

const checkoutReducer = (state = [],action)=>{
    switch(action.type){
        case 'ADD_ITEMS':
            return action.payload
        default:
            return state;
    }
}

export default checkoutReducer;