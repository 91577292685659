import React from 'react'
import "./Auth.scss"
import { TextField,Button,Alert } from '@mui/material'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import checkblack from '../Images/checkblack.png'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {Link} from 'react-router-dom'
const Input = styled('input')({
  display: 'none',
});
const Input2 = styled('input')({
  display: 'none',
});
function MerchantSignup(props) {
  const {handleSubmit,register,formState:{errors}}=useForm()
  const [error,setError]= React.useState("")
  const [organization,setOrganization]=React.useState(null)

  React.useEffect(()=>{
    if(props.location.search.length>2 && !props.location.search.includes("ref")){
      axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/org/byGroupId`,{groupId:props.location.search.split("?")[1]})
      .then(res=>{
        console.log(res)
        if(res.data.result!=="Not Found"){
          setOrganization(res.data.result)
        }
        
      })
      .catch(err=>{
        console.log(err)
      })
    }
    
  },[])
  
  const onSubmit = (data)=>{
    console.log(data)
    let link = `${process.env.REACT_APP_DEVELOPMENT}/api/auth/signup`
    if(props.location.search && props.location.search.includes("ref")){
      link = `${process.env.REACT_APP_DEVELOPMENT}/api/auth/signup${props.location.search}`
    }
    console.log(link)
      axios.post(link,{...data,type:1,groupId:organization?organization.groupId:"",organisationId:organization?organization._id:""})
      .then(res=>{
        if(res.data.msg==="Error"){
          setError(res.data.result)
        }else{
          props.history.push("/verifyotp",{auth:"merchantprofile",data:res.data})
        }
        
      })
      .catch(err=>{
        if(err.response){
          if(err.response.data){
            setError(err.response.data)
          }
        }else{
          setError("Something went wrong, check your internet connection and try again")
        }
        
      })
    
  }
  return (
    <>
        {organization?<div className='body100'>
        <div className="shadow-sm auth-container">
            <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
            <h3>Be Clever Pe Later</h3>
            <h6>Register in 30 seconds</h6>
            {organization&&<h5>You are being registered under Brand-{organization.name.toUpperCase()}</h5>}
            <form onSubmit = {handleSubmit(onSubmit)}>
            <TextField error={errors.name?true:false} {...register('name',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Merchant Name" />
            <TextField error={errors.storename?true:false} {...register('storename',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Store Name as per GSTIN" />
            <TextField error={errors.email?true:false} {...register('email',{required:true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
            })} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Email ID" />
            <TextField error={errors.mobileNo?true:false} type='number' {...register('mobileNo',{required:true,maxLength:10})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Mobile Number" />
            <TextField error={errors.postCode?true:false} {...register('postCode',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Pin Code" />
            <TextField error={errors.gstin?true:false} {...register('gstin',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="GSTIN Number" />
            <TextField error={errors.shopAddress?true:false} {...register('shopAddress',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Shop Address" />
            {/* <TextField {...register('homeAddress',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Home Address" /> */}

            {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
            <p className="grey subtitle my-3">By continuing, you agree to CleverPe's Conditions of Use and Privacy Notice.</p>
            <Button type="submit" className="mt-4" fullWidth variant="contained">SignUp</Button>
            <hr />
            <p className="mt-4">or, Already a Merchant ?</p>
            <Link to="/signin"><Button type="submit"  fullWidth variant="outlined">Log in</Button></Link>
            </form>
    
        <p className='grey subtitle mt-3'>
        ©2022 Cleverpe Technologies Private Limited
        </p>
        </div>
    
        </div>:<div className='body100'>
        <div className="shadow-sm auth-container">
            <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
            <h3>Be Clever Pe Later</h3>
            <h6>Register in 30 seconds</h6>
            <form onSubmit = {handleSubmit(onSubmit)}>
            <TextField error={errors.name?true:false} {...register('name',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Merchant Name" />
            <TextField error={errors.storename?true:false} {...register('storename',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Store Name as per GSTIN" />
            <TextField error={errors.email?true:false} {...register('email',{required:true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
            })} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Email ID" />
            <TextField error={errors.mobileNo?true:false} type='number' {...register('mobileNo',{required:true,maxLength:10})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Mobile Number" />
            <TextField error={errors.postCode?true:false} {...register('postCode',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Pin Code" />
            <TextField error={errors.gstin?true:false} {...register('gstin',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="GSTIN Number" />
            <TextField error={errors.shopAddress?true:false} {...register('shopAddress',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Shop Address" />
            {/* <TextField {...register('homeAddress',{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Home Address" /> */}

            {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
            <p className="grey subtitle my-3">By continuing, you agree to CleverPe's Conditions of Use and Privacy Notice.</p>
            <Button type="submit" className="mt-4" fullWidth variant="contained">Sign Up</Button>
            <hr />
            <p className="mt-4">or, Already a Merchant ?</p>
            <Link to="/signin"><Button type="submit"  fullWidth variant="outlined">Log in</Button></Link>
            </form>
    
        <p className='grey subtitle mt-3'>
        ©2022 Cleverpe Technologies Private Limited
        </p>
        </div>
    
        </div>}
        </>
  )
}

export default MerchantSignup