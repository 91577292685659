const wishlist = []



const cartReducer = (state = wishlist,action)=>{
    switch(action.type){
        case 'ADD_REMOVE_ITEM':
            let itemArr = state.filter(item=>item.variations._id === action.payload.variations._id)
             if(itemArr.length===0){
                return [...state,action.payload]
            }else{
                return state.filter(item=>item.variations._id !== action.payload.variations._id);
            }
        case "ADD_TO_WISHLIST":
            return [...state,action.payload]
        case "REMOVE_FROM_WISHLIST":
            return state.filter(item=>item._id!==action.payload._id)
        case "SET_WISHLIST":
            return action.payload
        default:
            return state;
    }
}

export default cartReducer;