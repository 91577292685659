import React from 'react'
import "./Footer.scss"
import {Link} from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import {ReactComponent as Msme} from './MSME logo.svg'
import ShopIcon from '@mui/icons-material/Shop';
import AppleIcon from '@mui/icons-material/Apple';
import footerplay from './gaplay.jpeg'
import {Button} from '@mui/material'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TvIcon from '@mui/icons-material/Tv';
import {withRouter} from 'react-router-dom'
import WorkIcon from '@mui/icons-material/Work';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import addcartimg from './add-to-cart.png'
import gateway from './gateway (1).png'
import delivery from './delivery.png'
import startupindia from './Startup India.png'
function Footer(props) {
  return (
    <div className="footer">
      <section className='desktop-container'>

      <div className='p-3'>
      <h2>Shopping Process</h2>
      <div className="row pt-3 mx-auto">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 shopping-container">
        <h3>Step 1</h3>
          <img src={addcartimg} alt="addcart" />
          <p>Select Any Item on cleverpe.com</p>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 shopping-container">
        <h3>Step 2</h3>
          <img src={gateway} alt="addcart" />
          <p>Select No Cost EMI Plan after checking out via 'cart' or 'buy now'</p>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 shopping-container">
        <h3>Step 3</h3>
          <img src={delivery} alt="addcart" />
          <p>Get Your Product at your doorstep</p>
        </div>
      </div>
      </div>

      <div className='p-3'>
      <h2>Shop our favourite categories</h2>
      <div className="row pt-3 mx-auto">
      <p><Button sx={{color:"white"}} 
      // onClick={()=>props.history.push("/categories",{categoryId:"6255247f0d4b9a0004f28c97",name:"Mobiles"})} 
      variant="secondary" 
      startIcon={<SmartphoneIcon />}>Mobiles</Button></p>
      <p className="ml-2"><Button sx={{color:"white"}} 
      // onClick={()=>props.history.push("/categories",{categoryId:"62571e99703b0600041b1db7",name:"Electronics"})} 
      variant="secondary" startIcon={<TvIcon />}>Electronics</Button></p>
      <p className="ml-2"><Button sx={{color:"white"}} 
      // onClick={()=>props.history.push("/categories",{categoryId:"62571e99703b0600041b1db7",name:"Electronics"})} 
      variant="secondary" startIcon={<TvIcon />}>Accessories</Button></p>
      </div>
      </div>

      <div className='border-bottom-grey p-3'>
      <h2>Get an EMI without a card</h2>
      <p>
      Love that new smartphone which is recently launched in the market? Want to buy a large LED television for your family? Big dreams burning big holes in your pocket? Does getting an EMI to look like a dream? Then look no further. We at cleverpe are here at your rescue. We understand your needs and hence are there to help you and support you in your financial needs. So who are we and why do you need us? Read on more to find out
      </p>
      </div>

{/* 
      <div className="row m-auto justify-content-between">
        <div className="my-3 col-5 footer-categories">
          <h2>Electronics</h2>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
        </div>
        <div className="my-3 col-5 footer-categories">
        <h2>Fashion</h2>
        <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
        </div>
      </div>


      <div className="row m-auto justify-content-between">
        <div className="my-3 col-5 footer-categories">
          <h2>Large Home Appliances
</h2>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
        </div>
        <div className="col-5 footer-categories">
        <h2>Health & Wellness</h2>
        <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
          <div className="row m-auto justify-content-between">
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
            <p><Link style={{color:"inherit"}}>Headphones</Link></p>
            <p><Link style={{color:"inherit"}}>Smartwatches</Link></p>
            <p><Link style={{color:"inherit"}}>Smartphones</Link></p>
          </div>
        </div>
      </div> */}



      <section className="border-bottom-grey row mt-5 mx-auto justify-content-between">

        <div className="col-4 col-sm-4 col-md-3 col-lg-1 col-xl-1">
        <Link to="/">
            <img src="/cleverpelogo.png" alt="logo" className="logo" />
        </Link>
            <div className="mt-3 startupindiadiv">
            <img src={startupindia} alt="startupindia" className="startupindia" />
            </div>

            <div className="msmelogo">
              <Msme />
            </div>

            {/* <div className="row m-auto apps">
              <div className="row align-items-center m-auto googleplay">
                  <div>
                    <ShopIcon className="icon" />
                  </div>
                  <div className="ml-3">
                    <p>Get it on</p>
                    <p className="appname">Google Play</p>
                  </div>
              </div>

              <div className="row align-items-center m-auto appstore">
                  <div>
                    <AppleIcon className="icon" />
                  </div>
                  <div>
                    <p>Download on the</p>
                    <p className="appname">App Store</p>
                  </div>
              </div>
            </div> */}
        </div>
        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <h2>About Company</h2>
            <p><Link to="/aboutus" className="link">About Us</Link></p>
            <p><Link to="/contactus" className="link">Contact Us</Link></p>
            {/* <p><span onClick={()=>window.location.href="https://d19fq2afrbh4en.cloudfront.net/ISO%209001%20HELLOMITR%20TECHNOLOGY.pdf"} className="cursor-pointer link">ISO 9001 2015 Certified</span></p>
            <p><span onClick={()=>window.location.href="https://d8rk3eocjlda.cloudfront.net/startup-certificate.pdf"} className="cursor-pointer link" >Startup Certified 2020</span></p> */}
        </div>
        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <h2>Legal Policy</h2>
            {/* <p><Link to="/deliveryinfo" className="link">Delivery Information</Link></p> */}
            <p><Link to="/disclaimer" className="link">Disclaimer</Link></p>
            <p><Link to="/termsandconditions" className="link">Terms & Conditions</Link></p>
            <p><Link to="/privacypolicy" className="link">Privacy Policy</Link></p>
            {/* <p><Link to="/sellerpolicy" className="link">Seller Policy</Link></p> */}
            <p><Link to="/returnpolicy" className="link">Return Policy</Link></p>
        </div>
        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <h2> Helpdesk</h2>
            <p><Link to="/merchantsignup" className="link">Register as a Merchant</Link></p>
            {/* <p><Link to="/faq" className="link">FAQs</Link></p> */}
            {/* <p><span  className="link">Help Center</span></p> */}
            <p><Link to="/howtobuy" className="link">How to Buy</Link></p>
            {/* <p><span  className="link">How to Buy</span></p> */}
            {/* <p><span onClick={()=>window.location.href="https://hellomitr.shiprocket.co/"} className="cursor-pointer link">Track Your Order</span></p> */}
            {/* <p><Link to="/corporate" className="link">Corporate & Bulk Purchasing</Link></p> */}
        </div>
        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
            <h2>Info</h2>
            <p>
              97, HOSUR, MAINMADIWALA, Bengaluru <br />
              Bengaluru Urban, Karnataka, 560034<br />
            </p>
            <p style={{wordWrap:"break-word"}}>support@cleverpe.com</p>
            {/* <p>Phone: 1800 - 572 - 8211</p>  */}
            <div className="row m-auto">
              <IconButton color="secondary">
                <FacebookRoundedIcon />
              </IconButton>

              <IconButton color="secondary">
                <TwitterIcon />
              </IconButton>

              <IconButton color="secondary">
                <YouTubeIcon />
              </IconButton>

              <IconButton color="secondary">
                <InstagramIcon />
              </IconButton>
            </div>


        </div>
        </section>


        <div className="row align-items-center my-4 mx-auto bottom-row">
        <div>
          <Link style={{color:"inherit"}} to="/merchantsignup"><Button startIcon={<WorkIcon />} variant="secondary">Become a seller</Button></Link>
        </div>
        <div>
          <Button startIcon={<QuestionMarkIcon />} variant="secondary">Need Help</Button>
        </div>
        <div className="ml-auto">
          <p>©2022 Cleverpe Technologies Private Limited</p>
        </div>
      </div>


      </section>



    </div>
  )
}

export default withRouter(Footer)