import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {connect} from 'react-redux'
import "./MerchantInventory.scss"
import {renderName} from '../../Utils/Functions'
import axios from 'axios'
//const options = ['Option 1', 'Option 2'];
function AlertDialog(props) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState('');
  const [options,setOptions]=React.useState([])
  const [checked,setChecked]=React.useState(true)
  const [quantity,setQuantity]=React.useState(1)
  const [data,setData]=React.useState([])
  React.useEffect(() =>{
    axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/product/allWithVariation`,{headers:{token:props.user.user}})
    .then(res=>{
      if(res.data.result.length>0){
        let nameArray = res.data.result.map((item,index)=>renderName(item))
        setOptions(nameArray)
        setData(res.data.result)
      }else{
        setOptions([])
      }
    })
    .catch(err=>{
    })
  },[])

  const handleSubmit = ()=>{
    ///api/inventory/create
    let variationId;
     data.map(item=>{
      if(renderName(item)===value){
        variationId= item.variations._id
      }
    })
    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/inventory/create`,{variationId,inStock:checked,quantity},{headers:{token:props.user.user}})
    .then(res=>{
      props.setOpen(false)
    })
    .catch(err=>{
    })

  }

  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Inventory Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select the item which you have in stock from the inventory dropdown given below
          </DialogContentText>


              <section className="inventory-form-section">
                <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              className='my-2'
              options={options}
              fullWidth
              renderInput={(params) => <TextField  {...params} label="Select Product" />}
            />

              <TextField onChange={(e)=>setQuantity(e.target.value)} className='my-2' fullWidth id="outlined-basics" variant="outlined" label="Product Quantity" />

                <FormGroup>
              <FormControlLabel onChange={(e)=>setChecked(!checked)} control={<Checkbox defaultChecked />} label="InStock" />
            </FormGroup>
            </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>handleSubmit()} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({cleverpeUser})=>{
  return {
    user:cleverpeUser
  }
}

export default connect(mapStateToProps)(AlertDialog)
{/* <TwoBDialog title="Delete Event" description="Are you sure you want to delete this event"
rightButton="Delete"
leftButton="Cancel"
open={open}
setOpen={setOpen}
handleSubmit={handleSubmit}
/> */}
