import React from 'react'
import "./Header.scss"
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from "redux";
import { withRouter } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import {setLoading} from '../redux/loading/loadingActions'
import axios from 'axios'
function Header(props) {
	const [search,setSearch]=React.useState("")
	const [data,setData]=React.useState([])
	const handleSearchPress = ()=>{
		//get all items by serach
		props.setLoading(true)
		axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/search`,{text:search},{headers:{token:props.user.user}})
		.then(res=>{
			props.setLoading(false)
			props.history.push("/categories",{data:res.data.result,name:search})
			setSearch("")
		})
		.catch(err=>{
		})
	}

	const handleSearch = (text)=>{
		setSearch(text)
		axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/autoComplete`,{text},{headers:{token:props.user.user}})
		.then(res=>{
			setData(res.data.result)
		})
		.catch(err=>{
		})
	}	


	const handleSearchItemPress = (name)=>{
		//get all items with variations
		props.setLoading(true)
		axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/search`,{text:name},{headers:{token:props.user.user}})
		.then(res=>{
			props.setLoading(false)
			props.history.push("/categories",{data:res.data.result,name})
			setSearch("")
		})
		.catch(err=>{
		})
	}

  return (
	  <section>
		<div className='header desktop-visible' style={{zIndex:"1000"}}>
		<div className="desktop-container row m-auto align-items-center">
		<div className="desktop-visible col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
		<Link 
		to="#"
		// to="/"
		>
		<img src="/cleverpewhitewithtext.png" alt="logo" className="logo" />
		</Link>
		</div>
		
		<div className="col-9 col-sm-9 col-md-7 col-lg-8 col-xl-8 p-0">
			<div className="inputdiv row m-auto align-items-center">
				<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 p-0" >
				<input 
				// onKeyPress={(e) => e.key === 'Enter' && handleSearchPress()} value={search} onChange={(e)=>handleSearch(e.target.value)} 
				placeholder="Search for products brands and more.." />
				</div>

				<div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2" style={{textAlign:"right"}}>
				{search.length>0?<CloseIcon onClick={()=>setSearch("")} className="cursor-pointer icon" color="primary" />:<SearchIcon  className="icon" color="primary" />}
				</div>
			</div>
			<div className={search.length>0?"suggestiondiv":"hidden"}>
				{
					data.length>0&&(
						data.map((item,index)=>(
							<p onClick={()=>handleSearchItemPress(item.name)} key={index} className='suggestion-item'>{item.name}</p>
						))
					)
				}
			</div>
		</div>

		<div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 row m-auto align-items-center p-0 icon-container justify-content-center">
			<div className=" signIn p-0">
				{
				!props.user.userInfo?<Link 
				to="#"
				// to="signin"
				><Button color="secondary" startIcon={<PersonIcon color="secondary" fontSize='large' />}>Sign In</Button></Link>
				:
				<Link 
				to="#"
				// to="myprofile"
				><Button color="secondary" startIcon={<PersonIcon color="secondary" fontSize='large' />}>{props.user.userInfo.name}</Button></Link>
			}
			</div>
			<div className="desktop-visible cart p-0">
			<Link 
			to="#"
			// to="cart"
			>
			<Button color="secondary" startIcon={
			 <Badge badgeContent={props.cart.length} color="tertiary">
			<ShoppingBagIcon color="secondary" />
			</Badge>
			}>Cart</Button>
			</Link>
			</div>
		</div>
		</div>
	</div>



	<div className='header mobile-visible'>
	<div className="row m-auto align-items-center">
		<div className="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1">
		<Link to="/">
		<img src="/cleverpec.jpg" alt="logo" className="logo" />
		</Link>
		</div>
		


		<div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-2 row m-auto align-items-center p-0 icon-container">
			<div className="ml-auto signIn p-0">
				{
				!props.user.userInfo?<Link 
				to="#"
				// to="signin"
				><Button color="secondary" startIcon={<PersonIcon color="secondary" fontSize='large' />}>Sign In</Button></Link>
				:
				<Link 
				to="#"
				// to="myprofile"
				><Button color="secondary" startIcon={<PersonIcon color="secondary" fontSize='large' />}>Profile</Button></Link>
			}
			</div>
			<div className="cart p-0">
			<Link 
			to="#"
			// to="cart"
			>
			<Button color="secondary" startIcon={
			 <Badge badgeContent={props.cart.length} color="tertiary">
			<ShoppingBagIcon color="secondary" />
			</Badge>
			}>Cart</Button>
			</Link>
			</div>
		</div>
		</div>


		<div className="searchdiv">
			<div className="inputdiv row m-auto align-items-center">
				<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 p-0" >
				<input 
				// onKeyPress={(e) => e.key === 'Enter' && handleSearchPress()} value={search} onChange={(e)=>handleSearch(e.target.value)} 
				placeholder="Search for products brands and more.." />
				</div>

				<div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2" style={{textAlign:"right"}}>
				{search.length>0?<CloseIcon onClick={()=>setSearch("")} className="cursor-pointer icon" color="primary" />:<SearchIcon  className="icon" color="primary" />}
				</div>
			</div>
			<div className={search.length>0?"suggestiondiv":"hidden"}>
				{
					data.length>0&&(
						data.map((item,index)=>(
							<p  onClick={()=>handleSearchItemPress(item.name)} key={index} className='suggestion-item'>{item.name}</p>
						))
					)
				}
			</div>
		</div>
	</div>








	</section>
  )
}



const mapStateToProps = ({cleverpeUser,cart})=>{
	return {
		user:cleverpeUser,
		cart
	}
}

const mapDispatchToProps = (dispatch)=>{
	return {
		setLoading:(loading)=>dispatch(setLoading(loading))
	}
}

export default compose(
	withRouter,
	connect(mapStateToProps,mapDispatchToProps)
  )(Header);