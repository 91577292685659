import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function ItemVarLoading() {
  return (
    <div>
        <div className="row my-3 mx-auto justify-content-between">
        <div className="col-11 p-0">
        <Skeleton variant="text" width={240}/>
        </div>
        <div className="col-1 p-0">
        <Skeleton variant="text" width={40}/>
        </div>
        </div>

        
        <div className="my-4" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Skeleton variant="rectangular" width={350} height={308} />
        </div>


        <div className="my-2">
        <Skeleton variant="text" width={150}/>
        </div>
        <div className="my-2">
        <Skeleton variant="text" width={80}/>
        </div>
        <div className="my-2">
        <Skeleton variant="text" width={250}/>
        </div>

    </div>
  )
}

export default ItemVarLoading