import { Button } from '@mui/material'
import React from 'react'
import CameraIcon from '@mui/icons-material/Camera';
import "./UploadPicture.scss"
function UploadPicture(props) {
  let orderId = props.location.state?props.location.state:null;

  return (
    <div className='upload-picture'>
        <h1>Product Photo Verification</h1>
        <img src="/handholdingphone.png" alt="handholding" />
        {/* <h4>Your Transaction is Successful!</h4> */}
        <p className='click'>Pls click a picture with the product you purchased</p>
        <center className='my-5'>
        <Button onClick={()=>props.history.push("/cameracomponent",props.location.state)} sx={{fontSize:"1.5em"}} startIcon={<CameraIcon />} variant="contained">
      Open Camera
    </Button>
        </center>
        <p className='tip'>Tip: You can ask the merchant to click your photo</p>
        <p className='verify'>Please note that clicking picture with the product is a part of verification process and is mandatory</p>

    </div>
  )
}

export default UploadPicture
// import React, { useState, useEffect } from "react";

// const UploadPicture = ({ imageId }) => {
//   const [imageSrc, setImageSrc] = useState(null);

//   useEffect(() => {
//     const fetchImage = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_DEVELOPMENT}/api/order/cusProdImg/657f468330c5624aa00a73cc`);
//         const blob = await response.blob();
//         const url = URL.createObjectURL(blob);
//         setImageSrc(url);
//       } catch (error) {
//         console.error("Error fetching image:", error);
//       }
//     };

//     fetchImage();
//   }, [imageId]);

//   return (
//     <div>
//       {imageSrc && <img src={imageSrc} alt="Uploaded Image" />}
//     </div>
//   );
// };

// export default UploadPicture;