import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import SubHeader from "../../Header/SubHeader";
import { connect } from "react-redux";
import MerchantDashhead from "../MerchantDashhead";
import {
  IconButton,
  TextField,
  Button,
  Tooltip,
  Fab,
  Alert,
  useMediaQuery,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Eligibility.scss";
import { getUserInfo, storeUserInfo } from "../../redux/user/userActions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormGroup from "@mui/material/FormGroup";
import * as FileSaver from "file-saver";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { setLoading } from "../../redux/loading/loadingActions";
import moment from 'moment';

function Eligibility(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [eligible, setEligible] = React.useState(false);
  const [custName, setCustName] = React.useState("");
  const [custNo, setCustNo] = React.useState("");
  const [eV, setEv] = React.useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (props?.user?.userInfo?.group[0]?.eV === true) {
      setEv(true);
    }
  }, [props.user.userInfo]);

  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  const onSubmit = (data) => {
    data.dateOfBirth = moment(data.dateOfBirth).format('yyyy/MM/dd');
    console.log(data);
    if (data?.mobileNo?.length === 10 && !eV) {
      props.setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/b2b/cashe/preapproval`,
          { mobileNo: data.mobileNo, customerName: data.name },
          { headers: { token: props.user.user } }
        )
        .then((res) => {
          props.setLoading(false);
          console.log(res);
          setError("");
          if (res.data.result === "Eligible") {
            setMessage(
              `This Mobile Number is Eligibile for a transaction worth ₹${res.data.amount}`
            );
            setCustName(data.name);
            setCustNo(data.mobileNo);
            setEligible(true);
          } else {
            setError("This Mobile Number is not Eligible for CleverPe EMI");
            localStorage.setItem("customerName", "");
            localStorage.setItem("customerNo", "");
          }
        })
        .catch((err) => {
          props.setLoading(false);
          console.log(err);
          setError("This Mobile Number is not Eligible for CleverPe EMI");
          setMessage("");
        });
    } else if (data?.mobileNo?.length === 10 && eV && regpan.test(data.pan)) {
      props.setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/b2b/cashe/eV/preapproval`,
          {
            mobileNo: data.mobileNo,
            customerName: data.name,
            pan: data.pan,
            dob: selectedDate,
            monthlyIncome: data.monthlyIncome,
            employmentType: data.employmentType
          },
          { headers: { token: props.user.user } }
        )
        .then((res) => {
          props.setLoading(false);
          console.log(res);
          setError("");
          if (res.data.result === "Eligible") {
            setMessage(
              `This Mobile Number is Eligibile for a transaction worth ₹${res.data.amount}`
            );
            setCustName(data.name);
            setCustNo(data.mobileNo);
            setEligible(true);
          } else {
            setError("This Mobile Number is not Eligible for CleverPe EMI");
            localStorage.setItem("customerName", "");
            localStorage.setItem("customerNo", "");
          }
        })
        .catch((err) => {
          props.setLoading(false);
          console.log(err);
          setError("This Mobile Number is not Eligible for CleverPe EMI");
          setMessage("");
        });
    } else if (!data?.mobileNo?.length === 10) {
      setError("Enter Valid Mobile Number");
    } else {
      setError("Enter valid pan number");
    }
  };

  function handleGenerateQR() {
    localStorage.setItem("customerName", custName);
    localStorage.setItem("customerNo", custNo);
    props.history.push("/GenerateQRnew");
  }

  return (
    <div>
      <Header />
      <div className="desktop-visible">
        <SubHeader />
      </div>
      {!loading && (
        <div className="my-profile">
          <div className="desktop-container row m-auto">
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
              <MerchantDashhead id={8} open={open} setOpen={setOpen} />
            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
              <div className="mobile-visible">
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </div>
              <h1>Check Customer Pre Approval</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" className="my-3">
                  <TextField
                    {...register("mobileNo", { required: true })}
                    id="outlined-basic"
                    variant="outlined"
                    label="Customer Mobile Number"
                    sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
                    fullWidth
                  />
                  <TextField
                    {...register("name", { required: true })}
                    id="outlined-basic"
                    variant="outlined"
                    label="Customer Name"
                    fullWidth
                  />
                </Box>
                {eV && (
                  <>
                    <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems="center" className="my-3">
                      <TextField
                        {...register("pan", { required: true })}
                        id="outlined-basic"
                        variant="outlined"
                        label="Customer Pan"
                        sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
                        fullWidth
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Customer Date of Birth"
                          inputFormat="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems="center" className="my-3">
                      <TextField
                        {...register("monthlyIncome", { required: true })}
                        id="outlined-basic"
                        variant="outlined"
                        label="Monthly Income"
                        type="number"
                        sx={{ mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
                        fullWidth
                      />
                      <TextField
                        {...register("employmentType", { required: true })}
                        id="outlined-select-currency"
                        select
                        label="Employment Type"
                        fullWidth
                      >
                        <MenuItem value={1}>Employed</MenuItem>
                        <MenuItem value={2}>Unemployed</MenuItem>
                        <MenuItem value={3}>Self-Employed</MenuItem>
                      </TextField>
                    </Box>
                  </>
                )}
                {error?.length > 0 && (
                  <Alert
                    className="alert"
                    severity="error"
                    onClose={() => {
                      setError(null);
                    }}
                  >
                    {error}
                  </Alert>
                )}
                {message?.length > 0 && (
                  <Alert
                    className="alert"
                    severity="success"
                    onClose={() => {
                      setMessage(null);
                    }}
                  >
                    {message}
                  </Alert>
                )}
                <div style={{ textAlign: "center" }}>
                  {!eligible ? (
                    <Button
                      startIcon={<SearchIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Check Now
                    </Button>
                  ) : (
                    <Button
                      startIcon={<SearchIcon />}
                      variant="contained"
                      disabled={!eligible}
                      color="success"
                      onClick={handleGenerateQR}
                    >
                      Generate Qr
                    </Button>
                  )}
                </div>
              </form>
            </section>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ cleverpeUser, loading }) => {
  return {
    user: cleverpeUser,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: (token) => dispatch(getUserInfo(token)),
    storeUserInfo: (user) => dispatch(storeUserInfo(user)),
    setLoading: (value) => dispatch(setLoading(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility);
