import React from 'react'
import Header from '../Header/Header'
import SubHeader from '../Header/SubHeader'
import Footer from '../Footer/Footer'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import "./Checkout.scss"
import {Button,IconButton,TextField} from '@mui/material'
import {connect} from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash'
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios'
import { formatMoney,percentage,renderTenure,renderTenureString } from '../Utils/Functions';
import {storeOrderId} from '../redux/user/userActions'
import { setLoading } from '../redux/loading/loadingActions';
import AddAddressModal from './AddAddressModal';
import { nanoid } from 'nanoid'
function Checkout(props) {
    const [products,setProducts]=React.useState([])
    const [renderState,setRenderState]=React.useState(1)
    const [value, setValue] = React.useState('');
    const [loading,setLoading]=React.useState(false)
    const userInfo = props.user.userInfo
    const [firstName,setFirstName]=React.useState("")
    const [lastName,setLastName]=React.useState("");
    const [city,setCity]=React.useState("");
    const [state,setState]=React.useState("");
    const [pincode,setPincode]=React.useState("");
    const [email,setEmail] =React.useState("")
    const [open,setOpen]=React.useState(false)
    const [total,setTotal]=React.useState(0)
    const [tenure,setTenure]=React.useState(props.location.state?props.location.state:"")


    
    React.useEffect(()=>{
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        let total = 0
        if(!props.user.userInfo || !props.checkout){
            setLoading(true)
        }else{
            setLoading(false)
            //let arr = props.checkout.map(item=>({...item,count:1}))
            setProducts(props.checkout)
            props.checkout.map(item=>{
                total = total + parseInt(item.variations.item.options.price)
            })
            setTotal(total)
            setFirstName(props.user.userInfo.name)
            setEmail(props.user.userInfo.email)
            setPincode(props.user.userInfo.postCode)
        }
        
    },[props.user.userInfo])

    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const renderName = (product,options)=>{
        let name = product.name
        _.map(options,(value,key)=>{
            if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
                name = name + " " + `(${value})`
            }
        })
        return name
    }

    const handlePlaceOrder =()=>{
        let total = 0;
        let variations = products.map(item=>{
            total = total + parseInt(item.variations.item.options.price)
            return {variationId:item.variations.item._id,quantity:item.variations.quantity}
        })
        let ten = renderTenure(tenure,total)

        let addressObj={
            "first_name": firstName,
            "last_name": lastName,
            "address_1": value,
            "address_2": "",
            "city": city,
            "state": state,
            "postcode": pincode,
            "country": "India",
            "email": email,
            "phone": props.user.userInfo.mobileNo
          }
          props.setLoading(true)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/create`,{
            total,
            shipping:addressObj,
            variations,
            merchantId:"629e29b6e802fd00049d02e5",
            downPayment:{price:ten.downPayment,status:0}

        },{headers:{token:props.user.user}})
        .then(res=>{
            props.setLoading(false)
            //props.history.push("/myorders")
            props.storeOrderId(res.data.result._id)
            if(ten.downPayment===0){
                axios.post(`${process.env.REACT_APP_CASHELIVE}/api/cashe/paymentgateway/customer/generateTransaction`,{
                    amount:total,
                    tenure:ten.months,
                    mobilenumber:props.user.userInfo.mobileNo,
                    authKey:`${process.env.REACT_APP_CASHELIVEKEY}`,
                    leafRefNo:nanoid(),
                    merchantname:"cleverpe",
                    returnPageURL:`${process.env.REACT_APP_PRODUCTION}/transactionstatus`
                  })
                  .then(res=>{
                    window.location.href=`${process.env.REACT_APP_CASHELIVEREDIRECT}/Login?transaction=${res.data.entity}`
                  })
                  .catch(err=>{
                  })
            }   //downPayment months
            else{ //take payment from razor pay
                const options = {
                    key: `${process.env.REACT_APP_RAZORPAYLIVE}`,
                    amount: `${ten.downPayment*100}`, //  = INR 1
                    name: 'Cleverpe',
                    description: 'some description',
                    image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
                    handler: async function(response) {
                        const dp = await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/downPayment`,{orderId:res.data.result._id,status:1},{headers:{token:props.user.user}})
                        axios.post(`${process.env.REACT_APP_CASHELIVE}/api/cashe/paymentgateway/customer/generateTransaction`,{
                            amount:parseInt(total)-parseInt(ten.downPayment),
                            tenure:ten.months,
                            mobilenumber:props.user.userInfo.mobileNo,
                            authKey:`${process.env.REACT_APP_CASHELIVEKEY}`,
                            leafRefNo:nanoid(),
                            merchantname:"cleverpe",
                            returnPageURL:`${process.env.REACT_APP_PRODUCTION}/transactionstatus`
                          })
                          .then(res=>{
                            window.location.href=`${process.env.REACT_APP_CASHELIVEREDIRECT}/Login?transaction=${res.data.entity}`
                          })
                          .catch(err=>{
                          })
                        
                    },
                    prefill: {
                        name: 'Gaurav',
                        contact: '9999999999',
                        email: 'demo@demo.com'
                    },
                    notes: {
                        address: 'some address'
                    },
                    theme: {
                        color: 'black',
                        hide_topbar: false
                    }
                };
                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
    
            }
        })
        .catch(err=>{
        })
    }

    const deliveryForm =()=>{
        return <div className="delivery-form">
            <h3 className="mt-3">Fill Your Shipping Details*</h3>
            <div className="row my-4 mx-auto">
                <div className="col-6">
                    <TextField onChange={(e)=>setFirstName(e.target.value)} defaultValue={userInfo.name} fullWidth variant="outlined" id="outlined-basics" label="First Name" />
                </div>
                <div className="col-6">
                    <TextField onChange={(e)=>setLastName(e.target.value)} defaultValue={userInfo.shipping&&userInfo.shipping.last_name} fullWidth variant="outlined" id="outlined-basics" label="Last Name" />
                </div>
            </div>

            <div className="row my-4 mx-auto">
                <div className="col-6">
                    <TextField onChange={(e)=>setCity(e.target.value)} defaultValue={userInfo.shipping&&userInfo.shipping.city} fullWidth variant="outlined" id="outlined-basics" label="City" />
                </div>
                <div className="col-6">
                    <TextField onChange={(e)=>setState(e.target.value)} defaultValue={userInfo.shipping&&userInfo.shipping.state} fullWidth variant="outlined" id="outlined-basics" label="State" />
                </div>
            </div>
            <div className="row my-4 mx-auto">
                <div className="col-6">
                    <TextField onChange={(e)=>setPincode(e.target.value)} defaultValue={userInfo.postCode&&userInfo.postCode} fullWidth variant="outlined" id="outlined-basics" label="Pin Code" />
                </div>
                <div className="col-6">
                    <TextField onChange={(e)=>setEmail(e.target.value)} defaultValue={userInfo.email} fullWidth variant="outlined" id="outlined-basics" label="Email" />
                </div>
            </div>
        </div>
    }


    const renderItems = ()=>{
        if(renderState ===1){
            return (<div>
                <h2>1. Delivery Details</h2>
                {props.user.userInfo.addresses.length>0?
                <div>
                <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Select Delivery Address</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                {
                props.user.userInfo.addresses.map((item,index)=>
                <FormControlLabel key={index} value={item} control={<Radio />} label={item} />
                )
                }
                </RadioGroup>
                </FormControl>
                    <div>
                    <Button variant="outlined" onClick={()=>setOpen(true)}>Add Address</Button>
                    </div>
                    {
                        deliveryForm()
                    }
                </div>
                :
                <div>
                <p>You haven't added any addresses</p>
                <div>
                    <Button variant="outlined" onClick={()=>setOpen(true)}>Add Address</Button>
                    </div>
                </div>
                }
            </div>)
        }else if(renderState===2){
            return <div>
                <h2>2. Review Products</h2>
                {
                    products.length>0&&products.map((item,index)=>(
                <div className="review-product-container " key={index}>
                <section className="row m-auto" >
                    <div className="col-3">
                    {item.variations.item.images.length>0?
                    <img src={item.variations.item.images[0]} alt="oppo" />
                    :<img src="/cleverpeproduct.png" alt="oppo" />
                    }
                    </div>
                    <div className="col-8">
                        <h3>{renderName(item.variations.item.product[0],item.variations.item.options)}</h3>
                        <h2>{formatMoney(item.variations.item.options.price)}</h2>
                        <p><span className="mrp">{formatMoney(item.variations.item.options.mrp)}</span> <span className="percentage">{percentage(item.variations.item.options.mrp,item.variations.item.options.price)}%</span></p>
                        <p className="savings">Your Savings on this order is {formatMoney(item.variations.item.options.mrp-item.variations.item.options.price)}</p>
                        <div className="row m-auto align-items-center">
                            <div>
                               <IconButton
                               disabled={item.variations.quantity===1&&true}
                               onClick={()=>{
                                   item.variations.quantity = item.variations.quantity-1
                                setProducts([...products])
                            }} >
                                   <RemoveIcon />
                               </IconButton>
                            </div>
                            <div className="mx-2">
                                <p>{item.variations.quantity}</p>
                            </div>
                            <div>
                            <IconButton onClick={()=>{
                                item.variations.quantity = item.variations.quantity+1
                                setProducts([...products])
                            }}>
                                   <AddIcon />
                               </IconButton>
                            </div>
                        </div>
                    </div>

                    <div className='col-1'>
                        <IconButton onClick={()=>{
                            setProducts(products.filter(prod=>prod._id!==item._id))
                        }}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                </section>
                <hr />
                </div>
                    ))
                }
            </div>
        }
        
        else if (renderState ===3){
            return <div>
                <h2>3. Select Payment Method</h2>
                <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Choose Tenure</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={(e)=>setTenure(e.target.value)}
                    value={tenure}
                >
                    <FormControlLabel value={1} control={<Radio />} label={renderTenureString(1,total)} />
                    <FormControlLabel value={2} control={<Radio />} label={renderTenureString(2,total)} />
                    <FormControlLabel value={3} control={<Radio />} label={renderTenureString(3,total)} />
                    <FormControlLabel value={4} control={<Radio />} label={renderTenureString(4,total)} />
                </RadioGroup>
                </FormControl>
            </div>
        }
    }

    const renderButtons = ()=>{
        if(renderState ===1){
            return <div>
                <Button disabled onClick={()=>setRenderState(renderState-1)} fullWidth>Back</Button>
                <Button disabled={value.length<=0?true:false} onClick={()=>setRenderState(renderState+1)} variant="contained" fullWidth>Next</Button>
            </div>
        }else if(renderState ===2){
            return <div>
                <Button onClick={()=>setRenderState(renderState-1)} fullWidth>Back</Button>
                <Button onClick={()=>tenure!==""?handlePlaceOrder():setRenderState(renderState+1)} variant="contained" fullWidth>Next</Button>
            </div>
        }else if(renderState===3){
            return <div>
                <Button onClick={()=>setRenderState(renderState-1)} fullWidth>Back</Button>
                <Button disabled={tenure===""?true:false} onClick={()=>handlePlaceOrder()} variant="contained" fullWidth>CleverPe Now</Button>
            </div>
        }
    }
    const renderCheckoutDetails = (i)=>{
        let count = 0;
        let subTotal = 0
        let total = 0
        products.map(item=>{
            subTotal = subTotal + parseInt(item.variations.item.options.mrp)
            total = total + parseInt(item.variations.item.options.price)
            count = count + item.variations.quantity
        })
        total = total *count;
        subTotal = subTotal*count;
        if(i ===1){
            return count
        }else if(i===2){
            return formatMoney(subTotal)
        }else if(i===3){
            return formatMoney(total)
        }else if(i===4){
            return formatMoney(subTotal-total)
        }
    }
  return (
    !loading&&<div>
        <Header />
        <SubHeader />
        <AddAddressModal open={open} setOpen={setOpen} />
        {products.length>0?<section className="desktop-container checkout row m-auto justify-content-between">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 product-review">
                {
                renderItems()
                }
            </div>
            <div className="col-10 col-sm-10 col-md-3 col-lg-3 col-xl-3 checkout-review">
                <h2>Purchase Details</h2>
                <hr />
                <div className="row m-auto justify-content-between align-items-center">
                    <div>
                        <p className="key">Product Quantity :</p>
                    </div>
                    <div>
                        <p className="value">{renderCheckoutDetails(1)}</p>
                    </div>
                </div>
                {/* <div className="row m-auto justify-content-between align-items-center">
                    <div>
                    <p className="key">Subtotal : </p>
                    </div>
                    <div>
                        <p className="value">{renderCheckoutDetails(2)}</p>
                    </div>
                </div> */}
                <div className="row m-auto justify-content-between align-items-center">
                    <div>
                    <p className="key">Delivery Charges : </p>
                    </div>
                    <div>
                        <p className="value">Free</p>
                    </div>
                </div>
                

                <div className="total row m-auto justify-content-between align-items-center">
                    <div>
                    <p className="key">Total : </p>
                    </div>
                    <div>
                        <p className="value">{renderCheckoutDetails(3)}</p>
                    </div>
                </div>
                <p style={{textAlign:"center"}} className="savings">Your Total Savings on this Order {renderCheckoutDetails(4)}</p>



                {
                    renderButtons()
                }
                
            </div>
        </section>
        :
        <section className="no-items">
            <h2>No Products available for checkout</h2>
        </section>    
    }
        <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,checkout})=>{
return {
    user:cleverpeUser,
    checkout
}
}

const mapDispatchToProps = (dispatch)=>{
    return {
        storeOrderId:(id)=>dispatch(storeOrderId(id)),
        setLoading:(loading)=>dispatch(setLoading(loading))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Checkout)