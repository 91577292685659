import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SubHeader from "../Header/SubHeader";
import { connect } from "react-redux";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MerchantDashhead from "./MerchantDashhead";
import {
  IconButton,
  TextField,
  Button,
  Tooltip,
  Fab,
  Alert,
} from "@mui/material";
import { formatMoney } from "../Utils/Functions";
import MenuIcon from "@mui/icons-material/Menu";
import "./MerchantProfile.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { getUserInfo, storeUserInfo } from "../redux/user/userActions";
import { setLoading } from "../redux/loading/loadingActions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SimpleBackdrop from "../Utils/SimpleBackdrop";
import { useForm } from "react-hook-form";
import axios from "axios";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const Input = styled("input")({
  display: "none",
});
function MerchantProfile(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [gender, setGender] = React.useState(10);
  const [address, setAddress] = React.useState("");
  const [addressData, setAddressData] = React.useState([]);
  const [userData, setUserData] = React.useState({});
  const [addressMobile, setAddressMobile] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState([]);
  const [selectedImage2, setSelectedImage2] = React.useState([]);
  const [selectedImage3, setSelectedImage3] = React.useState([]);
  const [selectedImage4, setSelectedImage4] = React.useState([]);
  const [frontImage, setFrontImage] = React.useState("");
  const [insideImage, setInsideImage] = React.useState("");
  const [gstin, setGstin] = React.useState("");
  const [cancelCheck, setCancelCheck] = React.useState("");
  const [dealershipCert, setdealershipCert] = React.useState("");
  const [bannerImg, setBannerImg] = React.useState(null);
  const handleChange = (event) => {
    setGender(event.target.value);
  };

 // console.log(props?.user?.userInfo?.group[0]?.eV);
  //console.log(props?.user);
  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/user/addBank`,
        {
          data,
        },
        { headers: { token: props.user.user } }
      )
      .then((res) => {
        console.log(res);
        props.getUserInfo(props.user.user);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    if (props.user.userInfo) {
      setAddressData([props.user.userInfo.merchantData.shopAddress]);
      if (props.user.userInfo.merchantData.documents.length > 0) {
        props.user.userInfo.merchantData.documents.map((item) => {
          if (item.name === "frontImage") {
            setFrontImage(item.img);
          } else if (item.name === "insideImage") {
            setInsideImage(item.img);
          } else if (item.name === "gstin") {
            setGstin(item.img);
          } else if (item.name === "cancelCheck") {
            setCancelCheck(item.img);
          }
           else if (item.name === "dealershipCert") {
            setdealershipCert(item.img);
          }
        });
      }
    }
    //getUserInfo()
    // axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/user/single`,{headers:{token:props.user.user}})
    // .then(res=>{
    //     //setUserData()
    //     props.storeUserInfo(res.data.result)
    // })
    // .catch(err=>{
    // })
  }, [props.user.userInfo]);

  React.useEffect(() => {
    props.getUserInfo();
  }, []);

  React.useEffect(() => {
    props?.user?.userInfo?.groupId &&
      axios
        .get(
          `${process.env.REACT_APP_DEVELOPMENT}/api/offer/byGroup/${props.user.userInfo.groupId}`,
          { headers: { token: props.user.user } }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result.length > 0) {
            res.data.result.map((item) => {
              setBannerImg(item.imgUrl);
            });
          }
        });
  }, []);
  return (
    <div>
      <Header />
      <div className="desktop-visible">
        <SubHeader />
      </div>
      {!loading && (
        <div className="my-profile">
          <div className="desktop-container row m-auto">
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
              <MerchantDashhead id={1} open={open} setOpen={setOpen} />
            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
              <div className="mobile-visible">
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </div>

              {!edit ? (
                <section className="profile-section row m-auto">
                  <div className="col-12 profile-parent-container">
                    {/* {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>} */}
                    {/* {props.user.userInfo && (
                      <>
                        {props.user.userInfo.recieptNotUploaded && (
                          <>
                            {props.user.userInfo.recieptNotUploaded.length >=
                              5 && (
                              <Alert className="alert" severity="error">
                                You have not uploaded receipts of{" "}
                                {props.user.userInfo.recieptNotUploaded.length}{" "}
                                completed orders, upload it or else your account
                                will be blocked
                              </Alert>
                            )}
                          </>
                        )}
                      </>
                    )} */}
                    <Alert iconMapping={{}} className="alert" severity="info">
                      Our CleverPe partner stores can offer a wide range of EMIs
                      options to their customers on any Brand or Products. Happy
                      selling 🔥
                    </Alert>
                    <Alert className="alert" severity="info">
                      For Instant WhatsApp{" "}
                      <WhatsAppIcon sx={{ fontSize: 18 }} /> Helpline:
                      8059896607
                    </Alert>
                    {bannerImg && (
                      <img
                        style={{ width: "100%" }}
                        src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${bannerImg}`}
                        alt=""
                      />
                    )}

                    <div className="row m-auto profile-cont align-items-center">
                      <div className="">
                        {props.user.userInfo &&
                          (props.user.userInfo.img ? (
                            <img
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${props.user.userInfo.img}`}
                              className="avatar"
                              alt="man"
                            />
                          ) : (
                            <img src="/man.png" className="avatar" alt="man" />
                          ))}
                      </div>
                      <div className="">
                        <h2>
                          {props.user.userInfo && props.user.userInfo.name}
                        </h2>
                        <p className="ml-3 mt-1">
                          {props.user.userInfo &&
                            props.user.userInfo.merchantId}
                        </p>
                      </div>
                      <div></div>
                      {/* <div className="">
                        <IconButton onClick={()=>setEdit(!edit)}>
                            <EditIcon />
                        </IconButton>
                    </div> */}
                    </div>

                    <Button
                      onClick={() => props.history.push("/GenerateQRnew")}
                      className="my-3"
                      variant="outlined"
                      fullWidth
                    >
                      Click to Create a new order
                    </Button>
                    <div></div>
                    <div className="row m-auto info-children p-0">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <h3>Mobile No</h3>
                        <p>
                          {props.user.userInfo && props.user.userInfo.mobileNo}
                        </p>
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <h3>Email</h3>
                        <p style={{ wordWrap: "break-word" }}>
                          {props.user.userInfo && props.user.userInfo.email}
                        </p>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <h3>GSTIN</h3>
                        {/* <p>{props.user.userInfo&&(props.user.userInfo.merchantData.gstin&&props.user.userInfo.merchantData.gstin)}</p> */}
                      </div>
                    </div>

                    {props.user.userInfo &&
                      (props.user.userInfo.merchantData.bankDetails ? (
                        <div>
                          <p>
                            Bank Account Number:{" "}
                            <b>
                              {
                                props.user.userInfo.merchantData.bankDetails
                                  .bankaccontno
                              }
                            </b>
                          </p>
                          <p>
                            Bank IFSC Code:{" "}
                            <b>
                              {
                                props.user.userInfo.merchantData.bankDetails
                                  .bankifsc
                              }
                            </b>
                          </p>
                          <p>
                            Bank Account Holder Name:{" "}
                            <b>
                              {
                                props.user.userInfo.merchantData.bankDetails
                                  .bankaccountholder
                              }
                            </b>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="my-3">
                              <TextField
                                error={errors.bankaccontno ? true : false}
                                {...register("bankaccontno", {
                                  required: true,
                                })}
                                sx={{ width: 300 }}
                                variant="outlined"
                                label="Bank Account No."
                                id="outlined-basics"
                              />
                            </div>
                            <div className="my-3">
                              <TextField
                                error={errors.bankifsc ? true : false}
                                {...register("bankifsc", { required: true })}
                                sx={{ width: 300 }}
                                variant="outlined"
                                label="Bank IFSC Code"
                                id="outlined-basics"
                              />
                            </div>
                            <div className="my-3">
                              <TextField
                                error={errors.bankaccountholder ? true : false}
                                {...register("bankaccountholder", {
                                  required: true,
                                })}
                                sx={{ width: 300 }}
                                variant="outlined"
                                label="Bank Account Holder Name"
                                id="outlined-basics"
                              />
                            </div>
                            <Button type="submit" variant="contained">
                              Submit Bank Details
                            </Button>
                          </form>
                        </div>
                      ))}

                    {props.user.userInfo &&
                    props.user.userInfo.addresses.length > 0 ? (
                      props.user.userInfo &&
                      props.user.userInfo.addresses.map((item, index) => (
                        <div key={index} className="address">
                          <h3>Address {index + 1}:</h3>
                          <p>{item}</p>
                        </div>
                      ))
                    ) : (
                      <div style={{ color: "#ccc" }}>
                        <ContactMailIcon />{" "}
                        <span className="ml-2">
                          You haven't added any addresses yet
                        </span>
                      </div>
                    )}
                    {props.user.userInfo &&
                      (props.user.userInfo.merchantData.documents.length > 0 ? (
                        <h3>Your Documents</h3>
                      ) : (
                        <h3>Complete Your KYC</h3>
                      ))}
                    <p>Note:</p>
                    <p>
                      1. Review the documents before uploading, once uploaded,
                      if it has any issues contact support merchant@cleverpe.com
                    </p>
                    <p>
                      2. Normal Settlement is T+1 business working days (Monday
                      - Friday) TAT for payment. Upload your genuine invoice
                      otherwise payment will get delayed
                    </p>
                    <section className="row m-auto justify-content-between">
                      <div>
                        <div
                          className="mt-4 mb-2"
                          style={{ textAlign: "center" }}
                        >
                          {frontImage.length <= 0 ? (
                            <label htmlFor="contained-button-frontImage">
                              <Input
                                onChange={(event) => {
                                  setSelectedImage(event.target.files[0]);
                                  const formdata = new FormData();
                                  if (!Array.isArray(event.target.files[0])) {
                                    formdata.append(
                                      "image",
                                      event.target.files[0]
                                    );
                                    props.setLoading(true);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_DEVELOPMENT}/api/user/uploadDoc/frontImage`,
                                        formdata,
                                        {
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type":
                                              "multipart/form-data",
                                            token: props.user.user,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        props.setLoading(false);
                                        props.getUserInfo(props.user.user);
                                      })
                                      .catch((err) => {
                                        props.setLoading(false);
                                      });
                                  }
                                }}
                                accept="image/*"
                                id="contained-button-frontImage"
                                type="file"
                              />
                              <Button
                                variant="contained"
                                component="span"
                                endIcon={<CameraAltIcon />}
                              >
                                store front image
                              </Button>
                            </label>
                          ) : (
                            <img
                              className="document-img"
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${frontImage}`}
                              alt="fornt"
                              style={{border:"2px solid black"}}
                            />
                          )}
                        </div>
                      </div>

                      <div>
                        <div
                          className="mt-4 mb-2"
                          style={{ textAlign: "center" }}
                        >
                          {insideImage.length <= 0 ? (
                            <label htmlFor="contained-button-inside">
                              <Input
                                onChange={(event) => {
                                  setSelectedImage2(event.target.files[0]);
                                  const formdata = new FormData();
                                  if (!Array.isArray(event.target.files[0])) {
                                    formdata.append(
                                      "image",
                                      event.target.files[0]
                                    );
                                    props.setLoading(true);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_DEVELOPMENT}/api/user/uploadDoc/insideImage`,
                                        formdata,
                                        {
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type":
                                              "multipart/form-data",
                                            token: props.user.user,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        props.setLoading(false);
                                        props.getUserInfo(props.user.user);
                                      })
                                      .catch((err) => {
                                        props.setLoading(false);
                                      });
                                  }
                                }}
                                accept="image/*"
                                id="contained-button-inside"
                                type="file"
                              />
                              <Button
                                variant="contained"
                                component="span"
                                endIcon={<CameraAltIcon />}
                              >
                                inside store image
                              </Button>
                            </label>
                          ) : (
                            <img
                              className="document-img"
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${insideImage}`}
                              alt="inside"
                              style={{border:"2px solid black"}}
                            />
                          )}
                        </div>
                      </div>
{ props?.user?.userInfo?.group[0]?.eV && <>
                      <div>
                        <div className="mt-4 mb-2" style={{ textAlign: "center" }}>
                          {dealershipCert?.length <= 0 ? (
                            <label htmlFor="contained-button-dealershipCert">
                              <Input
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  if (file) {
                                    setdealershipCert(file);
                                    const formdata = new FormData();
                                    formdata.append("image", file);
                                    props.setLoading(true);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_DEVELOPMENT}/api/user/uploadDoc/dealershipCert`,
                                        formdata,
                                        {
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type": "multipart/form-data",
                                            token: props.user.user,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        props.setLoading(false);
                                        props.getUserInfo(props.user.user);
                                      })
                                      .catch((err) => {
                                        props.setLoading(false);
                                      });
                                  } else {
                                    // Handle the case when the uploaded file is not a PDF
                                    alert("Please upload a PDF file.");
                                  }
                                }}
                                accept="image/*" // Only accept PDF files
                                id="contained-button-dealershipCert"
                                type="file"
                              />
                              <Button
                                variant="contained"
                                component="span"
                                endIcon={<CameraAltIcon />}
                              >
                                Letter Of Intent/Award (dealership certificate)
                              </Button>
                            </label>
                          ) : (
                            <img
                              className="document-img"
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${dealershipCert}`}
                              alt="LOI"
                              style={{border:"2px solid black"}}
                            />
                          )}
                        </div>
                      </div>
   </>

}
                      <div>
                        <div
                          className="mt-4 mb-2"
                          style={{ textAlign: "center" }}
                        >
                          {gstin.length <= 0 ? (
                            <label htmlFor="contained-button-gstin">
                              <Input
                                onChange={(event) => {
                                  setSelectedImage3(event.target.files[0]);
                                  const formdata = new FormData();
                                  if (!Array.isArray(event.target.files[0])) {
                                    formdata.append(
                                      "image",
                                      event.target.files[0]
                                    );
                                    props.setLoading(true);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_DEVELOPMENT}/api/user/uploadDoc/gstin`,
                                        formdata,
                                        {
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type":
                                              "multipart/form-data",
                                            token: props.user.user,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        props.setLoading(false);
                                        props.getUserInfo(props.user.user);
                                      })
                                      .catch((err) => {
                                        props.setLoading(false);
                                      });
                                  }
                                }}
                                accept="image/*"
                                id="contained-button-gstin"
                                type="file"
                              />
                              <Button
                                variant="contained"
                                component="span"
                                endIcon={<CameraAltIcon />}
                              >
                                GSTIN Certificate image
                              </Button>
                            </label>
                          ) : (
                            <img
                              className="document-img"
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${gstin}`}
                              alt="gstin"
                              style={{border:"2px solid black"}}
                            />
                          )}
                        </div>
                      </div>

                      <div>
                        <div
                          className="mt-4 mb-2"
                          style={{ textAlign: "center" }}
                        >
                          {cancelCheck.length <= 0 ? (
                            <label htmlFor="contained-button-cancelCheck">
                              <Input
                                onChange={(event) => {
                                  setSelectedImage4(event.target.files[0]);
                                  const formdata = new FormData();
                                  if (!Array.isArray(event.target.files[0])) {
                                    formdata.append(
                                      "image",
                                      event.target.files[0]
                                    );
                                    props.setLoading(true);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_DEVELOPMENT}/api/user/uploadDoc/cancelCheck`,
                                        formdata,
                                        {
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type":
                                              "multipart/form-data",
                                            token: props.user.user,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        props.setLoading(false);
                                        console.log(res);
                                        props.getUserInfo(props.user.user);
                                      })
                                      .catch((err) => {
                                        props.setLoading(false);
                                      });
                                  }
                                }}
                                accept="image/*"
                                id="contained-button-cancelCheck"
                                type="file"
                              />
                              <Button
                                variant="contained"
                                component="span"
                                endIcon={<CameraAltIcon />}
                              >
                                Upload Cancel Check
                              </Button>
                            </label>
                          ) : (
                            <img
                              className="document-img"
                              src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${cancelCheck}`}
                              alt="cancelCheck"
                              style={{border:"2px solid black"}}
                            />
                          )}
                        </div>
                      </div>
                    </section>
                  </div>

                  {/* <div className="col-4 credit-limit-container">
                    <h4>CleverPe Credit Limit</h4>
                    <h1>{formatMoney(props.user.userInfo&&props.user.userInfo.creditLimit)}</h1>
                </div> */}
                </section>
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="profile-section row m-auto"
                >
                  <div className="col-8 profile-parent-container">
                    <div className="row m-auto profile-cont align-items-center">
                      <div className="">
                        <img src="/man.png" alt="man" />
                      </div>
                      <div className="mx-2">
                        <TextField
                          fullWidth
                          error={errors.name ? true : false}
                          {...register("name", { required: true })}
                          defaultValue={
                            props.user.userInfo && props.user.userInfo.name
                          }
                          id="outlined-basic"
                          variant="outlined"
                          label="Name"
                        />
                      </div>
                      <div className="">
                        <IconButton onClick={() => setEdit(!edit)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>

                    <div className="row m-auto info-children p-0">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p>
                          <TextField
                            fullWidth
                            error={errors.mobile ? true : false}
                            {...register("mobileNo", { required: true })}
                            defaultValue={
                              props.user.userInfo &&
                              props.user.userInfo.mobileNo
                            }
                            id="outlined-basic"
                            variant="outlined"
                            label="Mobile Number"
                          />
                        </p>
                      </div>
                      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gender}
          label="Gender"
          onChange={handleChange}
          defaultValue={10}
        >
          <MenuItem value={10}>Male</MenuItem>
          <MenuItem value={20}>Female</MenuItem>
          <MenuItem value={30}>Other</MenuItem>
        </Select>
      </FormControl>
        </p>
    </div> */}
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p>
                          <TextField
                            fullWidth
                            error={errors.email ? true : false}
                            {...register("email", { required: true })}
                            defaultValue={
                              props.user.userInfo && props.user.userInfo.email
                            }
                            id="outlined-basic"
                            variant="outlined"
                            label="Email"
                          />
                        </p>
                      </div>
                    </div>

                    <section className="add-address row m-auto align-items-center">
                      <div className="col-6">
                        <TextField
                          onChange={(e) => setAddress(e.target.value)}
                          fullWidth
                          multiline
                          maxRows={5}
                          id="outlined-basic"
                          variant="outlined"
                          label="Enter New Address"
                        />
                        <TextField
                          className="mt-3"
                          onChange={(e) => setAddressMobile(e.target.value)}
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Enter Mobile Number"
                        />
                      </div>

                      <div className="col-4">
                        <Button
                          onClick={() => {
                            let addr = `${address} ${addressMobile}`;
                            setAddressData([...addressData, addr]);
                          }}
                          variant="contained"
                          fullWidth
                        >
                          Add Address
                        </Button>
                      </div>
                    </section>

                    {addressData.length > 0 ? (
                      addressData.map((item, index) => (
                        <div
                          key={index}
                          className="row m-auto address align-items-center"
                        >
                          <div className="col-10 p-0">
                            <h3>Address {index + 1}:</h3>
                            <p>{item}</p>
                          </div>
                          <div className="col-2 p-0">
                            <IconButton>
                              <DeleteOutlineRoundedIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ color: "#ccc" }}>
                        <ContactMailIcon />{" "}
                        <span className="ml-2">
                          You haven't added any addresses yet
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-4 credit-limit-container">
                    <h4>CleverPe Credit Limit</h4>
                    <h1>
                      {formatMoney(
                        props.user.userInfo && props.user.userInfo.creditLimit
                      )}
                    </h1>
                  </div>
                  {edit && (
                    <div
                      style={{ position: "fixed", bottom: "5%", right: "15%" }}
                    >
                      <Tooltip title="Create Product">
                        <Fab type="submit" variant="extended">
                          <CheckIcon sx={{ mr: 1 }} />
                          Done
                        </Fab>
                      </Tooltip>
                    </div>
                  )}
                </form>
              )}
            </section>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ cleverpeUser, loading }) => {
  return {
    user: cleverpeUser,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: (token) => dispatch(getUserInfo(token)),
    setLoading: (value) => dispatch(setLoading(value)),
    storeUserInfo: (user) => dispatch(storeUserInfo(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantProfile);

/* {!Array.isArray(selectedImage)?(
                    <div className="my-3 row justify-content-center">
                    <img alt="not fount" className="document-img" src={URL.createObjectURL(selectedImage)} />
                    <IconButton onClick={()=>setSelectedImage([])} aria-label="delete" size="small" color='error'>
                    <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    </div>
                    ):null} */
