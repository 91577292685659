import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import {Link} from 'react-router-dom'
import "./PageSection.scss"
import SubHeader from '../components/Header/SubHeader'
function ReturnPolicy() {
  return (
    <div>
        <Header />
        <SubHeader />
        <section className="desktop-container page-section">
        <h1>Return Policy on Cleverpe</h1>
        <p>
        Cleverpe’s return and replacement policy can be availed for the products purchased 
        from the Cleverpe website/app. The details for return and replacement can be checked 
        on the product details page. Please ensure that the product is unused and the original 
        packaging and tags are intact
        </p>
        <p>
        A return or replacement request can be raised by placing a "Return Order" request by 
logging a call with Company's Customer Care Centre by raising a ticket & filling a 
'Refund Form' by clicking on <Link to="/refund">https://cleverpe.com/refund</Link>
        </p>
        <p>
        Any Product purchased from the Website can be returned or replaced to the Company 
only if the product supplied by the Company is damaged, defective or the Product 
received is different from the product that was ordered. The User shall ensure that 
he/she does not accept the delivery of any Product whose original packaging is 
damaged or tampered in any manner. In the event if user, on removing the packaging 
of the Product, finds the Product is damaged and/or defective and/or is different from 
the Product that was ordered, the user shall intimate the Company’s Customer Care 
Centre within 24 hours of receiving the product, about such damage and/or defect and/ 
or difference and the Company shall arrange for the replacement of the Product or 
refund of the price of the Product collected by the Company, in the unlikely event of 
the Company only being unable to replace the damaged and/or defective Product. For 
any Refund or Exchange / Replacement of Product, the relevant provisions of Refund 
and Exchange shall apply as given herein. In case of Cancellation (Return / 
Replacement) of order after delivery of the Product to User, the Company will arrange 
to pick up of the Product to be returned. Product returned must be in like-new 
condition and returned in original packaging with all original accessories and other 
components. User will assist and cooperate fully to return the Product with its 
manuals, booklets, warranty card, accessories, freebies, packing materials, or any 
other thing which were delivered to the User along with the Product being returned. 
The Company reserves the right to reject any refund request if the product received is 
damaged or if any accessories or any other original components are missing. Products 
received that do not meet the above return criteria may be returned to the customer via 
ground shipment. At the time of pickup, the User will be provided with an 
acknowledgment of pick up by the Company’s Logistical Partner. User should retain 
the said acknowledgment and quote on the product, the same in all his/her future 
communications with the Company in this regard. All free gifts, in original packing as 
delivered and unused condition, must be returned along with the Product in case of 
cancellation of the order of the Product with which the free gift(s) is/are given.
        </p>

        <h3>RULES FOR RETURN & REPLACEMENT</h3>

        <ul>
            <li>The freight charges for such products shall be reimbursed to you on successful receipt of 
product and verification of the same</li>
<li>Returns are subject to verification and checks in order to determine the legitimacy of 
complaint/return. During pick-up, your product shall be checked for the following conditions:</li>
        </ul>
        <table class="ui celled table">
  <thead>
    <tr><th>For</th>
    <th>Conditions</th>
  </tr></thead>
  <tbody>
    <tr>
      <td data-label="Name">Correctness</td>
      <td data-label="Age">IMEI/ name/ image/ brand/ serial number/ article number/ bar code should 
match and MRP tag should not be removed and clearly visible.</td>
    </tr>
    <tr>
      <td data-label="Name">Completeness</td>
      <td data-label="Age">All in-the-box accessories (like remote control, starter kits, instruction 
manuals, chargers, headphones, etc.), freebies, warranty/guarantee cards and 
combos (if any) should be present</td>
    </tr>
    <tr>
      <td data-label="Name">Non Usage</td>
      <td data-label="Age">All in-the-box accessories (like remote control, starter kits, instruction 
manuals, chargers, headphones, etc.), freebies, warranty/guarantee cards and 
combos (if any) should be present</td>
    </tr>
    <tr>
      <td data-label="Name">Non-damage 
(Product)
</td>
      <td data-label="Age">All in-the-box accessories (like remote control, starter kits, instruction 
manuals, chargers, headphones, etc.), freebies, warranty/guarantee cards and 
combos (if any) should be present</td>
    </tr>
    <tr>
      <td data-label="Name">Non-damage 
(Packaging)
</td>
      <td data-label="Age">Product’s original packaging/ brand box should be undamaged.</td>
    </tr>
  </tbody>
</table>




        <ul>
            <li>Products may be rejected for return if they are not returned as per the conditions mentioned 
above. Products may also be rejected for return (for refund or replacement) if sent directly to 
the Seller by you without our approval for the same, in which case you waive any and all 
claims against Cleverpe.</li>
            <li> If the product received does not pass the verification and checks, the product shall be 
shipped back to you.</li>
<li>
Refund/replacement shall be issued to you only after the product is received and verification 
of the same.
</li>
<li>
Replacement is subject to availability of the product (size, color, etc.) and is limited to one 
request.
</li>
<li>
In case the replacement product is out of stock, a refund shall be initiated.
</li>
<li>
If the price of the replacement product is lower than the amount paid by you originally, a 
partial refund will be issued to you provided.
</li>
<li>
Products with passwords/locks should be returned unlocked/disabled.
</li>
<li>
Products that store personal information should be returned after deleting all personal 
information. Cleverpe shall not be responsible in any manner for any misuse or usage of 
such information.
</li>
<li>
Products sold as combos/sets cannot be replaced or returned individually
</li>
<li>
Products should be carefully packaged so that there is no damage during transit.
</li>
<li>
Cleverpe shall not be responsible for the products returned by mistake. In circumstances 
where a product not belonging to the Seller or transacted through Cleverpe is returned by 
mistake, Cleverpe or the Seller shall not be accountable for misplacement or replacement of 
the product and shall not responsible for its delivery back to the you. You waive any and all 
claims against Cleverpe in this regard undoubtedly
</li>

        </ul>



        </section>
        <Footer />
    </div>
  )
}

export default ReturnPolicy