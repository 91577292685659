import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab,Alert } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'
import {useForm} from 'react-hook-form'
import {renderName,renderTenureString,renderTenure,renderTenureStringInterest, renderTenureInterest} from '../../Utils/Functions'
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import {Link} from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import _ from 'lodash'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { pink } from '@mui/material/colors';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { setLoading } from '../../redux/loading/loadingActions'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BlockIcon from '@mui/icons-material/Block';
const steps = ['Enter Customer Details', 'Select Product', 'Verify details and Generate QR'];
function GenerateMerchantQR(props) {
    const {handleSubmit,register,formState:{errors},setValue}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const data = [{name: "samsung", price: 31},{name: "samsung", price: 31},{name: "samsung", price: 31},{name: "samsung", price: 31},{name: "samsung", price: 31}];
    //const [error,setError]=React.useState("")

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const [selectedBrand, setSelectedBrand] = React.useState(null);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [allBrands,setAllBrands] = React.useState([])
    const [allProducts,setAllProducts] = React.useState([])
    const [verifyItems,setVerifyItems] = React.useState(0)
    const [error,setError] = React.useState(null)
    

    //form info hooks
    const [customerName,setCustomerName] = React.useState("")
    const [customerNo,setCustomerNo] = React.useState("")
    const [productPrice,setProductPrice]=React.useState(0)
    const [productQuantity,setProductQuantity]=React.useState(1)
    const [serialNo,setSerialNo] = React.useState(null)
    const [validation,setValidation] = React.useState(null)
    const [cart,setCart] = React.useState([])
    const [tenure,setTenure] = React.useState(null)
    const [orderId,setOrderId] = React.useState(null)
    const [regeneration,setRegeneration] = React.useState(0)
    const [copied,setCopied] = React.useState(false)
    const [userOrganisation,setUserOrganisation]=React.useState(null)
    console.log(selectedBrand,selectedProduct)
    const totalAmount = ()=>{
      let total = 0;
      cart.map(i=>{
        total = total + parseInt(i.price)
      })
      return total;
    }


    const handleSelect = (event, value) => {
      setSelectedBrand(value);
      props.setLoading(true)
      value &&
      axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/product/bybrand/${value.name}`,{headers:{token:props.user.user}})
      .then(res=>{
        setAllProducts(res.data.result.map((i,ind)=>({...i,id:ind})))
        setSelectedProduct(null)
        props.setLoading(false)
        if(value.validation.level===1 || value.validation.level===2){
          setVerifyItems(1)
        }else if(value.validation.level===3){
          setVerifyItems(2)
        }
        
      })
      .catch(err=>{
        props.setLoading(false)
      })
      props.setLoading(false)
    };

    const handleSelect2 = (event, value) => {
      setSelectedProduct(value);
      if(value?.mrpCheck){
        setValue("productPrice",value?.variationMrp)
      }
      
    };


    React.useEffect(()=>{
      axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/oem/all`,{headers:{token:props.user.user}})
      .then(async res=>{
        setAllBrands(res.data.result)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/org/byGroupId`,{groupId:props.user.userInfo.groupId},{headers:{token:props.user.user}})
        .then(response=>{
          setUserOrganisation(response.data.result)
        })
        
      })
      .catch(err=>{

      })
    },[])

  
    const isStepOptional = (step) => {
      return step === 1;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      if(customerName==="" || customerNo===""){
        setError("All Fields are compulsory")
      }
      // else if(customerName==="sharjeel" || customerNo==="9665276786"){
        
      //     setError("This Mobile Number is not eligible for credit limit approval")
        
      // }
      else if(cart.length<=0 && activeStep===1){
        setError("Cart cannot be empty, please add product first")
      }
      
      else if(activeStep === steps.length - 1){
        //generate qr
        props.setLoading(true)
        let variations = cart.map(item=>{
          return {
            productPrice:item.price,quantity:1,variationId:item.product.variationId,imeiserial:item.imeiserial
          }
        })
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/createOffline0.1`,{
          variations,
          mobileNo:customerNo,
          name:customerName,
          total:totalAmount(),
          serialNo,
          scheme:tenure,
          validation,
          oemId:selectedBrand._id,
          productId:selectedProduct._id?selectedProduct._id:"",
          native:JSON.parse(process.env.REACT_APP_NATIVE_FLAG)
        },{headers:{token:props.user.user}})
        .then(res=>{
          console.log("generate qr response",res)
          props.setLoading(false)
          if(res.data.msg==="Success"){
            setOrderId(res.data.result._id)
            setError(null)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
          }
        })
        .catch(err=>{
          props.setLoading(false)
          // if(err.response){
          //   if(err.response.data){

          //   }
          // }else{

          // }
          setError("Something went wrong")
        })
      }
      else{
        setError(null)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
  
      
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      //setActiveStep(0);
      window.location.reload(false);
    };

    const onSubmit = (data)=>{
      let check;
      //data.productPrice
      props.setLoading(true)
      // axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/b2b/cashe/getCreditLine/${customerNo}`,{headers:{token:props.user.user}})
      // .then(res=>{
      //   if(res.data.result){
      //     if(res.data.result.availableAmount<data.productPrice){
      //       props.setLoading(false)
      //       setError(`Sorry, ₹${res.data.result.availableAmount} creditline is left, choose a different product`)
      //     }else{
      //       setError(null)
      //     }
      //   }
      // })
      // .catch(err=>{
      //   props.setLoading(false)
      // })

      //validate request start----------------------------------------------------------
      const validationRequest = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/oem/serial/validate`,{oemId:selectedBrand._id,serialNo:data.serialNo,modelId:selectedProduct.modelId?selectedProduct.modelId:null})
        .then(res=>{
          props.setLoading(false)
          if(res.data.msg==="Success"){
            if(res.data.serialNo){
              setSerialNo(res.data.serialNo)
            }
            setValidation(res.data.result)
            setError(null)
            if(parseInt(data.productPrice)<=999){
              setError("Enter amount greater than 999")
            }else{
              setError(null)
              setCart([...cart,{price:data.productPrice,quantity:data.productQuantity,product:selectedProduct}])
            }
            
          }
        })
        .catch(err=>{
          props.setLoading(false)
            if(err.response.data){
              setError(err.response.data.result)
            }
        })
      }
      //validate request end----------------------------------------------------------

      if(selectedBrand){
        props.setLoading(true)
        //check if mrp and mop validation is there or not
        if(selectedProduct?.mrpCheck){
          check = parseInt(data?.productPrice) <= parseInt(selectedProduct?.variationMrp) && parseInt(data?.productPrice) >= parseInt(selectedProduct?.variationMop) // this will give true
          console.log("check is --------------------",check,selectedProduct)
          if(check){
            validationRequest() 
          }else{
            setError(`Price must be in between ${selectedProduct?.variationMop} to ${selectedProduct?.variationMrp}`)
            props.setLoading(false)
          }
        }else{
          validationRequest()
        }
      }
      
      //setCart([...cart,{price:data.productPrice,quantity:data.productQuantity,product:selectedProduct}])
    }
    console.log("userorganisation",userOrganisation)
    const renderRadio = ()=>{
      if(selectedBrand.schemeType==="GENERIC"){
           if(totalAmount()>=1000 && totalAmount()<=5999){
                //showing only 3 months scheme
                return userOrganisation.schemes.map((item,index)=>{
                  if(item.scheme==="3by0"){
                  return <FormControlLabel key={index} value={!item.interestBased?renderTenure(item.scheme,totalAmount())?.name:renderTenureInterest(item.scheme,totalAmount())?.name} control={<Radio />} label={item.interestBased?renderTenureStringInterest(item.scheme,totalAmount()):renderTenureString(item.scheme,totalAmount())} />
                  }
              })
              }
              else if(totalAmount()>=6000 && totalAmount()<=8999){
                //shwoing till 9by3
                return userOrganisation.schemes.map((item,index)=>{
                  if(item.scheme==="3by0" || item.scheme==="6by0" || item.scheme==="8by2" || item.scheme==="9by3"){
                  return <FormControlLabel key={index} value={!item.interestBased?renderTenure(item.scheme,totalAmount())?.name:renderTenureInterest(item.scheme,totalAmount())?.name} control={<Radio />} label={item.interestBased?renderTenureStringInterest(item.scheme,totalAmount()):renderTenureString(item.scheme,totalAmount())} />
                  }
              })
              }else if(totalAmount()>=9000 && totalAmount()<=11999){
                //showing till 9by0
                return userOrganisation.schemes.map((item,index)=>{
                  //item.scheme==="9by0" ||
                  if(item.scheme==="3by0" || item.scheme==="6by0" ||  item.scheme==="8by2" || item.scheme==="9by3"){
                  return <FormControlLabel key={index} value={!item.interestBased?renderTenure(item.scheme,totalAmount())?.name:renderTenureInterest(item.scheme,totalAmount())?.name} control={<Radio />} label={item.interestBased?renderTenureStringInterest(item.scheme,totalAmount()):renderTenureString(item.scheme,totalAmount())} />
                  }
              })
              }else{
                //showing all what is in array
                return userOrganisation.schemes.map((item,index)=><FormControlLabel key={index} value={!item.interestBased?renderTenure(item.scheme,totalAmount()).name:renderTenureInterest(item.scheme,totalAmount())?.name} control={<Radio />} label={item.interestBased?renderTenureStringInterest(item.scheme,totalAmount()):renderTenureString(item.scheme,totalAmount())} />)
              }
        
      }else if(selectedBrand.schemeType==="PRODUCT"){
        //product scheme
        // in the following checking if its 0 or 3 or 4 or not if its 0 i.e. 3 months emi then check if its interestbased or not
        if(totalAmount()>=1000 && totalAmount()<=5999){
          //showing only 3 months scheme
          return selectedProduct.schemes.map((item,index)=>index===0&&(item.interestBased?
            <FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount())} />
            :<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />))
        }
        else if(totalAmount()>=6000 && totalAmount()<=8999){
          //shwoing till 9by3
          return selectedProduct.schemes.map((item,index)=>index<=3&&(item.interestBased?
            <FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount())} />
            :<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />))
        }else if(totalAmount()>=9000 && totalAmount()<=11999){
          //showing till 9by0
          return selectedProduct.schemes.map((item,index)=>index<=4&&(item.interestBased?
            <FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount())} />
            :<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />))
        }else{
          //showing all what is in array
          return selectedProduct.schemes.map((item,index)=>(item.interestBased?
            <FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount())} />
            :<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />))
        }
      }
      else{
        if(totalAmount()>=1000 && totalAmount()<=5999){
          //showing only 3 months scheme
          return selectedBrand?.schemes.map((item,index)=>index===0&&<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />)
        }
        else if(totalAmount()>=6000 && totalAmount()<=8999){
          //shwoing till 9by3
          return selectedBrand?.schemes.map((item,index)=>index<=3&&<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />)
        }else if(totalAmount()>=9000 && totalAmount()<=11999){
          //showing till 9by0
          return selectedBrand?.schemes.map((item,index)=>index<=4&&<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />)
        }else if(totalAmount()>=11999 && totalAmount()<=36000){
          //showing all what is in array
          return selectedBrand?.schemes.map((item,index)=>index<=5&&<FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />)
          // return selectedBrand.schemes.map((item,index)=><FormControlLabel key={index} value={renderTenure(item.scheme,totalAmount()).name} control={<Radio />} label={renderTenureString(item.scheme,totalAmount())} />)
        } else{
          //showing all what is in array
          return selectedBrand?.schemes.map((item,index)=>(item.interestBased?
            <FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount(), item.interestRate, selectedBrand.pf).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount(), item.interestRate, selectedBrand.pf)} />
            :<FormControlLabel key={index} value={renderTenureInterest(item.scheme,totalAmount(), item.interestRate, selectedBrand.pf).name} control={<Radio />} label={renderTenureStringInterest(item.scheme,totalAmount(), item.interestRate, selectedBrand.pf)} />))        }
        
      }
    }

    const renderVerifyItems = ()=>{
      if(verifyItems===0){
        return <div />
      }
      else if(verifyItems===1){
        return <div>
        <TextField error={errors.serialNo?true:false} {...register('serialNo',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Serial Number" />  
        </div>
      }else if(verifyItems===2){
        return <div>
        <TextField error={errors.serialNo?true:false} {...register('serialNo',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Serial Number" />  
        {/*<TextField error={errors.modelId?true:false} {...register('modelId',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Model Number" />  */}
        </div>
      }
    }

    const renderPriceInput = ()=>{
      if(selectedProduct){
        if(selectedProduct.mrpCheck){
          return <TextField error={errors.productPrice?true:false} focused={selectedProduct?(selectedProduct.mrpCheck?true:false):false} type='number' {...register('productPrice',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Price" />
        }else{
          return <TextField error={errors.productPrice?true:false} type='number' {...register('productPrice',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Price" />  
        }
      }
      else{
        return <TextField error={errors.productPrice?true:false} type='number' {...register('productPrice',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Price" />
      }
    }


    const renderStepContent = (activeStep)=>{
      if(activeStep===0){
        return <section className="newqr-form-head">
          <h1>Customer Info</h1>
          <TextField 
      value={customerNo}
      onChange={(e)=>setCustomerNo(e.target.value)}
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
    }}
      type='number' className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Customer Mobile Number" />

      <TextField 
      value={customerName}
      onChange={(e)=>setCustomerName(e.target.value)}
      className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Customer Name as Per Pan" />

      {/* <img src="/newgoldscheme.jpg" className="gqil1" alt="generateqr"  /> */}
        </section>
      }else if(activeStep===1){
        return <section className="newqr-form-head">
          {/* <div class="glassmorphism-strip">
  <p>Congratulations! You are eligible for loan approval of ₹15000.</p>
</div> */}
          <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Product Selection</h1>
          <Autocomplete
          id="autocomplete-demo"
          className="mb-4"
          options={allBrands}
          getOptionLabel={(option) => option.name}
          onChange={handleSelect}
          value={selectedBrand}
          renderInput={(params) => (
            <TextField {...params} label="Select a brand" variant="outlined" />
          )}
        />
          <Autocomplete
          id="autocomplete-demo"
          className="my-2"
          options={allProducts}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          getOptionLabel={(option) => option.name}
          onChange={handleSelect2}
          value={selectedProduct}
          renderInput={(params) => (
            <TextField {...params} label="Select a product" variant="outlined" />
          )}
        />
        {
          renderVerifyItems()
        }
        {
          renderPriceInput()
        }
        
        {/* <TextField error={errors.productQuantity?true:false} type='number' {...register('productQuantity',{required:true})} defaultValue={1} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Quantity" /> */}
        <div className="my-2" style={{textAlign:"center"}}>
        {/* {error&&<p className="my-3" style={{textAlign:"center",color:"red",fontWeight:"bold"}}>{error} !</p>} */}
        <Button disabled={cart.length>0?true:false} type="submit" variant="outlined">Add Item to cart</Button>
        </div>
        </form>

        {/* cart section starts */}
        { cart.length>0?<div className="table-responsive">
    <table className="table">
  <thead>
    <tr>
      <th scope="col">Sr</th>
      <th scope="col">Product Name</th>
      <th scope="col">Quantity</th>
      <th scope="col">Price</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>

   {
     cart.map((item,index)=>(
      <tr key={index} style={{alignItems:"center"}}>
      <th scope="row">{index+1}</th>
      <td>{item.product.name}</td>
      <td>1</td>
      <td>{parseInt(item.price)}</td>
      <td>
        <DeleteOutlineIcon className="cursor-pointer" onClick={()=>{
          setCart(cart.filter(i=>item.product.variationId!==i.product.variationId))
          }} sx={{fontSize:"1.3em"}} />
      </td>
    </tr>
     ))
   }
  </tbody>
</table>
    </div>:<div className="mt-4" style={{textAlign:"center"}}>
            <h2>Cart is Empty</h2>
            <p>(Add at least one item to cart inorder to proceed)</p>
          </div>
    }
    {/* <img src="/newgoldscheme.jpg" className="gqil1" alt="generateqr"  /> */}
        </section>
      }else if(activeStep===2){
        return <section className="newqr-form-head">
          <h1>Verify Details</h1>
          <p>Customer Name: <b>{customerName}</b></p>
          <p>Customer Mobile Number: <b>{customerNo}</b></p>
          <div className="table-responsive">
            <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Product Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>

          {
            cart.map((item,index)=>(
              <tr key={index} style={{alignItems:"center"}}>
              <th scope="row">{index+1}</th>
              <td>{item.product.name}</td>
              <td>1</td>
              <td>{parseInt(item.price)}</td>
            </tr>
            ))
          }
          </tbody>
        </table>
            </div>
            <h1>Select EMI Scheme</h1>
            <FormLabel id="demo-radio-buttons-group-label">Select CleverPe Plan For Customer</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        onChange={(e)=>setTenure(e.target.value)}
      >
        {
          renderRadio()
        }
              </RadioGroup>
            <div style={{textAlign:"center"}}>
            {/* <Button endIcon={<QrCodeScannerIcon />} variant="contained">Generate QR</Button> */}
            </div>
            {/* <img src="/newgoldscheme.jpg" className="gqil1" alt="generateqr"  /> */}
        </section>
      }
    }

    const renderCompleted = ()=>{
      return <section className="completed-section">
        <h1>Order Created! Here is your QR</h1>
            <QRCode size={200} value={`${process.env.REACT_APP_PRODUCTION}/offlinepay/${orderId}/${regeneration}`} logoImage='/cleverpec.jpg' removeQrCodeBehindLogo logoHeight={25} logoWidth={25} />
            <div>
              {copied&&<p style={{textAlign:"center"}} className="my-3"><b>Link copied to clipboard!</b></p>}
            <Button onClick={() => {
              setCopied(true)
              navigator.clipboard.writeText(`${process.env.REACT_APP_PRODUCTION}/offlinepay/${orderId}/${regeneration}`)}}>Click to copy link to clipboard</Button>
            </div>
            <div className="mt-3">
            <Button onClick={()=>setRegeneration(regeneration+1)} className="mx-2" endIcon={<ReplayIcon />}>Regenerate QR</Button>
            <Button className="mx-2" onClick={handleReset} endIcon={<AddIcon />} variant="contained">Create new order</Button>
            </div>
      </section>
    }
//.recieptNotUploaded.length>=50

  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto' >
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={5} open={open} setOpen={setOpen}/>
                  

            </section>

            
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container" >

{            props.user.userInfo.kyc?<>

            {!props.user.userInfo?<section style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100%"}}>
            <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>
                <div>
                <BlockIcon sx={{fontSize:"3em"}} />
                </div>
                <div>
                <h3>Your account is blocked because of not uploading receipts, Upload remaining receipts inorder to unblock it</h3>
                </div>
                </section>:
            <>
            <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>
                <Box sx={{ width: '100%' }}>
      {/* this is top stepper */}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">Optional</Typography>
          //   );
          // }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          {/* this section is for completed step */}
          {
            renderCompleted()
          }
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Create New Order</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* this section is for all sects except completed step */}
          {renderStepContent(activeStep)}
          {error&&<Alert className="alert" severity="error">{error}</Alert>}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
             sx={{fontSize:14,mr:1}} startIcon={<ArrowBackIosIcon />}
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            <Button variant="contained" sx={{fontSize:14}} endIcon={<ArrowForwardIosIcon />}   onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Generate QR' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
            </>
            }
            
            </>:
            <section style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100%"}}>
                <div>
                <FindInPageIcon sx={{fontSize:"3em"}} />
                </div>
                <div>
                <h3>Your account is under review</h3>
                </div>
                </section>
                }
           
                

            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user)),
        setLoading:value=>dispatch(setLoading(value))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GenerateMerchantQR)