import React from 'react'
import {Link,useParams} from 'react-router-dom'
import axios from 'axios'
import { TextField,Button,Alert } from '@mui/material';
import {useForm} from 'react-hook-form'

function VerifyOrderOTP(props) {
    const params = useParams();
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [error,setError]= React.useState("")
    const [hash,setHash] = React.useState(null)
    const [timer,setTimer]=React.useState(0)
    React.useEffect(()=>{
      if(timer>0){
        setTimeout(() => {
            setTimer(timer-1)
        }, 1000);
    }
    },[timer])

    const getOtp = async ()=>{
      await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/auth/order/otp`,{
        orderId:params.id
      })
      .then(res=>{
        setHash(res.data.hash)
      })
      .catch(err=>{
        if(err.response.data){
          setError(err.response.data)
        }
        
      })
    }

    const onSubmit = (data)=>{

        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/auth/order/verify`,{
          orderId:params.id,
          hash,
          otp:data.otp
        })
        .then(res=>{
          if(res.data==="Verified"){
            props.history.push(`/offlinepay?${params.id}`)
          }else{
            setError("Something went wrong")
          }
          
        })
        .catch(err=>{
          if(err.response.data){
            setError(err.response.data)
          }
        })
      
    }
  return (
    <div className='body'>
    <div className="shadow-sm auth-container">
        <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
        <h3>Be Clever Pe Later</h3>
        <h6>Click on Get OTP to receive otp on registered number</h6>
        <form onSubmit = {handleSubmit(onSubmit)}>
        <TextField {...register('otp',{required:true})} className="my-3" fullWidth variant="outlined" id="outlined-basic" label="Enter OTP" />
        <div style={{textAlign:"right"}}>
        {timer!==0?<Button disabled>Get OTP ({timer})</Button>:<Button onClick={()=>{
          getOtp()
          setTimer(59)}}>Get OTP</Button>}
        {/* <Button onClick={()=>getOtp()}>Get OTP</Button> */}
        </div>
        {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
        <p className="grey subtitle my-3">By continuing, you agree to CleverPe <Link to="/termsandconditions">Terms and Conditions</Link> of Use and <Link to="/privacypolicy">Privacy Policy</Link>.</p>
        <Button type="submit" className="mt-4" fullWidth variant="contained">Verify</Button>
        </form>

    <p className='grey subtitle mt-3'>
    ©2022 Cleverpe Technologies Private Limited
    </p>
    </div>

    </div>
    // <section className="verify-parent">
    // <div className="verifyotp">
    //     <h1>Verify OTP</h1>
    //     <p>OTP has been successfully sent to the registered order number</p>
    //     <TextField className="my-3" fullWidth variant="outlined" id="outlined-basic" label="Enter OTP" />
    //     <Button fullWidth variant="contained">Verify </Button>
    // </div>
    // </section>
  )
}

export default VerifyOrderOTP