import React from 'react'
import "./Description.scss"
function Description({desc}) {
  return (
    <div>
        <h1>Product Description</h1>
        <p>  
        {desc}
        </p>

        {/* <div className='row mx-2 my-5 attribute-section'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <img src="/pd1.png" alt="pd1" />
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h4>Intelligent Frame Sense</h4>
                <p>With two Sony camera sensors, one for the front and one for the back cameras, 
you'll be able to take unrivalled photographs regardless of the illumination. 
You can flamboyantly record memorable recollections of your life.</p>
            </div>
        </div>


        <div className='row mx-2 my-5 attribute-section'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <img src="/pd2.png" alt="pd1" />
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h4>Scenic Beauty</h4>
                <p>With two Sony camera sensors, one for the front and one for the back cameras, 
you'll be able to take unrivalled photographs regardless of the illumination. 
You can flamboyantly record memorable recollections of your life.</p>
            </div>
        </div>



        <div className='row mx-2 my-5 attribute-section'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <img src="/pd3.png" alt="pd1" />
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h4>Dapper Sensor</h4>
                <p>With two Sony camera sensors, one for the front and one for the back cameras, 
you'll be able to take unrivalled photographs regardless of the illumination. 
You can flamboyantly record memorable recollections of your life.</p>
            </div>
        </div>


        <div className='row mx-2 my-5 attribute-section'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <img src="/pd4.png" alt="pd1" />
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h4>Expressive Photography</h4>
                <p>With two Sony camera sensors, one for the front and one for the back cameras, 
you'll be able to take unrivalled photographs regardless of the illumination. 
You can flamboyantly record memorable recollections of your life.</p>
            </div>
        </div>


        <div className='row mx-2 my-5 attribute-section'>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <img src="/pd5.png" alt="pd1" />
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h4>Smart Selfie</h4>
                <p>With two Sony camera sensors, one for the front and one for the back cameras, 
you'll be able to take unrivalled photographs regardless of the illumination. 
You can flamboyantly record memorable recollections of your life.</p>
            </div>
        </div> */}


    </div>
  )
}

export default Description