import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./MerchantInventory.scss"
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'
import TwoBDialog from './TwoBDialog'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash'
import {renderName} from '../../Utils/Functions'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import moment from 'moment'
function MerchantInventory(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [open2,setOpen2]=React.useState(false)
    const [data,setData]=React.useState([])

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/approval/mine`,{headers:{token:props.user.user}})
        .then(res=>{
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setData(arr)
        })
        .catch(err=>{
        })
    },[])

  return (
    <div>
        <TwoBDialog open={open2} setOpen={setOpen2} />
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={2} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>


                {props.user.userInfo.kyc?<section>
                <Button startIcon={<AddIcon />} className="mb-3"  onClick={()=>setOpen2(true)}>Add Inventory Item</Button>

                <div style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    autoPageSize
                    // onRowClick={(item,ev)=>props.history.push("/productdetail",item.row)}
                />
                </div>
                </section>:
                <section style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100%"}}>
                    <div>
                    <FindInPageIcon sx={{fontSize:"3em"}} />
                    </div>
                    <div>
                    <h3>Your account is under review</h3>
                    </div>
                </section>
                }

            </section>
        </div>
    </div>
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MerchantInventory)

const columns = [
    { field: 'id', headerName: 'ID',width:20},
    { field: 'product', headerName: 'Product Name',valueGetter:(param)=>{
        const renderNameVar = (product,variations)=>{
            let name = product.name
            _.map(variations.options,(value,key)=>{
                if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
                    name = name + " " + `(${value})`
                }
            })
            return name
        }
        return renderNameVar(param.row.product[0],param.row.variationDetails)},width:250},
    {field:"inStock",headerName:"In Stock",valueGetter:(param)=>param.value.toString(),width:150},
    {field:"status",headerName:"Status",valueGetter:(param)=>param.value.toString(),width:150},
    {field:"updatedAt",headerName:"Last Updated",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];