import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./MerchantOrders.scss"
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'
import moment from 'moment';
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {formatMoney} from '../../Utils/Functions'
import axios from 'axios'
function CompletedOrders(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [orders,setOrders]=React.useState([])
    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/order/merchant`,{headers:{token:props.user.user}})
        .then(res=>{
            if(Array.isArray(res.data.result)){
                setOrders(res.data.result.filter(item=>item.status==="Completed"))
            }
            
        })
        .catch(err=>{
        })
    },[])
    const renderStatus = (status)=>{
        if(status.toLowerCase()==="in process"){
            return "status"
        }else if(status.toLowerCase()==="delivered" || status.toLowerCase()==="completed"){
            return "green-status"
        }else{
            return "red-status"
        }
    }
  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={6} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>
                <div className="row m-auto table-head">
                    <div className="col-2">
                        <h3>Sr No</h3>
                    </div>
                    <div className="col-2">
                        <h3>Status</h3>
                    </div>
                    <div className="col-3">
                        <h3>Date Purchased</h3>
                    </div>
                    <div className="col-2">
                        <h3>Items</h3>
                    </div>
                    <div className="col-3">
                        <h3>Total Amount</h3>
                    </div>
                </div>


                {
                    orders.length>0?(
                        orders.map((item,index)=>(
                            <Link key={index} style={{color:"inherit"}} to={`/merchantorderdetails?${item._id}`}>
                            <div className="row m-auto table-content" key={index}>
                            <div className="col-2">
                                <p className="index">{index+1}</p>
                            </div>
                            <div className="col-2 stat-cont">
                                <p style={{wordWrap:"break-word"}} className={renderStatus(item.status)}>{item.status}</p>
                            </div>
                            <div className="col-3">
                                <p className="createdat">{moment.parseZone(item.createdAt).local().format("MMMM Do YYYY, h:mm a")}</p>
                            </div>
                            <div className="col-2">
                                <p className="length">{item.items.length}</p>
                            </div>
                            <div className="col-3">
                                <p className="total"><b>{formatMoney(item.total)}</b></p>
                                {item.appliedOffer&&<p className="green-status">Cashback: ₹{item.appliedOffer.order.value}</p>}
                            </div>
                        </div>
                        </Link>
                        ))
                    ):null
                }


        

            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompletedOrders)