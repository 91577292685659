import React from 'react'
import "./ContactUs.scss"
import Header from '../../components/Header/Header'
import SubHeader from '../../components/Header/SubHeader'
import Footer from '../../components/Footer/Footer'
import {useForm} from 'react-hook-form'
import {TextField,Button} from '@mui/material'
function ContactUs() {
  return (
    <div>
        <Header />
        <SubHeader />

        <section className="contactus">

        

        <div className="row m-auto">
          <div className="col-7 img-container">
          <img src="/contactusimage.png" alt="contactus" />
          </div>
          <div className="col-4 input-div">
            <h1>Contact Us, we'll try to reach asap!</h1>
            <TextField fullWidth className="my-3 textfield" id="outlined-basic" variant="outlined" label="Name" />
            <TextField fullWidth className="my-3 textfield" id="outlined-basic" variant="outlined" label="Email" />
            <TextField fullWidth className="my-3 textfield" id="outlined-basic" variant="outlined" label="Mobile Number" />
            <TextField
            className="my-3 textfield"
            fullWidth
          id="outlined-multiline-static"
          label="Query"
          multiline
          rows={3}
        />
            <Button fullWidth variant="contained">Send</Button>
          </div>
        </div>
        
        </section>
        <Footer />
        </div>
  )
}

export default ContactUs