import React from 'react'
import Header from '../Header/Header'
import SubHeader from '../Header/SubHeader'
import Footer from '../Footer/Footer'
import "./Cart.scss"
import {IconButton,Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {connect} from 'react-redux'
import { getWishList } from '../redux/wishlist/wishlistActions';
import {getCart,addToCart,removeFromCart,increaseItemCount,decreaseItemCount,addItemToUserCart,removeItemFromUserCart,handleItemCartCount} from '../redux/cart/cartActions'
import { formatMoney,percentage,cartTotal,cartMrpTotal,cartDiscountTotal } from '../Utils/Functions'
import {wishListAction,addToWishListReq,removeFromWishListReq} from '../redux/wishlist/wishlistActions'
import {addItems} from '../redux/checkout/checkoutActions'
import _ from 'lodash'
function Cart(props) {

  React.useEffect(()=>{
    if(props.user.user){
      props.getWishList(props.user.user)
      props.getCart(props.user.user)
  }else{
    props.history.push("/signin",{route:"/cart"})
  }
  },[])

   const renderName = (product,options)=>{
    let name = product.name
    _.map(options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}

  return (
    <div>
      <Header />
      <SubHeader />
      <section className="desktop-container cart-container row m-auto">
          <section className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 cart-cards-container">
              <h1>My Cart ({props.cart.length})</h1>

              {
                props.cart.length>0?props.cart.map((item,index)=>(
                  <section className="cart-card-head">
                  <div className="col-12 close p-0" style={{textAlign:"right"}}>
                  <IconButton onClick={()=>{
                    //props.removeFromWishListReq(props.user.user,item)
                    props.removeItemFromUserCart(item,props.user.user)
                    }}>
                      <CloseIcon />
                  </IconButton>
                </div>
                  <div key={index} className="cart-card row my-4 mx-auto p-0">
                  <div className="col-10 col-sm-10 col-md-3 col-lg-3 col-xl-3 img-card">
                    {item.variations.item.images.length>0?
                    <img src={item.variations.item.images[0]} alt={item.variations.item.images[0]} />
                    :<img src="/cleverpeproduct.png" alt="oppo" />}
                  </div>
                  <div className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8 info-cont">
                    <h2>{renderName(item.variations.item.product[0],item.variations.item.options)}</h2>
                    <h3>{formatMoney(item.variations.item.options.price)}</h3>
                    <p className="percent"><span className="regular-price">{formatMoney(item.variations.item.options.mrp)}</span>{percentage(item.variations.item.options.mrp,item.variations.item.options.price)}% off</p>
                    <p className="downpayment">Downpayment</p>
                    <p className="emi">EMI From<span className='amount'>₹2300/mon*</span></p>
                    <h5 className='freehome'>Free Home Delivery</h5>

                    <div className="row m-auto button-div align-items-center">
                      <div className="col-1">
                        <RemoveIcon onClick={()=>{
                          if(item.variations.quantity!==1){
                            props.handleItemCartCount(item,false,props.user.user)
                          }
                          }} className="button-icon" />
                      </div>
                      <div className="col-1" style={{textAlign:"right"}}>
                        <h2>{item.variations.quantity}</h2>
                      </div>
                      <div className="col-1">
                      <AddIcon onClick={()=>props.handleItemCartCount(item,true,props.user.user)} className="button-icon" />
                      </div>
                    </div>

                    </div>
              </div>
              </section>
                )):
                <div style={{textAlign:"center"}}>
                  <img className="cartimg" src="/emptycart.png" alt="emptycart" />
                  <h1>Cart is Empty</h1>
                </div>
              }

              <h1>My Wishlist ({props.wishList.length})</h1>

              {
                props.wishList.length>0?props.wishList.map((item,index)=>(
                  <section className="cart-card-head">
                    {
                    }
                      <div className="col-12 close p-0" style={{textAlign:"right"}}>
                      <IconButton onClick={()=>props.removeFromWishListReq(props.user.user,{variations:{_id:item.variations.item._id}})}>
                          <CloseIcon />
                      </IconButton>
                    </div>
                  <div key={index} className="cart-card row my-4 mx-auto p-0">
                  <div className="col-10 col-sm-10 col-md-3 col-lg-3 col-xl-3 img-card">
                    {item.variations.item.images.length>0?
                    <img src={item.variations.item.images[0]} alt={item.variations.item.images[0]} />
                    :<img src="/cleverpeproduct.png" alt="oppo" />}
                  </div>
                  <div className="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8 info-cont">
                    <h2>{renderName(item.variations.item.product[0],item.variations.item.options)}</h2>
                    <h3>{formatMoney(item.variations.item.options.price)}</h3>
                    <p className="percent"><span className="regular-price">{formatMoney(item.variations.item.options.mrp)}</span>{percentage(item.variations.item.options.mrp,item.variations.item.options.price)}% off</p>
                    <p className="downpayment">Downpayment</p>
                    <p className="emi">EMI From<span className='amount'>₹2300/mon*</span></p>
                    <h5 className='freehome'>Free Home Delivery</h5>

                    <div className="cart-button">
                      <Button onClick={()=>{
                        let varn = {variations:{_id:item.variations.item._id}}
                        props.addItemToUserCart(varn,props.user.user)
                        props.removeFromWishListReq(props.user.user,item)
                      }} variant="contained">Move to cart</Button>
                    </div>

                    </div>


              </div>
              </section>
                )):
                <div style={{textAlign:"center"}}>
                <img className="wishlistimg" src="/emptywishlist.png" alt="emptycart" />
                <h1>Wishlist is Empty</h1>
              </div>
              }



          </section>
          {props.cart.length>0&&<section className='col-11 col-sm-12 col-md-12 col-lg-3 col-3 checkout-container'>
          <h1>Summary</h1>
            <p>Price: <span className="regular-price">{cartMrpTotal(props.cart)}</span></p>
            <p>Discount: <span className="percentage">-{cartDiscountTotal(props.cart)}</span></p>
            <p>Delivery: <span className="percentage">Free Home Delivery</span></p>
            <p>Total Amount:</p>
            <h2>{cartTotal(props.cart)}</h2>
            <p>You'll save <span className="percentage">-{cartDiscountTotal(props.cart)}</span> on this order</p>
            <Button onClick={()=>{
              props.addItems(props.cart)
              props.history.push("/checkout")
              }} className="my-3" variant="contained" fullWidth>proceed to checkout</Button>
          </section>}
      </section>
      <Footer />
    </div>
  )
}
const mapStateToProps = ({cleverpeUser,cart,wishList})=>{
  return {
      user:cleverpeUser,
      cart,
      wishList
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
      getWishList:(token)=>dispatch(getWishList(token)),
      getCart:(token)=>dispatch(getCart(token)),
      wishListAction:(item)=>dispatch(wishListAction(item)),
      addToCart:(item)=>dispatch(addToCart(item)),
      increaseItemCount:(id)=>dispatch(increaseItemCount(id)),
      decreaseItemCount:(id)=>dispatch(decreaseItemCount(id)),
      removeFromCart:(item)=>dispatch(removeFromCart(item)),
      addItemToUserCart:(item,token)=>dispatch(addItemToUserCart(item,token)),
      removeItemFromUserCart:(item,token)=>dispatch(removeItemFromUserCart(item,token)),
      removeFromWishListReq:(token,item)=>dispatch(removeFromWishListReq(token,item)),
      addToWishListReq:(token,item)=>dispatch(addToWishListReq(token,item)),
      handleItemCartCount:(item,check,token)=>dispatch(handleItemCartCount(item,check,token)),
      addItems:(item)=>dispatch(addItems(item))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Cart)