import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import SubHeader from "../../Header/SubHeader";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DashboardMenu from "../MerchantDashhead";
import { IconButton, TextField, Button, Alert } from "@mui/material";
import {
  formatMoney,
  renderName2,
  orderSubTotal,
  orderTotal,
  orderDiscount,
} from "../../Utils/Functions";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import "./MerchantOrders.scss";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { setLoading } from "../../redux/loading/loadingActions";
import { Link } from "react-router-dom";
import LoanDetails from "../../Utils/LoanDetails";
const Input = styled("input")({
  display: "none",
});
function SingleOrder(props) {
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState(null);
  const [rto, setRto] = React.useState("");
  const [consentForm, setConsentForm] = React.useState("");
  const [insuranceDoc, setInsuranceDoc] = React.useState("");
 

  console.log("order songle", order);

  const getOrder = () => {
    props.setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/order/single`,
        { orderId: props.location.search.slice(1) },
        { headers: { token: props.user.user } }
      )
      .then(async (res) => {
        console.log(res);
        if (res.data.result === "Data Missing") {
          props.setLoading(false);
        } else {
          if (res.data.result.cusProdImg) {
            const response = await fetch(
              `${process.env.REACT_APP_DEVELOPMENT}/api/order/cusProdImg/${res.data.result.cusProdImg}`
            );
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setImageSrc(url);
            props.setLoading(false);
          }
           else {
            setImageSrc(null);
            props.setLoading(false);
          }
          setOrder(res.data.result);
        }
      })
      .catch((err) => {
        props.setLoading(false);
      });
  };

  React.useEffect(() => {
    if (order) {
      if (order?.files?.length > 0) {
        order?.files?.map((item) => {
          if (item.docName === "rto") {
            setRto(item.filename);
          }
          if (item.docName === "consentForm") {
            setConsentForm(item.filename);
          }
          if (item.docName === "insuranceDoc" ) {
            setInsuranceDoc(item.filename);
          }
        });
      }
    }
  }, [props.user.userInfo]);
  React.useEffect(() => {
    props.setLoading(true);
    getOrder();
  }, []);

  const renderStatus = (status) => {
    if (status) {
      if (status.toLowerCase() === "processing") {
        return "status";
      } else if (
        status.toLowerCase() === "delivered" ||
        status.toLowerCase() === "completed"
      ) {
        return "green-status";
      } else if (status.toLowerCase() === "verification pending") {
        return "yellow-status";
      } else {
        return "red-status";
      }
    }
  };

  const renderUploadButton = () => {
    if (
      order.status === "Completed" ||
      order.status === "Verification Pending"
    ) {
      return (
        <label htmlFor="contained-button-Invoice">
          <Input
            onChange={(event) => {
              //setSelectedImage(event.target.files[0]);
              console.log(event.target.files[0]); //application/pdf
              const formdata = new FormData();
              if (
                !Array.isArray(event.target.files[0]) &&
                event.target.files[0].size < 1000000
              ) {
                setError("");
                formdata.append("image", event.target.files[0]);
                props.setLoading(true);
                axios
                  .post(
                    `${process.env.REACT_APP_DEVELOPMENT}/api/order/uploadReciept/${order._id}`,
                    formdata,
                    {
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        token: props.user.user,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res);
                    //props.getUserInfo(props.user.user)
                    setTimeout(() => {
                      getOrder();
                    }, 3000);
                  })
                  .catch((err) => {
                    props.setLoading(false);
                  });
              } else {
                setError("Size greater than 1 MB");
              }
            }}
            accept="image/*"
            id="contained-button-Invoice"
            type="file"
          />
          <Button
            fullWidth
            variant="contained"
            component="span"
            endIcon={<CameraAltIcon />}
          >
            upload invoice
          </Button>
        </label>
      );
    } else {
      return <div />;
    }
  };

  const renderUploadButton2 = () => {
    if (
      order.status === "Completed" ||
      order.status === "Verification Pending"
    ) {
      return (
        <label htmlFor="contained-button-customerImage">
          <Input
            onChange={(event) => {
              //setSelectedImage(event.target.files[0]);
              console.log(event.target.files[0]); //application/pdf
              const formdata = new FormData();
              if (
                !Array.isArray(event.target.files[0]) &&
                event.target.files[0].size < 10000000
              ) {
                setError("");
                formdata.append("image", event.target.files[0]);
                props.setLoading(true);
                axios
                  .post(
                    `${process.env.REACT_APP_DEVELOPMENT}/api/order/mCusProdImg/${order._id}`,
                    formdata,
                    {
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        token: props.user.user,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res);

                    //props.getUserInfo(props.user.user)
                    setTimeout(() => {
                      getOrder();
                    }, 3000);
                  })
                  .catch((err) => {
                    props.setLoading(false);
                  });
              } else {
                setError("Size greater than 1 MB");
              }
            }}
            accept="image/*"
            id="contained-button-customerImage"
            type="file"
          />
          <Button
            fullWidth
            variant="outlined"
            component="span"
            endIcon={<CameraAltIcon />}
          >
            upload customer Image
          </Button>
        </label>
      );
    } else {
      return <div />;
    }
  };

  const renderImageorPdf = () => {
    if (order.reciept.includes(".pdf")) {
      return (
        <Link
          to="#"
          onClick={() =>
            window.open(
              `https://api.cleverpe.com/api/user/image/${order.reciept}`,
              "_blank"
            )
          }
        >
          View Reciept
        </Link>
      );
    } else {
      return (
        <img 
        style={{border:"2px black solid"}}
          className="document-img"
          src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${order.reciept}`}
          alt="fornt"
        />
      );
    }
  };

 

  const renderStatusString = (status) => {
    if (status.toLowerCase() === "completed") {
      return "Customer Tranasction is Successful";
    } else if (status.toLowerCase() === "failed") {
      return "Customer Tranasction has Failed";
    } else if (status.toLowerCase() === "verification pending") {
      return "Customer Product Verification Pending";
    } else {
      return "Customer Hasn't Paid Yet";
    }
  };

  const renderMerchantPaymentStatus = (status) => {
    if (status) {
      return "Disbursed";
    } else {
      return "Pending";
    }
  };

  return (
    <div>
      <Header />
      <div className="desktop-visible">
        <SubHeader />
      </div>
      <div className="my-profile">
        <div className="desktop-container row m-auto">
          <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
            <DashboardMenu id={3} open={open} setOpen={setOpen} />
          </section>
          <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
            <div className="mobile-visible">
              <IconButton onClick={() => setOpen(true)}>
                <MenuIcon />
              </IconButton>
            </div>

            {!_.isEmpty(order) ? (
              order.type === "Onsite" ? (
                <section className="single-order">
                  <div className="row m-auto justify-content-between">
                    <p>
                      Order Id: <span className="boldspan">{order.orderId}</span>
                    </p>
                    <p>
                      Created At:{" "}
                      <span className="boldspan">
                        {moment
                          .parseZone(order.createdAt)
                          .local()
                          .format("MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </p>
                    <p>Delivered On: -</p>
                  </div>

                  {/* {order.appliedOffer&&<>
                    <img src="/offer.png" alt="offer" style={{width:"10%"}} className='my-3' /> <span className='green-status'>You have won a cashback of ₹<b>{order.appliedOffer.order.value}</b></span>
                </>} */}
                  {/* <img src="/cashback.jpg" alt="offer" style={{width:"60%",display:"block",margin:"auto"}} className='my-3' /> 
                    <p className='green-status m-3' style={{textAlign:"center"}}>Congratulations! You've earned a cashback reward of ₹<b style={{fontSize:"2em"}}>250</b></p> */}
                  {order.appliedOffer && (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="/cashback.jpg"
                        alt="offer"
                        style={{
                          width: "60%",
                          display: "block",
                          margin: "auto",
                        }}
                        className="my-3"
                      />
                      <p
                        className="green-status m-3"
                        style={{ textAlign: "center" }}
                      >
                        Congratulations! You've earned a cashback reward of ₹
                        <b style={{ fontSize: "2em" }}>
                          {order.appliedOffer.order.value}
                        </b>
                      </p>
                    </div>
                  )}

                  <h4 className={renderStatus(order.status)}>
                    {renderStatusString(order.status)}
                  </h4>
                  <h5 className="my-2">
                    Cleverpe Merchant Payment Status:{" "}
                    {renderMerchantPaymentStatus(order.merchantPayment)}
                  </h5>
                  <h5 className="my-2">UTR: {order.utr}</h5>

                  <h5>
                    Order Type:{" "}
                    {order.type.toLowerCase() === "onsite"
                      ? "instore"
                      : "online"}
                  </h5>
                  {order.type === "Onsite" && (
                    <>
                      <h5>Customer Name: {order.customer.name}</h5>
                      <h5>Customer Mobile No: {order.customer.mobileNo}</h5>
                    </>
                  )}

                  {order.items.length > 0 &&
                    order.items.map((item, index) => (
                      <div
                        key={index}
                        className="row m-auto item-order-container"
                      >
                        <div className="col-2">
                          {item.details.images.length > 0 ? (
                            <img src={item.details.images[0]} alt="oppo" />
                          ) : (
                            <img src="/cleverpeproduct.png" alt="oppo" />
                          )}
                        </div>
                        <div className="col-7">
                          <h5>
                            {renderName2(
                              item.details.product[0],
                              item.details.options
                            )}
                          </h5>
                          <p>
                            {formatMoney(item.productPrice)} x {item.quantity}
                          </p>
                        </div>
                      </div>
                    ))}

                  <div className="row m-auto shipping-container justify-content-between">
                    <div className="col-5 shipping">
                      <h4>User Details</h4>
                      <p>Name: {order.userDetails.name}</p>
                      <p>Email: {order.userDetails.email}</p>
                      <p>Phone: {order.userDetails.mobileNo}</p>
                      <hr />
                      {props?.user?.userInfo?.group[0]?.eV != true ? (
                        <>
                          <h4>Shipping Address</h4>
                          <p>
                            Address:{" "}
                            {order.shipping && order.shipping.address_1}
                          </p>
                          <p>City: {order.shipping && order.shipping.city}</p>
                          <p>
                            Pincode: {order.shipping && order.shipping.postcode}
                          </p>
                        </>
                      ) : (
                        (
                          order.status === "Completed" ||
                          order.status === "Verification Pending"
                        ) 
                        &&
                        
                        <div>
                          <h1 style={{fontWeight:"bold"}}>Upload Documents</h1>
                          
                                        <p
                                          style={{
                                            color: "#F9D973",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Note:image size should be
                                          less than 1MB
                                        </p>


                          <div style={{width:"250%"}} className="d-flex flex-wrap justify-content-between"
>
                            <div>
                            {props?.user?.userInfo?.group[0]?.eV &&(
                              <>
                                <div style={{zIndex:"999"}}>
                                  <div
                                    className="mt-4 mb-2"
                                    style={{ textAlign: "center" }}
                                  >
                                  <h3 style={{fontWeight:"bold"}}>RTO Registration Reciept</h3>
                                    {rto?.length <= 0 ? (
                                      <label htmlFor="contained-button-RTO">
                                        <Input
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            if (
                                              file
                                            ) {
                                              setRto(file);
                                              const formdata = new FormData();
                                              formdata.append("image", file);
                                              props.setLoading(true);
                                              axios
                                                .post(
                                                  `${process.env.REACT_APP_DEVELOPMENT}/api/order/upload/doc/${order._id}/rto`,
                                                  formdata,
                                                  {
                                                    headers: {
                                                      Accept:
                                                        "application/json",
                                                      "Content-Type":
                                                        "multipart/form-data",
                                                      token: props.user.user,
                                                    },
                                                  }
                                                )
                                                .then((res) => {
                                                  props.setLoading(false);
                                                  props.getUserInfo(
                                                    props.user.user
                                                  );
                                                })
                                                .catch((err) => {
                                                  props.setLoading(false);
                                                });
                                            } else {
                                              // Handle the case when the uploaded file is not a PDF
                                              alert(
                                                "Please upload a file."
                                              );
                                            }
                                          }}
                                          accept="image/*" // Only accept PDF files
                                          id="contained-button-RTO"
                                          type="file"
                                        />
                                        <Button
                                          variant="contained"
                                          component="span"
                                          endIcon={<CameraAltIcon />}
                                        >
                                         Upload
                                        </Button>
                                      </label>
                                    ) : (
                                      <img
                                        className="document-img"
                                        src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${rto}`}
                                        alt="rto"
                                        style={{border:"2px solid black"}}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div>
                                  <div
                                    className="mt-4 mb-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3 style={{fontWeight:"bold"}}>Customer Consent Form</h3>
                                    {consentForm?.length <= 0 ? (
                                      <label htmlFor="contained-button-consentForm">
                                        <Input
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            if (
                                              file
                                            ) {
                                              setConsentForm(file);
                                              const formdata = new FormData();
                                              formdata.append("image", file);
                                              props.setLoading(true);
                                              axios
                                                .post(
                                                  `${process.env.REACT_APP_DEVELOPMENT}/api/order/upload/doc/${order._id}/consentForm`,
                                                  formdata,
                                                  {
                                                    headers: {
                                                      Accept:
                                                        "application/json",
                                                      "Content-Type":
                                                        "multipart/form-data",
                                                      token: props.user.user,
                                                    },
                                                  }
                                                )
                                                .then((res) => {
                                                  props.setLoading(false);
                                                  props.getUserInfo(
                                                    props.user.user
                                                  );
                                                })
                                                .catch((err) => {
                                                  props.setLoading(false);
                                                });
                                            } else {
                                              // Handle the case when the uploaded file is not a PDF
                                              alert(
                                                "Please upload a file."
                                              );
                                            }
                                          }}
                                          accept="image/*" // Only accept PDF files
                                          id="contained-button-consentForm"
                                          type="file"
                                        />
                                        <Button
                                          variant="contained"
                                          component="span"
                                          endIcon={<CameraAltIcon />}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                    ) : (
                                      <img
                                        className="document-img"
                                        src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${consentForm}`}
                                        alt="consentForm"
                                        style={{border:"2px solid black"}}
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            </div>


                            
                              
                            <div style={{zIndex:"999"}}>
                            {props?.user?.userInfo?.group[0]?.eV && (
                              <>
                    
                                <div>
                                  <div
                                    className="mt-4 mb-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3 style={{fontWeight:"bold"}}>Insurance Document</h3>
                                    {insuranceDoc?.length <= 0 ? (
                                      <label htmlFor="contained-button-InsuranceDoc">
                                        <Input
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            if (
                                              file 
                                            ) {
                                              setInsuranceDoc(file);
                                              const formdata = new FormData();
                                              formdata.append("image", file);
                                              props.setLoading(true);
                                              axios
                                                .post(
                                                  `${process.env.REACT_APP_DEVELOPMENT}/api/order/upload/doc/${order._id}/insuranceDoc`,
                                                  formdata,
                                                  {
                                                    headers: {
                                                      Accept:
                                                        "application/json",
                                                      "Content-Type":
                                                        "multipart/form-data",
                                                      token: props.user.user,
                                                    },
                                                  }
                                                )
                                                .then((res) => {
                                                  props.setLoading(false);
                                                  props.getUserInfo(
                                                    props.user.user
                                                  );
                                                })
                                                .catch((err) => {
                                                  props.setLoading(false);
                                                });
                                            } else {
                                              // Handle the case when the uploaded file is not a PDF
                                              alert(
                                                "Please upload a file."
                                              );
                                            }
                                          }}
                                          accept="image/*" // Only accept PDF files
                                          id="contained-button-InsuranceDoc"
                                          type="file"
                                        />
                                        <Button
                                          variant="contained"
                                          component="span"
                                          endIcon={<CameraAltIcon />}
                                        >
                                         Upload
                                        </Button>
                                      </label>
                                    ) : (
                                      <img
                                        className="document-img"
                                        src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${insuranceDoc}`}
                                        alt="insuraceDoc"
                                        style={{border:"2px solid black"}}
                                      />
                                    )}
                                  </div>
                                </div>

                                {props?.user?.userInfo?.group[0]?.eV && (
                                  <>
                                    <div
                                      className="mt-4 mb-2"
                                      style={{ textAlign: "center" }}
                                    >
                                    <h3 style={{fontWeight:"bold"}}>Invoice</h3>
                        
                                      {!order.reciept
                                        ? renderUploadButton()
                                        : renderImageorPdf()}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            </div>
                          </div>
                                     
                          
                        </div>
                      )}
                    </div>
            
                    <div className="col-6 summary">
                      <h4>Total Summary</h4>
                      {/* <div className="flex-row-item">
                            <div>
                            <p className="key">Subtotal</p>
                            </div>
                            <div>
                            <p className="value">{orderSubTotal(order.items)}</p>
                            </div>
                        </div>

                        <div className="flex-row-item">
                            <div>
                            <p className="key">Delivery</p>
                            </div>
                            <div>
                            <p className="value">0</p>
                            </div>
                        </div>

                        <div className="flex-row-item">
                            <div>
                            <p className="key">Discount</p>
                            </div>
                            <div>
                            <p className="value" style={{color:"green"}}>{orderDiscount(order.items)}</p>
                            </div>
                        </div> */}

                      <div className="flex-row-item total">
                        <div>
                          <p className="key">Total</p>
                        </div>
                        <div>
                          <p className="value">{order.total}</p>
                        </div>
                      </div>

                      {!props?.user?.userInfo?.group[0]?.eV && (
                        <>
                          <div
                            className="mt-4 mb-2"
                            style={{ textAlign: "center" }}
                          >
                            <h3 style={{fontWeight:"bold"}}>Invoice</h3>
                            {!order.reciept && (
                              <p style={{ color: "red", fontWeight: "bold" }}>
                                Note: Invoice image size should be less than 1MB
                              </p>
                            )}
                            {!order.reciept
                              ? renderUploadButton()
                              : renderImageorPdf()}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="col-12 mt-4 mb-2"
                      style={{ textAlign: "center" }}
                    >
                      <h4 className="mb-3 pb-4">Customer Image with product</h4>
                      {!order.cusProdImg && (
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          Note: Customer image with product should be less than
                          10MB
                        </p>
                      )}

                      {!order.cusProdImg ? (
                        renderUploadButton2()
                      ) : (
                        <img
                          className="document-img"
                          src={imageSrc}
                          alt="imgsrc"
                        />
                      )}
                    </div>
                  </div>

                  {order.keyFactStatement && (
                    <LoanDetails {...order.keyFactStatement} />
                  )}
                </section>
              ) : (
                <section className="single-order">
                  <div className="row m-auto justify-content-between">
                    <p>
                      Order Id: <span className="boldspan">{order.orderId}</span>
                    </p>
                    <p>
                      Created At:{" "}
                      <span className="boldspan">
                        {moment
                          .parseZone(order.createdAt)
                          .local()
                          .format("MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </p>
                    <p>Delivered On: -</p>
                  </div>

                  <h4 className={renderStatus(order.status)}>{order.status}</h4>

                  <h5>Order Type: {order.type}</h5>

                  {order.items.length > 0 &&
                    order.items.map((item, index) => (
                      <div
                        key={index}
                        className="row m-auto item-order-container"
                      >
                        <div className="col-2">
                          {item.details.images.length > 0 ? (
                            <img src={item.details.images[0]} alt="oppo" />
                          ) : (
                            <img src="/cleverpeproduct.png" alt="oppo" />
                          )}
                        </div>
                        <div className="col-7">
                          <h5>
                            {renderName2(
                              item.details.product[0],
                              item.details.options
                            )}
                          </h5>
                          <p>
                            {formatMoney(item.details.options.price)} x{" "}
                            {item.quantity}
                          </p>
                        </div>
                      </div>
                    ))}
                  <div className="row m-auto shipping-container justify-content-between">
                    <div className="col-5 shipping">
                      <h4>User Details</h4>
                      <p>Name: {order.userDetails.name}</p>
                      <p>Email: {order.userDetails.email}</p>
                      <p>Phone: {order.userDetails.mobileNo}</p>
                      <hr />
                      <h4>Shipping Address</h4>
                      <p>
                        Address: {order.shipping && order.shipping.address_1}
                      </p>
                      <p>City: {order.shipping && order.shipping.city}</p>
                      <p>
                        Pincode: {order.shipping && order.shipping.postcode}
                      </p>
                    </div>
                    <div className="col-6 summary">
                      <h4>Total Summary</h4>
                      <div className="flex-row-item">
                        <div>
                          <p className="key">Subtotal</p>
                        </div>
                        <div>
                          <p className="value">{orderSubTotal(order.items)}</p>
                        </div>
                      </div>

                      <div className="flex-row-item">
                        <div>
                          <p className="key">Delivery</p>
                        </div>
                        <div>
                          <p className="value">0</p>
                        </div>
                      </div>

                      <div className="flex-row-item">
                        <div>
                          <p className="key">Discount</p>
                        </div>
                        <div>
                          <p className="value" style={{ color: "green" }}>
                            {orderDiscount(order.items)}
                          </p>
                        </div>
                      </div>

                      <div className="flex-row-item total">
                        <div>
                          <p className="key">Total</p>
                        </div>
                        <div>
                          <p className="value">{orderTotal(order.items)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            ) : null}

            {error.length > 0 && (
              <Alert className="alert" severity="error">
                {error}
              </Alert>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ cleverpeUser }) => {
  return {
    user: cleverpeUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleOrder);
