import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./Settlements.scss"
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormGroup from '@mui/material/FormGroup';
import * as FileSaver from 'file-saver';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from "@mui/x-date-pickers";
import {useForm} from 'react-hook-form'
import axios from 'axios'
function Settlements(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedDate2, setSelectedDate2] = React.useState("");
    const [error,setError] = React.useState("")
    console.log("settlementprops",props)
  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={7} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>



                <form className="form">
            <h2 className="mb-3">Export Transaction Dump For Merchants (Excel)</h2>

<div className="row m-auto">
<section className="my-2 col-6">
<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
    label="Start Date"
    inputFormat="dd/MM/yyyy"
    value={selectedDate}
    onChange={(newValue) => {
        console.log(newValue);
        setSelectedDate(newValue);
    }}
    renderInput={(params) => <TextField fullWidth {...params} />} />
</LocalizationProvider>
</section>

<section className="my-2 col-6">
<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
    label="End Date"
    inputFormat="dd/MM/yyyy"
    value={selectedDate2}
    onChange={(newValue) => {
        console.log(newValue);
        setSelectedDate2(newValue);
    }}
    renderInput={(params) => <TextField fullWidth {...params} />} />
</LocalizationProvider>
</section>
</div>

{error.length>0?<p style={{color:"red",fontWeight:"bold"}}>{error}</p>:null}
<div className="my-3" style={{textAlign:"center"}}>
<Button onClick={async ()=>{
    axios({
    url: `${process.env.REACT_APP_DEVELOPMENT}/api/order/settlement`,
    method: 'POST',
    responseType: 'blob',
    data:{date:selectedDate,date1:selectedDate2},
    headers:{token:props.user.user}
    })
    .then(response=>{
        setError("")
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'settlement.xlsx');
    })
    .catch(err=>{
        setError("Data not found")
    })
    
    

}} variant="contained">Download Excel</Button>

</div>
</form>

            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Settlements)