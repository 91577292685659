import axios from 'axios'

export const wishListAction = (item)=>{
    return {
        type:"ADD_REMOVE_ITEM",
        payload:item
    }
}

export const setWishList = (itemArray)=>{
    return {
        type:"SET_WISHLIST",
        payload:itemArray
    }
}

export const addToWishList = (item)=>{
    return {
        type:"ADD_TO_WISHLIST",
        payload:item
    }
}

export const removeFromWishList = (item)=>{
    return {
        type:"REMOVE_FROM_WISHLIST",
        payload:item
    }
}

export const getWishList = (token)=>{
    return async (dispatch)=>{
        return await axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/wishlist/single`,{headers:{token}})
        .then(res=>{
            dispatch(setWishList(res.data.result))
        })
        .catch(err=>{
        })
    }
}

export const addToWishListReq = (token,item)=>{
    return async (dispatch)=>{
        return await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/wishlist/add`,{variationId:item.variations._id},{headers:{token}})
        .then(res=>{
            dispatch(getWishList(token))
        })
        .catch(err=>{
        })
    }
}
export const removeFromWishListReq = (token,item)=>{
    return async (dispatch)=>{
        return await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/wishlist/remove`,{variationId:item.variations._id},{headers:{token}})
        .then(res=>{
            dispatch(getWishList(token))
        })
        .catch(err=>{
        })
    }
}