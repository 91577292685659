import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardMenu from '../DashboardMenu';
import { IconButton,TextField,Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./MyWishlist.scss"
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash'
import {getCart,addToCart,removeFromCart,increaseItemCount,decreaseItemCount,addItemToUserCart,removeItemFromUserCart,handleItemCartCount} from '../../redux/cart/cartActions'
import { formatMoney,percentage,cartTotal,cartMrpTotal,cartDiscountTotal } from '../../Utils/Functions'
import {wishListAction,addToWishListReq,removeFromWishListReq,getWishList} from '../../redux/wishlist/wishlistActions'
import {connect} from 'react-redux'

function MyWishlist(props) {
    const [open,setOpen]=React.useState(false)
    const [edit,setEdit]=React.useState(false)
    const [gender, setGender] = React.useState(10);

    const handleChange = (event) => {
        setGender(event.target.value);
    };
    const renderName = (product,options)=>{
        let name = product.name
        _.map(options,(value,key)=>{
            if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
                name = name + " " + `(${value})`
            }
        })
        return name
    }
  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    <div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <DashboardMenu id={3} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container" style={{backgroundColor:"#f7f7f7"}}>
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>


                <section className="cart-container" >
                <h1>My Wishlist ({props.wishList.length})</h1>

                    {
                    props.wishList.length>0?props.wishList.map((item,index)=>(
                        <div key={index} className="cart-card row my-4 mx-auto p-0">
                        <div className="col-3 img-card">
                        {item.variations.item.images.length>0?
                        <img src={item.variations.item.images[0]} alt={item.variations.item.images[0]} />
                        :<img src="/cleverpeproduct.png" alt="oppo" />}
                        </div>
                        <div className="col-8 info-cont">
                        <h2>{renderName(item.variations.item.product[0],item.variations.item.options)}</h2>
                        <h3>{formatMoney(item.variations.item.options.price)}</h3>
                        <p className="percent"><span className="regular-price">{formatMoney(item.variations.item.options.mrp)}</span>{percentage(item.variations.item.options.mrp,item.variations.item.options.price)}% off</p>
                        <p className="downpayment">Downpayment</p>
                        <p className="emi">EMI From<span className='amount'>₹2300/mon*</span></p>
                        <h5 className='freehome'>Free Home Delivery</h5>

                        <div className="">
                            <Button onClick={()=>{
                            let varn = {variations:{_id:item.variations.item._id}}
                            props.addItemToUserCart(varn,props.user.user)
                            props.removeFromWishListReq(props.user.user,item)
                            }} variant="contained">Move to cart</Button>
                        </div>

                        </div>

                        <div className="col-1 close p-0" style={{textAlign:"right"}}>
                            <IconButton onClick={()=>props.removeFromWishListReq(props.user.user,item)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    )):
                    <div style={{textAlign:"center"}}>
                    <img className="wishlistimg" src="/emptywishlist.png" alt="emptycart" />
                    <h1>Wishlist is Empty</h1>
                  </div>
                    }

</section>

            </section>
        </div>
    </div>
    <Footer />
    </div>
  )
}
const mapStateToProps = ({cleverpeUser,cart,wishList})=>{
    return {
        user:cleverpeUser,
        cart,
        wishList
    }
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return {
        getWishList:(token)=>dispatch(getWishList(token)),
        getCart:(token)=>dispatch(getCart(token)),
        wishListAction:(item)=>dispatch(wishListAction(item)),
        addToCart:(item)=>dispatch(addToCart(item)),
        increaseItemCount:(id)=>dispatch(increaseItemCount(id)),
        decreaseItemCount:(id)=>dispatch(decreaseItemCount(id)),
        removeFromCart:(item)=>dispatch(removeFromCart(item)),
        addItemToUserCart:(item,token)=>dispatch(addItemToUserCart(item,token)),
        removeItemFromUserCart:(item,token)=>dispatch(removeItemFromUserCart(item,token)),
        removeFromWishListReq:(token,item)=>dispatch(removeFromWishListReq(token,item)),
        addToWishListReq:(token,item)=>dispatch(addToWishListReq(token,item)),
        handleItemCartCount:(item,check,token)=>dispatch(handleItemCartCount(item,check,token)),
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(MyWishlist)