import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./MerchantPayments.scss"
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'

import {useForm} from 'react-hook-form'
import axios from 'axios'
function MerchantPayments(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)


  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={4} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>



        merchant payments

            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MerchantPayments)