import React from 'react'
//transactionId=null&transactionStatus=0&transactionTime=1653887336811&leafRefNo=undefined
import "./TransactionStatus.scss"
import axios from 'axios'
import {connect} from 'react-redux'
var url_string = window.location.href
var url = new URL(url_string);
var transactionId = url.searchParams.get("transactionId");
var transactionStatus = url.searchParams.get("transactionStatus");
var transactionTime = url.searchParams.get("transactionTime");
var leafRefNo = url.searchParams.get("leafRefNo");


function TransactionStatus(props) {

  const [success,setSuccess]=React.useState(false)
var orderId = props.user.orderId;

  React.useEffect(()=>{
    if(transactionId.toString()!=="null"){
      setSuccess(true)
      axios.get(`${process.env.REACT_APP_CASHELIVE}/cashe/loanBooking/transactionStatus/${transactionId}`,{headers:{Authorization:`${process.env.REACT_APP_CASHELIVEKEY}`}})
      .then(res=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-0`,{orderId,transaction:res.data,status:"In Process"},{headers:{token:props.user.user}})
        .then(res=>{
          if(res.status===200){
            props.history.push("/myorders")
          }
          
        })
        .catch(err=>{
        })
      })
      .catch(err=>{

      })
    }else{

        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-0`,{orderId,transaction:{transactionId:""},status:"Not Paid"},{headers:{token:props.user.user}})
        .then(res=>{
          if(res.status===200){
            props.history.push("/myorders")
          }
          
        })
        .catch(err=>{
        })

    }
    
  },[])

  return (
    <div>
      {
        success?
        <div className="transaction-status-container">
        <img className="img" src="/success.png" alt="failed" />
        <h1 >Transaction <span style={{color:"#05d237"}}>Success</span></h1>
        <p>Transaction Successful with cleverpe, Thank you for shopping with Cleverpe</p>
        <p><b>Redirecting ...</b></p>
      </div>
          :
          <div className="transaction-status-container">
            <img className="img" src="/failed.png" alt="failed" />
            <h1 >Transaction Failed</h1>
            <p>Failed To Genereate Credit Limit</p>
            <p><b>Redirecting ...</b></p>
          </div>
      }
    </div>
  )
}

const mapStateToProps = ({cleverpeUser})=>{
return {
  user:cleverpeUser
}
}

export default connect(mapStateToProps)(TransactionStatus)