const FAQS = [
  {
    question:
      "What are the benefits of applying for a loan through CleverPe Eco?",
    answer:
      "Using CleverPe Eco to apply for a loan brings multiple benefits. The process is entirely digital,removing the need for any paperwork and making the application simple and fast. You will receive loan offers customized to your specific needs, with quick processing and immediate disbursal. CleverPe Eco provides a smooth and convenient way to access sustainable financing.",
  },
  {
    question:
      "How much loan can I get for my electric 2-wheeler through CleverPe Eco?",
    answer:
      "When applying for an electric 2-wheeler loan through CleverPe Eco, the amount you can get depends on various factors though the maximum loan you can get is upto 2 lakhs, such as your personal profile and the price of the electric 2-wheeler. CleverPe offers full financing options based on the vehicle`s on-roadprice, ensuring all your financing needs are covered. The final loan amount will be tailored to your financial profile and the cost of the e-bike you choose.",
  },
  {
    question:
      "What documents are required for applying for a loan through CleverPe Eco?",
    answer:
      "The loan application process through CleverPe Eco is hassle-free and does not require any physical documents. Simply upload your bank statement for income verification, that is it.",
  },
  {
    question:
      "What is the turnaround time for loan approval through CleverPe Eco?",
    answer:
      "CleverPe Eco aims to provide super fast loan approvals in just 30 seconds. We strive to process and approve your loan as quickly as possible, minimizing the waiting time.",
  },
  {
    question:
      "How can I check my eligibility for applying for a loan through CleverPe Eco?",
    answer:
      "You can check the pre approved loan limit just by entering your name, mobile number, PAN and monthly income , present on our website.",
  },
  {
    question:
      "What are the tenure and EMI options for the loan through CleverPe Eco?",
    answer:
      "CleverPe Eco offers flexible repayment options to fit your financial situation. You can take a loan of up to Rs 2 Lakhs with a repayment tenure extending up to 36 months. Our aim is to make the EMI payments as convenient and adaptable as possible for you.",
  },
];

export default FAQS;
