import React from 'react'
import "./ProductInfo.scss"
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SubHeader from '../Header/SubHeader'
import "./ProductInfo.scss"
import { Button } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Description from './Description'
import Specification from './Specification'
import axios from 'axios'
import Ratings from './Ratings'
import {connect} from 'react-redux'
import Rating from '@mui/material/Rating';
import _ from 'lodash'
import {formatMoney,percentage} from '../Utils/Functions'
import ProductPage from '../Utils/LoadingPages/ProductPage'
import ItemVar from '../Item/ItemVar'
import ItemVarLoading from '../Utils/LoadingPages/ItemVarLoading'
import {addItems} from '../redux/checkout/checkoutActions'
import { storeEmi, storeMerchant } from '../redux/user/userActions'
import SelectMerchant from './SelectMerchant'
//?transactionId=null&transactionStatus=0&transactionTime=1652095486699&leafRefNo=23423a23
function ProductInfo(props) {
  let variationId=props.location.search.split("?")[1]
  const [open,setOpen]=React.useState(false)
  const [value, setValue] = React.useState('1');
  const [variation,setVariation]=React.useState({})
  const [product,setProduct] = React.useState({})
  const [image,setImage]=React.useState("")
  const [reviews,setReviews]=React.useState([])
  const [smartPhones,setSmartPhones]=React.useState([])
  const [itemVarLoading,setItemVarLoading]=React.useState(false)
  const data = [1,2,3,4,5]

  console.log("product",product)
  console.log('variatoin',variation)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   const renderName = (prod,options)=>{
    let name = prod.name
    _.map(options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}

  const getProducts = async()=>{
      const res = await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/byCategory`,{categoryId:"6255247f0d4b9a0004f28c97"})
      setSmartPhones(res.data.result[0].products.slice(0,4))
      setItemVarLoading(false)
  }

  React.useEffect(() => {
    setItemVarLoading(true)
    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/variation/single`,{variationId},{headers:{token:props.user.user}})
    .then(res=>{
      if(res.data.result.length>0){
        setVariation(res.data.result[0])
        if(res.data.result[0].available.length>0){
          props.storeMerchant(res.data.result[0].available[0])
        }else{
          props.storeMerchant({})
        }
        if(res.data.result[0].images.length>0){
          setImage(res.data.result[0].images[0])
        }

        setProduct(res.data.result[0].product[0])
      }
      axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/review/product`,{productId:res.data.result[0].product[0]._id},{headers:{token:props.user.user}})
      .then(resp=>{
        setReviews(resp.data.result)
      })
      .catch(err=>{
      })
    })
    .catch(err=>{
    })

    getProducts()
  },[])



  const handlePayment=(tenure,amount)=>{
    if(props.user.user){
      props.addItems([{_id:"",variations:{item:variation,quantity:1}}])
      props.history.push("/checkout",tenure)
    }else{
      props.history.push("/signin",{route:`/productinfo${props.location.search}`})
    }

  }

  return (
    <div>
    <SelectMerchant open={open} setOpen={setOpen} options={_.isEmpty(variation)?[]:variation.available} />
    <Header />    
    <SubHeader />
    {_.isEmpty(product)?
    
    <div className="desktop-container">
      <ProductPage />
    </div>
      
      :

    <section className="product-info-container desktop-container">
        <div className="row m-auto image-product-div">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              {image.length>0?<img className="product-image" src={image} alt={image} />:
              <img className="product-image" src="/cleverpeproduct.png" alt="opporeno" />}

              <div className="product-images-container row m-auto">
                {
                  variation.images.length>0?(
                    variation.images.map((item,index)=>(
                      <div onClick={()=>setImage(item)}  key={index} className="product-images-head">
                      <img  src={item} alt={item} />
                      </div>
                    ))
                  ):null
                }

              </div>

            </div>

            

            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-8">
                <h2 className="productname">{renderName(product,variation.options)}</h2>
                <div className="rating-container mb-4">
                  <Rating name="read-only" sx={{fontSize:"2.5em"}} value={product.rating.avg} readOnly />
                  <p className="ratings">{product.rating.total} Ratings and {reviews.length} Reviews</p>
                </div>

              <div className='row m-auto align-items-center emioff-container'>
                  <div>
                    <img src="/cleverpeblackhalf.png" alt="cleverpeblackhalf" className='emi-image'/>
                  </div>
                  <div>
                    <p className="emioff"> No Cost EMI - 3 M & 6 M</p>
                  </div>
              </div>

              <div className='row m-auto align-items-center emioff-container'>
                  <div>
                  <img src="/cleverpeblackhalf.png" alt="cleverpeblackhalf" className='emi-image'/>
                  </div>
                  <div>
                    <p className="emioff">  Zero Downpayment</p>
                  </div>
              </div>

              <div className='row m-auto align-items-center emioff-container'>
                  <div>
                  <img src="/cleverpeblackhalf.png" alt="cleverpeblackhalf" className='emi-image'/>
                  </div>
                  <div>
                    <p className="emioff">  Supersonic Delivery Available</p>
                  </div>
              </div>

              {
                !_.isEmpty(props.user.merchant)&&
                <section className="soldby">
                <span className="merchantpara">Sold By <b>{props.user.merchant.merchantId}</b> <span className="change" onClick={()=>setOpen(true)}>Change</span></span>
                </section>
              }

              <h1 className="price">{formatMoney(variation.options.price)}</h1>
              <div className="row m-auto align-items-center">
                <div>
                <h4 className="regular-price">{formatMoney(variation.options.mrp)}</h4>
                </div>
                <div>
                  <h5 className='percentage'>{percentage(variation.options.mrp,variation.options.price)}% off</h5>
                </div>
              </div>

              {!_.isEmpty(variation)&&(variation.available.length>0?<div className="row m-auto bnpl ">
                <div className="shadow bnpl-cont col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                    <p><span>{formatMoney(Math.ceil(variation.options.price/3))}</span> x 3 Months</p>
                    <p className="downpayment">+ ₹0 Downpayment</p>
                    <Button color="secondary" onClick={()=>{handlePayment(1,variation.options.price)}} fullWidth variant="contained">Buy on 3 months emi</Button>
                </div>

                {parseInt(variation.options.price)>5999&<>
                <div className="shadow bnpl-cont col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                    <p><span>{formatMoney(Math.ceil(variation.options.price/6))}</span> x 6 Months</p>
                    <p className="downpayment">+ ₹0 Downpayment</p>
                    <Button color="secondary" onClick={()=>{handlePayment(2,variation.options.price)}} fullWidth variant="contained">Buy on 6 months emi</Button>
                </div>

                <div className="shadow bnpl-cont col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                    <p><span>{formatMoney(Math.ceil(variation.options.price/8))}</span> x 6 Months</p>
                    <p className="downpayment">+ {formatMoney(Math.ceil((variation.options.price/8)*2))} Downpayment</p>
                    <Button color="secondary" onClick={()=>{handlePayment(3,variation.options.price)}} fullWidth variant="contained">Buy on 6 months emi</Button>
                </div>

                <div className="shadow bnpl-cont col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                    <p><span>{formatMoney(Math.ceil(variation.options.price/9))}</span> x 6 Months</p>
                    <p className="downpayment">+ {formatMoney(Math.ceil((variation.options.price/9)*3))} Downpayment</p>
                    <Button color="secondary" onClick={()=>{handlePayment(4,variation.options.price)}} fullWidth variant="contained">Buy on 6 months emi</Button>
                </div>
                </>}
              </div>:
              <div>
                <h1 className="outofstock">Out of Stock</h1>
              </div>
              )}


            </div>
        </div>


        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Description" value="1" />
            <Tab label="Specification" value="2" />
            <Tab label="Ratings & Reviews" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><Description desc={product.description} /></TabPanel>
        <TabPanel value="2"><Specification spec={product.specification} /></TabPanel>
        <TabPanel value="3"><Ratings ratings={product.rating} reviews={reviews} /></TabPanel>
      </TabContext>

      <section className="my-5 smartphones-section">
            <div className="row ml-3 mb-4 align-items-center">
                <div>
                    <img src="/smartphonelogo.png" alt="cat" />
                </div>
                <div className="ml-3">
                <h1>Similar Products</h1>
                </div>

                </div>
                <div className="row m-auto justify-content-between product-row">
                {
                    !itemVarLoading?(
                        smartPhones.map((item,index)=>(
                        <div key={index} className="my-4 col-11 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <ItemVar item={item} />
                        </div>
                        ))
                    ):
                    data.map((item,index)=>(
                        <div key={index} className="my-4 col-11 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <ItemVarLoading />
                        </div>
                        ))
                }
                </div>
           
            </section>
    </section>
    }
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser})=>{
  return {
    user:cleverpeUser
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      addItems:(item)=>dispatch(addItems(item)),
      storeMerchant:(merchant)=>dispatch(storeMerchant(merchant)),
      storeEmi:(emi)=>dispatch(storeEmi(emi))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProductInfo)