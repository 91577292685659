const initialStateCart = []

const cartReducer = (state = initialStateCart,action)=>{
    switch(action.type){

        case "SET_CART":
            return action.payload

        case 'ADD_ITEM':
            let itemArr = state.filter(item=>item.variations._id === action.payload.variations._id)
            let count = 1;
            action.payload.count = count;
            if(itemArr.length===0){
                return [...state,action.payload]
            }else{
                return state;
            }
        case 'REMOVE_ITEM':
            return state.filter(item=>item.variations._id!==action.payload.variations._id) 
        
        case "INCREASE_ITEM_COUNT":
            return state.map(item=>{
                if(item.variations.item._id===action.payload){
                    item.variations.quantity = item.variations.quantity+1;
                }
                return item;
            })
        case "DECREASE_ITEM_COUNT":
            return state.map(item=>{
                if(item.variations.item._id===action.payload){
                    item.variations.quantity = item.variations.quantity+1;
                }
                return item;
            })    
        case "EMPTY_CART":
            return []
        default:
            return state;
    }
}

export default cartReducer;