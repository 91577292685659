import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SubHeader from '../Header/SubHeader'
import PersonIcon from '@mui/icons-material/Person';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InventoryIcon from '@mui/icons-material/Inventory';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import "./MerchantDashhead.scss"
import {Link} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import {compose} from "redux";
import {connect} from 'react-redux'
import { deleteUser } from '../redux/user/userActions';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DescriptionIcon from '@mui/icons-material/Description';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { styled } from '@mui/material/styles';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import axios from 'axios'
import {getUserInfo,storeUserInfo} from '../redux/user/userActions'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
const Input = styled('input')({
    display: 'none',
  });
function MerchantDashhead(props) {
    const [selectedImage,setSelectedImage]=React.useState([])
    const [eV,setEv]=React.useState(false);

    console.log("properties",props?.user?.userInfo?.group[0]?.groupId);

    React.useEffect(()=>{
        props.getUserInfo(props.user.user)
    },[])

    React.useEffect(() => {
      if (props?.user?.userInfo?.group[0]?.groupId === "CLEVERPE_EV") {
        setEv(true);
      }
    }, [props.user.userInfo]);
    

    return (
      <div className={props.open?"mobile-menu shadow dashboard-menu":"hidden mobile-menu shadow dashboard-menu"}>
          <div  className="mobile-visible" >
                    <IconButton onClick={()=>props.setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                    </div>
                  {
                  props.user.userInfo&&(
                  props.user.userInfo.img?<img src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${props.user.userInfo.img}`} className="avatar" alt="man" />
                  :
                  <img src="/man.png" className="avatar" alt="man" />
                  )
                }
                  <div style={{textAlign:"center"}}>
                  <label htmlFor="contained-button-file">
                    <Input onChange={(event)=>{
                    const formdata = new FormData();
                    formdata.append('image',event.target.files[0])
                    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/user/upload`,formdata,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                    .then(res=>{
                        props.getUserInfo(props.user.user)
                    })
                    .catch(err=>{
                    })
                    }} accept="image/*" id="contained-button-file" multiple type="file" />
                  <Button component="span" className="mt-2">
                    Edit Avatar
                  </Button>
                </label>
                  </div>
                  <h2><span className="subheading">Howdy! ,</span> {props.user.userInfo&&props.user.userInfo.name}</h2>
                  <Link to="/merchantprofile" className={props.id===1?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <PersonIcon />
                      </div>
                      <div>
                      <h4>My Profile</h4>
                      </div>
                  </Link>


                  
        <Link to="/eligibility" className={props.id === 8 ? "link-menu-container row m-auto align-items-center active" : "link-menu-container row m-auto align-items-center"}>
          <div>
            <PersonSearchIcon />
          </div>
          <div>
            <h4>Check Pre-Approval (New)</h4>
          </div>
        </Link>
      
          

                  <Link to="/GenerateQRnew" className={props.id===5?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <QrCode2Icon />
                      </div>
                      <div>
                      <h4>Generate QR</h4>
                      </div>
                  </Link>

                  <Link to="/merchantorders" className={props.id===3?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <DescriptionIcon />
                      </div>
                      <div>
                      <h4>All Orders</h4>
                      </div>
                  </Link>

                  <Link to="/completedorders" className={props.id===6?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <InventoryIcon />
                      </div>
                      <div>
                      <h4>Completed Orders</h4>
                      </div>
                  </Link>

                  <Link to="/settlements" className={props.id===7?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <CurrencyRupeeIcon />
                      </div>
                      <div>
                      <h4>Settlements</h4>
                      </div>
                  </Link>
{/* 
                  <Link to="/merchantinventory" className={props.id===2?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <Inventory2OutlinedIcon />
                      </div>
                      <div>
                      <h4>Inventory</h4>
                      </div>
                  </Link> */}


  



  
{/*   
                  <Link to="/merchantpayments" className={props.id===4?"link-menu-container row m-auto align-items-center active":"link-menu-container row m-auto align-items-center"}>
                      <div>
                      <CreditCardIcon />
                      </div>
                      <div>
                      <h4>Customer Payments</h4>
                      </div>
                  </Link> */}


                  <div className="my-3" style={{textAlign:"center"}}>
                  <Button onClick={()=>{
                      props.deleteUser()
                      props.history.push("/")
                      }} startIcon={<LogoutIcon />}>Logout</Button>
                  </div>
  
             
      </div>
    )
  }
  
const mapStateToProps = ({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

  const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        deleteUser:()=>dispatch(deleteUser())
    }
}

  export default compose(
	withRouter,
	connect(mapStateToProps,mapDispatchToProps)
  )(MerchantDashhead);