import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function ProductPage() {
  return (
    <div>
        <div className="row my-5 mx-auto">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <Skeleton variant="rectangular" height={400} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <Skeleton variant="text" />        
            <Skeleton className="mt-3" variant="text" width={250} />        
            <Skeleton className="mt-3" variant="text" width={250} />        
            <Skeleton className="mt-3" variant="text" width={250} />      
            <div className="mt-3">
            <Skeleton className="mt-3" variant="text" width={100} />      
            <Skeleton className="mt-3" variant="text" width={80} />      
            </div>

            <div className="row my-5 mx-auto justify-content-between">
                <div>
                <Skeleton variant="rectangular" height={100} width={200} />
                </div>
                <div>
                <Skeleton variant="rectangular" height={100} width={200} />
                </div>
                <div>
                <Skeleton variant="rectangular" height={100} width={200} />
                </div>
            </div>

            </div>
        </div>

        <div className="my-3" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Skeleton className="mt-3" variant="text" width={850} />        
        </div>

        <div className="my-3" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Skeleton className="mt-3" variant="text" width={850} />        
        </div>

        <div className="my-3" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Skeleton className="mt-3" variant="text" width={850} />        
        </div>



    </div>
  )
}

export default ProductPage