import React from 'react'
//transactionId=null&transactionStatus=0&transactionTime=1653887336811&leafRefNo=undefined
import "./TransactionStatus.scss"
import axios from 'axios'
import {connect} from 'react-redux'





function TransactionStatusOffline(props) {
  var url_string = window.location.href
  var url = new URL(url_string);
  let urlArray = url.search.split("?")
  let newStr = `?${urlArray[1]}&${urlArray[2]}`
  url.search = newStr
  var transactionId = url.searchParams.get("transactionId");
  var transactionStatus = url.searchParams.get("transactionStatus");
  var transactionTime = url.searchParams.get("transactionTime");
  var leafRefNo = url.searchParams.get("leafRefNo");
  var txnStatus = url.searchParams.get("txnStatus");
  const [success,setSuccess]=React.useState(false)
  const [status, setStatus] = React.useState(null);

var orderId = props.user.orderId;
console.log(orderId,transactionId)


  const renderGoldPrice = (price)=>{
    if(price<=4999){
      return 50;
    }else if(price>=5000 && price<=9999){
      return 100;
    }else if(price>=10000 && price<=14999){
      return 250;
    }else{
      return 500
    }
  }

  // React.useEffect(()=>{
  //   if(transactionId.toString()!=="null"){
  //     setSuccess(true)
  //     axios.get(`${process.env.REACT_APP_CASHELIVE}/cashe/loanBooking/transactionStatus/${transactionId}`,{headers:{Authorization:`${process.env.REACT_APP_CASHELIVEKEY}`}})
  //     .then(res=>{
  //       axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-1`,{orderId,transaction:res.data,status:"Completed"})
  //       .then(res=>{
  //         if(res.status===200){
  //         props.history.push("/")
  //         }
          
  //       })
  //       .catch(err=>{
  //       })
  //     })
  //     .catch(err=>{

  //     })
  //   }else{
  //     //console.log({orderId,transaction:{transactionId:""},status:"Not Paid"})
  //       axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-1`,{orderId,transaction:{transactionId:""},status:"Not Paid"})
  //       .then(res=>{
  //         if(res.status===200){
  //         props.history.push("/")
  //         }
          
  //       })
  //       .catch(err=>{
  //       })

  //   }
    
  // },[])
  React.useEffect(()=>{
    if(transactionId.toString()!=="null"){
      setSuccess(true)
      setStatus("Completed")

        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-1`,{orderId,transaction:transactionId,status:"Completed"})
        .then(res=>{
          console.log(res.data, "___________________________")

          if(res.status===200){
            let resData = res.data.result.data;
            let cpi = true
            if(cpi && res.data.result.data.appliedOffer.applied){
              props.history.push("/uploadpicture",{orderId,appliedOffer:res.data.result.data.appliedOffer}) //upload picture to cashback
            }else if(cpi){
              props.history.push("/uploadpicture",{orderId})
            }else{
              if(res.data.result.data.appliedOffer.applied){
                //get offer
                props.history.push("/feedback",orderId)
                //props.history.push("/cashback",res.data.result.data.appliedOffer)
              }else{
                //not got offer
                props.history.push("/feedback",orderId)
                //props.history.push("/cashback")
              }  
            }
          // if(res.data.result.data.cusProdImg){
          //   if(res.data.result.data.appliedOffer){
          //     //benew cashback redirect with false or true
          //     console.log(res.data.result.data.appliedOffer)
          //     if(res.data.result.data.appliedOffer.applied){
          //       //get offer
          //       props.history.push("/cashback",res.data.result.data.appliedOffer)
          //     }else{
          //       //not got offer
          //       props.history.push("/cashback")
          //     }
          //   }else{
          //     props.history.push("/uploadpicture",orderId)
          //   }
          // }else if(res.data.result.data.appliedOffer){
          //   //benew cashback redirect with false or true
          //   console.log(res.data.result.data.appliedOffer)
          //   if(res.data.result.data.appliedOffer.applied){
          //     //get offer
          //     props.history.push("/cashback",res.data.result.data.appliedOffer)
          //   }else{
          //     //not got offer
          //     props.history.push("/cashback")
          //   }
          // }else{
          //   props.history.push("/feedback",orderId)
          // }
          
          }
          
        })
        .catch(err=>{
        })

    }else{
      //console.log({orderId,transaction:{transactionId:""},status:"Not Paid"})
      setSuccess(false)
      setStatus("Payment Failed")
      setTimeout(() => {
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-1`,{orderId,transaction:{transactionId:""},status:"Payment Failed"})
        .then(res=>{
          if(res.status===200){
          props.history.push("/")
          }
          
        })
        .catch(err=>{
        })
      }, 3000);
        

    }
    
  },[])

  React.useEffect(() => {
    if (txnStatus === 'Approved') {
      setStatus('Approved');
      setSuccess(false)
      // Add any specific logic for "Approved" status here
      setTimeout(() => {
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/completePayment-1`,{orderId,transaction:{transactionId:""},status:"Approved"})
        .then(res=>{
          if(res.status===200){
          props.history.push("/")
          }
          
        })
        .catch(err=>{
        })
      }, 3000);
    }
  }, [txnStatus]);
  
//comment
return (
  <div>
    {status === 'Completed' ? (
      <div className="transaction-status-container">
        <p>
          <b>Redirecting to</b>
        </p>
        <h1>Product Photo Verification</h1>
        <center className="my-5">
          <div className="loader"></div>
        </center>
      </div>
    ) : status === 'Payment Failed' ? (
      <div className="transaction-status-container">
        <img className="img" src="/failed.png" alt="failed" />
        <h1>Transaction Failed</h1>
        <p>Don't Worry we'll help you out, Please contact support@cleverpe.com</p>
        <p>
          <b>Redirecting ...</b>
        </p>
      </div>
    ) : status === 'Approved' ? (
      <div className="transaction-status-container">
        <h1>Transaction Approved</h1>
        <p>Your transaction has been approved.</p>
        <p>
          <b>Redirecting ...</b>
        </p>
      </div>
    ) : null}
  </div>
);

}

const mapStateToProps = ({cleverpeUser})=>{
return {
  user:cleverpeUser
}
}

export default connect(mapStateToProps)(TransactionStatusOffline)