import React from 'react'
import "./SubHeader.scss"
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import {Button} from '@mui/material'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TvIcon from '@mui/icons-material/Tv';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ToysIcon from '@mui/icons-material/Toys';
function SubHeader(props) {
    
  return (
    <div className={`row ${props.color?"subheadercolor":"subheader"} justify-content-between desktop-container m-auto`}>
        <Button startIcon={<SmartphoneIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"6255247f0d4b9a0004f28c97",name:"Mobiles"})}
        >
            Mobiles
        </Button>
        <Button startIcon={<TvIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"62571e99703b0600041b1db7",name:"Electronics"})}
        >
            Electronics
        </Button>
        <Button startIcon={<ManIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"62b2b8b596096700041c13d9",name:"Men"})}
        >
            Men's Fashion
        </Button>
        <Button startIcon={<WomanIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"62571ecb703b0600041b1dc1",name:"Women"})}
        >
            Women's Fashion
        </Button>
        <Button startIcon={<CheckroomIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"627d35d1603e7a0004fe723a",name:"Accessories"})}
        >
            Accessories
        </Button>
        <Button startIcon={<ToysIcon />} variant="secondary" 
        // onClick={()=>props.history.push("/categories",{categoryId:"62b2b90f96096700041c13df",name:"Kids"})}
        >
            Kids Fashion
        </Button>

      

        
       
    </div>
  )
}

export default withRouter(SubHeader)