import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {connect} from 'react-redux'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Rating from '@mui/material/Rating';
const Input = styled('input')({
  display: 'none',
});
//api/ticket/all
function ReviewModal(props) {
    const {register,handleSubmit,formState:{errors}}=useForm()
    const [selectedImage,setSelectedImage]=React.useState([])
    const [value, setValue] = React.useState(5);
    // React.useEffect(()=>{
    //     axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/ticket/all`,{headers:{token:props.user.user}})
    //     .then(res=>{
    //     })
    //     .catch(err=>{
    //     })
    // },[])
    const onSubmit = ({description})=>{

        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/ticket/create`,{entityId:props.orderId,entitySchema:"Order",description},{headers:{token:props.user.user}})
        .then(res=>{
            props.setOpen(false)
        })
        .catch(err=>{
            props.setOpen(false)
        })
      
    }

  return (
    <div>
      <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
        <DialogTitle>Rate Product</DialogTitle>
        <form onSubmit = {handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Note : Ratings and Reviews helps other customer buy a good and relaible product
          </DialogContentText>


                    <TextField
          inputProps={{ maxLength: 200 }}
          
          {...register('description',{required:true})}
          error={errors.description?true:false}
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            fullWidth
            variant="standard"
          />





        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

export default connect(mapStateToProps)(ReviewModal)