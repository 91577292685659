import React from 'react'
import "../Home"
import { Link } from 'react-router-dom'
import { IoClose, IoMenu } from 'react-icons/io5'
function EcoHeader(props) {
    const [navOpen, setNavOpen] = React.useState(false)
    return (
        <div style={{marginTop:'1rem'}}>
            <div className="EcoNavbar">
                <div className='left-section'>
                    <Link to="/"><span className={`mx-3 eco-home-link ${props.id === 1 && 'eco-home-link-active'}`}>Home</span></Link>
                    <Link to="/aboutus"><span className={`mx-3 eco-home-link ${props.id === 2 && 'eco-home-link-active'}`}>About</span></Link>
                    <Link to="/cleverpeeco"><span className={`mx-3 eco-home-link ${props.id === 3 && 'eco-home-link-active'}`}>CleverPe Eco</span></Link>
                </div>
                <div className='right-section'>
                    <img className="logo" style={{height:40}} src="/cp_green.png" alt="logo" />
                </div>
            </div>
            <div className='eco-mob-logo'>
                <img src="/cp_green.png" alt="logo" />
            </div>
            <div className='eco-menu-icon' onClick={()=>setNavOpen(!navOpen)}>
      { !navOpen ? <IoMenu fontSize={"7vw"}/> : <IoClose fontSize={"7vw"}/> }
      </div>
            { navOpen &&
   <div className={`${navOpen} ? eco-mob-nav : eco-mob-nav-display`}>
        <Link to="/"><span className={`mx-3 eco-mob-link ${props.id===1&&'eco-mob-link-active'}`}>Home</span></Link>
        <Link to="/aboutus"><span className={`mx-3 eco-mob-link ${props.id===2&&'eco-mob-link-active'}`}>About</span></Link>
        <Link to="/cleverpeeco"><span className={`mx-3 eco-mob-link ${props.id===3&&'eco-mob-link-active'}`}>CleverPe Eco</span></Link>
   </div>
    }
        </div>
    )
}

export default EcoHeader