import React from 'react'
import "./Auth.scss"
import { TextField,Button,Alert } from '@mui/material'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {connect} from 'react-redux'
import {setLoading} from '../redux/loading/loadingActions'
import {Link} from 'react-router-dom'
function Refund(props) {
  const {handleSubmit,register,formState:{errors}}=useForm()
  const [error,setError]=React.useState("")
  const [message,setMessage]=React.useState("")
  React.useEffect(()=>{
    if(!props.user.user){
        props.history.push("/signin",{route:`/refund`})
    }
  },[])
  const onSubmit = (data)=>{
    props.setLoading(true)
      setError("")
      axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/refund/create`,{description:data.description},{headers:{token:props.user.user}})
      .then(res=>{
        props.setLoading(false)
        if(res.data.msg==="Error"){
          setError(res.data.result)
        }else{
         setMessage("Successfully Submitted") 
        }
        
      })
      .catch(err=>{
        setError("Check your internet connection")
        props.setLoading(false)
      })

  }

  return (
    <div className='body'>
    <div className="shadow-sm auth-container">
        <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
        <h3>Refund</h3>
        <h6>After reviewing your request we'll proceed with your refund so be descriptive</h6>
        <form onSubmit={handleSubmit(onSubmit)}>
        <TextField error={errors.mobile?true:false} {...register("description",{required:true,valueAsNumber:true})} className="textfield" fullWidth 
        id="outlined-multiline-static"
        multiline
        rows={4}
        label="Refund Description" />


        {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
        {message.length>0&&<Alert className="alert" severity="success">{message}</Alert>}
        <Button type="submit" className="mt-4" fullWidth variant="contained">Submit</Button>
        <p className="grey subtitle my-3">By continuing, you agree to CleverPe <Link to="/termsandconditions">Terms and Conditions</Link> of Use and <Link to="/privacypolicy">Privacy Policy</Link>.</p>

        <hr />
        {/* <div className="mt-3">
          <p className="newtext">New to CleverPe? Dive in by creating an account</p>
          <Button fullWidth variant="outlined" onClick={()=>props.history.push("/signup",props.location.state)}>create an account</Button>
        </div> */}
        </form>

    <p className='grey subtitle mt-3'>
    ©2022 Cleverpe Technologies Private Limited
    </p>
    </div>

    </div>
  )
}
const mapStateToProps = ({loading,cleverpeUser})=>{
  return {
      loading,
      user:cleverpeUser
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
      setLoading:value=>dispatch(setLoading(value))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Refund)