import userReducer from './user/userReducer'
//import socketReducer from './socket/socketReducer'
import loadingReducer from './loading/loadingReducer'
import wishListReducer from './wishlist/wishlistReducer'
import checkoutReducer from './checkout/checkoutReducer'
import cartReducer from './cart/cartReducer'
import {combineReducers} from 'redux'
import { persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
// const socketPersistConfig = {
//     key: 'socket',
//     storage: storage,
//     blacklist: ['state']
//   }

// const loadingPersistConfig = {
//     key:"loading",
//     storage:storageSession,
//     //blacklist:['loading']
// }

export default combineReducers({
    cleverpeUser:userReducer,
    wishList:wishListReducer,
    cart:cartReducer,
    checkout:checkoutReducer,
    //socket:persistReducer(socketPersistConfig,socketReducer),
    //loading:persistReducer(loadingPersistConfig,loadingReducer)
    loading:loadingReducer
})