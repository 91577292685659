import React, { useState, useRef } from "react";

import EcoHeader from "../components/Header/EcoHeader";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import FAQS from "../libs/constants/FAQS";
import { GoDotFill } from "react-icons/go";
import { IconContext } from "react-icons";  

import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

import "./PageSection.scss";

function CleverPeEco() {
  const [isOpen, setIsOpen] = useState(0);

  const toggleDropdown = (idx) => {
    if (isOpen !== idx) {
      setIsOpen(idx);
    } else {
      setIsOpen(7);
    }
  };

  const myRef = useRef();

  function scrollToComponent() {
    myRef.current.scrollIntoView({ behavior: "smooth" });
    myRef.current.focus();
  }

  return (
    <div
      className="eco-home"
    >
      <EcoHeader id={3} />
      <section className="desktop-container-ecopage">
        <div className="hero-sliding-container">
          <div className="cleverpeEco">
            <div className="img-cover">
              <div className="hero">
                <div className="hero-title">
                  <div className="main-title">
                    The Smartest way of EV Financing.
                  </div>
                </div>
                <h3>Helping INDIA Embrace the Green Revolution.</h3>
                <button className="white-button" onClick={scrollToComponent}>
                  <span>know more!</span>
                </button>
              </div>


                <img
                  src="/MAIN SCOOTER.png"
                  alt="Easy Approvals"
                />
             

              <div className="demo-mf">
                <h2
                  id="slide-text"
                >
                  {/* LOAN ELIGIBILITY IN 30 SECONDS. */}
                   Loan Eligibilty In 30 Seconds.
                </h2>
              </div>
            </div>

            {/* <div className="slidingText">
              <h3 id="slide-text">
                Helping INDIA Embrace the Electric revolution.
              </h3>
            </div> */}
          </div>
        </div>

        <span className="eco-heading">
            What is <span className="eco-green-text">CleverPe Eco</span> ?
          </span>

        <div className="eco-intro-container" ref={myRef}>
          <div
            className="eco-text-image-container"
          >
            <p
            className="paragraph"
              // style={{
              //   display: "inline-block",
              //   lineHeight: "1.5",
              //   alignItems: "flex-start",
              //   marginTop:"3vh",
              //   fontWeight: "450",
              //   width: "33vw",
              //   textAlign: "justify",
              // }}
            >
              Discover a new era of affordable{" "}
              <span className="exception">Electric Vehicle financing</span> with
              CleverPe, your go-to funding expert. Join the eco-friendly
              revolution and enjoy the convenience of budget-friendly financial
              solutions. Rely on us to support your path to sustainable living
              as we help create a greener tomorrow.
            </p>
            
            <div className="image-box">
            <img  src="/Pic2.png" alt="PIC" />
            <p className="ease-txt">Simple<span><IconContext.Provider value={{color:"#44C264",size:10}}><GoDotFill/></IconContext.Provider></span>Easy<span><IconContext.Provider value={{color:"#44C264",size:10}}><GoDotFill/></IconContext.Provider></span>Quick</p>
            </div>
          </div>
        </div>

        <div className="eco-chooseus" >
          <span className="eco-heading">Here's our Edge</span>
          <div className="eco-card-container">
            <div className="eco-card">
              <p>Loan Approval upto 2 Lakhs</p>
              <div className="underline"></div>
              <img src="LOAN APPROVAL.png" alt="loan approval" />
            </div>

            <div className="eco-card">
              <p>100% Paperless</p>
              <div className="underline"></div>
              <img src="/PAPERLESS.png" alt="Easy Approvals" />
            </div>

            <div className="eco-card">
              <p>No Hypothecation</p>
              <div className="underline"></div>
              <img src="NO HYPO1.png" alt="quick loan journey" />
            </div>

            <div className="eco-card">
              <p>Swift & Easy Process</p>
              <div className="underline"></div>
              <img src="/EASY PROCESS.png" alt="easy process" />
            </div>

            <div className="eco-card">
              <p>Anywhere in India</p>
              <div className="underline"></div>
              <img src="/NEW_INDIA_MAP.png" alt="Easy Approvals" />
            </div>
          </div>
        </div>

        <div className="eco-avail">
          <span className="eco-heading">How to Avail?</span>
          <div className="bg">
            <h2>Simple. Easy. Quick</h2>
            <div className="steps-container">
              <div className="step">
                <h2>1</h2>
                <h3 style={{ marginTop: 0 }}>Choose your Favorite EV</h3>
                <div className="underline"></div>
                <span>Visit the nearest EV dealer partnered with us.</span>
              </div>

              <div className="step">
                <h2>2</h2>
                <h3 style={{ marginTop: 0 }}>Register & buy using CleverPe</h3>
                <div className="underline"></div>
                <span>Complete the loan journey within few minutes</span>
              </div>

              <div className="step">
                <h2>3</h2>
                <h3 style={{ marginTop: 0 }}>Ride your EV home!</h3>
                <div className="underline"></div>
                <span>Enjoy your ride!</span>
              </div>
            </div>
          </div>
        </div>

        <div className="eco-feVehicle">
          <span className="eco-heading">Featured Vehicle</span>
          <div className="eco-vehicle-container">
            <div className="eco-vehicle">
              <img
                src="/VEGH LOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "2rem" }}>
                Vegh S60 -β
              </h3>
              <img
                src="/VEGH.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>

            <div className="eco-vehicle">
              <img
                src="/BGAUSS LOGO.png"
                style={{height:"12vh"}}
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "0.7rem" }}>
                Bgauss C12
              </h3>
              <img
                src="/BGAUSS.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>

            <div className="eco-vehicle">
              <img
              style={{height:"12vh"}}
                src="/GEMPAI LOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "0.7rem" }}>
                Gemopai Astrid Lite
              </h3>
              <img
                src="/GEMOPAI.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>

            <div className="eco-vehicle">
              <img
                src="/RUNRLOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "2rem" }}>
              Runr HS
              </h3>
              {/* <img
                src="/RUNR_.png"
                alt="RUNR"
                style={{ height: "2rem", marginTop: "2rem" }}
              /> */}
              {/* <h3 style={{ color: "white", fontSize: 20 }}>RUNR HS</h3> */}
              <img
                src="/RUNR.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>
          </div>
        </div>



        <div className="swipe" >
          <span className="eco-heading">Featured Vehicle</span>
          <div style={{marginLeft:"9.5vw",marginTop:"5vh"}}>
            <Swiper 
           spaceBetween={10}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}>

         <SwiperSlide>
            <div className="eco-vehicle">
              <img
                src="/VEGH LOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "2rem" }}>
                Vegh S60 -β
              </h3>
              <img
                src="/VEGH.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="eco-vehicle">
              <img
                src="/BGAUSS LOGO.png"
                style={{height:"12vh"}}
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "0.7rem" }}>
                Bgauss C12
              </h3>
              <img
                src="/BGAUSS.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>
            </SwiperSlide>


            <SwiperSlide>
            <div className="eco-vehicle">
              <img
              style={{height:"12vh"}}
                src="/GEMPAI LOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "0.7rem" }}>
                Gemopai Astrid Lite
              </h3>
              <img
                src="/GEMOPAI.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>
            </SwiperSlide>


            <SwiperSlide>
            <div className="eco-vehicle">
              <img
                src="/RUNRLOGO.png"
                alt="Easy Approvals"
                className="vehicle-logo"
              />
              <h3 style={{ color: "white", fontSize: 24, marginTop: "2rem" }}>
              Runr HS
              </h3>
              {/* <img
                src="/RUNR_.png"
                alt="RUNR"
                style={{ height: "2rem", marginTop: "2rem" }}
              /> */}
              {/* <h3 style={{ color: "white", fontSize: 20 }}>RUNR HS</h3> */}
              <img
                src="/RUNR.png"
                alt="Easy Approvals"
                className="vehicle-img"
              />
            </div>
            </SwiperSlide>

            </Swiper>
          </div>
        </div>

        
 
        


        

        <div className="eco-feProducts">
          <span className="eco-heading">Featured Retro-EV Brands</span>
          <span
            style={{ color: "gray", marginTop: "0.5rem" }}
            className="fe-prod-para"
          >
            Convert Existing petrol vehicles to Electric or hybrid
          </span>
          <div className="eco-product-container">
            <div className="eco-product">
              <span className="prod-title">Green Tiger </span>
              <div className="para-image-container">
                <img src="/GREEN TIGER.png" alt="green-tiger" />
                <span>
                  A converted scooter has both electric & petrol modes. You can
                  ride each of them independently.
                </span>
              </div>
            </div>

            <div className="eco-product">
              <span className="prod-title">Starya</span>
              <div className="para-image-container">
                <img src="/STARYA LOGO.png" alt="green-tiger" />
                <span>Convert Existing petrol vehicles to Electric.</span>
              </div>
            </div>
          </div>
        </div>

        <div className="eco-brands">
          <span className="eco-heading">Partnered Brands</span>
          <div className="brands-container">
            <div className="brand">
              <div
                className="main-div"
              >
                <img src="/SES_LOGO.png" />
                <span>
                  SES Electric
                </span>
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://www.seselectric.in/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div  className="main-div"
                
              >
                <img src="/BG GAUSS_ GREY LOGO.png" />
                <span
                 
                >
                  Bguass
                </span>
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://www.bgauss.com/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                className="main-div"
              >
                <img src="/GREEN TIGER LOGO.png" />
                <span
                 
                >
                  Green Tiger
                </span>
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://greentiger.in/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                 className="main-div"
              >
                <img src="/STARYA LOGO.png" />
                <span
                  
                >
                  Starya
                </span>
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://www.starya.in/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
               className="main-div"
              >
                <img src="/GEMPAI LOGO.png" />
                <span
                 
                >
                  Gemopai
                </span>
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://gemopai.com/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                className="main-div"
              >
                <img src="/RUNR BLK LOGO.png" />
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://runrmobility.com/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                 className="main-div"
              >
                <img src="/VEGH LOGO.png" />
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://thevegh.com/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                 className="main-div"
              >
                <img src="/e-sprinto.png" />
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://www.esprinto.in/">
                  <span>Website</span>
                </a>
              </div>
            </div>

            <div className="brand">
              <div
                className="main-div"
              >
                <img src="/warivo.png" style={{ height: "2.5rem" }} />
              </div>
              <div className="underline"></div>
              <div className="hyperlinks">
                <a href="https://warivomotor.com/">
                  <span>Website</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="eco-advantages">
          <span className="eco-heading">Advantages</span>
          <span
            className="advantages-text"
          >
            Experience the future of affordable electric 2-wheeler financing
            with CleverPe, your go-to funding expert. Join the eco-friendly
            revolution and enjoy the convenience of budget-friendly financial
            solutions. Rely on us to support your path to sustainable living as
            we help create a greener tomorrow.CleverPe provides a swift and
            seamless experience to get your favorite bike home in just 30
            minutes loan journey . With a 100% digital and paperless process,
            our loan journey is designed to be easy, simple, and quick. Enjoy
            the convenience of fast financing and ride towards a greener
            tomorrow with CleverPe.
          </span>
        </div>

        <div className="eco-criteria">
          <span className="eco-heading">Eligibility Criteria</span>
          <div className="criteria-container">
            <span>Citizen of India</span>
            <div className="underline" />
            <span>18+ Years of age</span>
            <div className="underline" />
            <span>salaried or self employed</span>
            <div className="underline" />
          </div>
        </div>

        <div className="dropdown-container">
          <h3 className="eco-heading">FAQS</h3>

          <div className={`dropdown ${isOpen === 0 ? "open" : ""}`}>
            <div className="upper">
              <span className="title">{FAQS[0].question}</span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(0)}
                >
                  <div className={`${isOpen === 0 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="dropdown-description"
                    style={{
                      display: "inline-block",
                      textAlign: "justify",
                      lineHeight: "1.5",
                    }}
                  >
                    {FAQS[0].answer}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={`dropdown ${isOpen === 1 ? "open" : ""}`}>
            <div className="upper" style={{ width: "100%" }}>
              <span className="title" style={{ width: "85%" }}>
                {FAQS[1].question}
              </span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(1)}
                >
                  <div className={`${isOpen === 1 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="dropdown-description"
                    style={{
                      display: "inline-block",
                      textAlign: "justify",
                      lineHeight: "1.5",
                    }}
                  >
                    {FAQS[1].answer}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={`dropdown ${isOpen === 2 ? "open" : ""}`}>
            <div className="upper">
              <span className="title">{FAQS[2].question}</span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(2)}
                >
                  <div className={`${isOpen === 2 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="dropdown-description"
                    style={{
                      textAlign: "justify",
                      display:"inline-block",
                      lineHeight: "1.5",
                    }}
                  >
                    {FAQS[2].answer}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={`dropdown ${isOpen === 3 ? "open" : ""}`}>
            <div className="upper">
              <span className="title">{FAQS[3].question}</span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(3)}
                >
                  <div className={`${isOpen === 3 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 3 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="dropdown-description"
                    style={{
                      display: "inline-block",
                      textAlign: "justify",
                      lineHeight: "1.5",
                    }}
                  >
                    {FAQS[3].answer}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={`dropdown ${isOpen === 4 ? "open" : ""}`}>
            <div className="upper">
              <span className="title">{FAQS[4].question}</span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(4)}
                >
                  <div className={`${isOpen === 4 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 4 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="div-4"
                    style={{
                      display: "inline-block",
                      textAlign: "justify",
                      lineHeight: "1.5",
                    
                    }}
                  >
                    {FAQS[4].answer}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className={`dropdown ${isOpen === 5 ? "open" : ""}`}>
            <div className="upper">
              <span className="title">{FAQS[5].question}</span>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className="arrow-container"
                  onClick={() => toggleDropdown(5)}
                >
                  <div className={`${isOpen === 5 ? "rotate" : ""}`}>
                    <img src="./arrow.png" alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
            {isOpen === 5 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  <span
                    className="dropdown-description"
                    style={{
                      display: "inline-block",
                      textAlign: "justify",
                      lineHeight: "1.5",
                    }}
                  >
                    {FAQS[5].answer}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="eco-footer">
          <div>
            <img
              src="/CleverPeEcoLogo.png"
              alt="cleverPeEco"
              style={{
                height: "11rem",
                marginRight: "2rem",
                marginLeft: "3rem",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
            }}
          >
            <div className="eco-footer-row">
              <div className="eco-footer-row-block" style={{ width: "19rem" }}>
                <span className="block-title">Registered office Address</span>
                <span className="block-description">
                  97, HOSUR, MAINMADIWALA, Bengaluru, Bengaluru Urban,
                  Karnataka,560034
                </span>
              </div>

              <div className="eco-footer-row-block">
                <span className="block-title">About Company</span>
                <a href="https://www.cleverpe.com/aboutus">
                  <span
                    className="block-description"
                    style={{ textDecoration: "underline" }}
                  >
                    About Us
                  </span>
                </a>

                <a href="https://www.cleverpe.com/contactus">
                  <span
                    className="block-description"
                    style={{ textDecoration: "underline" }}
                  >
                    Contact Us
                  </span>
                </a>
              </div>
            </div>

            <div className="eco-footer-row">
              <div className="eco-footer-row-block" style={{ width: "19rem" }}>
                <span className="block-title">
                  Corporate Identification Number (CIN)
                </span>
                <span className="block-description">U74999KA2022PTC160186</span>
              </div>

              <div className="eco-footer-row-block">
                <span className="block-title">Follow us</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <a
                    href="https://www.facebook.com/profile.php?id=100083343771059"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://x.com/CleverPe33947"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://youtube.com/@cleverpe-fx6kv?si=u6_ttv1hgajnUXcv"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <FaYoutube />
                  </a>
                  <a
                    href="https://www.instagram.com/cleverpe_india/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <FaInstagram />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-footer">
          <span className="block-description">
            <a href="mailto:support@cleverpe.com">
              <span className="block-description">support@cleverpe.com</span>
            </a>{" "}
            | Terms & Conditions | Privacy Policy
          </span>
          <span className="block-description">
            ©2022 Cleverpe Technologies Private Limited
          </span>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
}

export default CleverPeEco;