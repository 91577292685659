import _ from 'lodash'
export function formatIndianCurrency(amount) {
    // Convert the amount to a string
    const amountStr = amount.toString();

    // Split the amount into rupees and paise (if any)
    const [rupees, paise] = amountStr.split('.');

    // Add commas to the rupees part
    const formattedRupees = rupees.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the formatted rupees and paise (if any)
    const formattedAmount = paise ? `₹${formattedRupees}.${paise}` : `₹${formattedRupees}`;

    return formattedAmount;
}
export function formatMoney(n) {
    return "₹" + (Math.ceil(parseInt(n) * 100) / 100).toLocaleString();
}

export function percentage(mrp,price){
    return Math.ceil(((parseInt(mrp)-parseInt(price))/parseInt(mrp))*100)
}

export const renderName = (details)=>{
    let name = details.name
    _.map(details.variations.options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}
export const renderName2 = (product,options)=>{
    let name = product.name
    _.map(options,(value,key)=>{
        if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
            name = name + " " + `(${value})`
        }
    })
    return name
}
export const cartTotal = (cart)=>{
    let total = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price) * item.variations.quantity)
    })
    return formatMoney(total);
}

export const cartMrpTotal = (cart)=>{
    let total = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.mrp) * item.variations.quantity)
    })
    return formatMoney(total);
}

export const cartDiscountTotal = (cart)=>{
    let total = 0
    let mrptotal = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price)* item.variations.quantity)
    })
    cart.map(item=>{
        mrptotal = mrptotal + (parseInt(item.variations.item.options.mrp)* item.variations.quantity)
    })
    return formatMoney(mrptotal-total);
}

export const cartDiscountPercantage = (cart)=>{
    let total = 0
    let mrptotal = 0
    cart.map(item=>{
        total = total + (parseInt(item.variations.item.options.price) * item.variations.quantity)
    })
    cart.map(item=>{
        mrptotal = mrptotal + (parseInt(item.variations.item.options.mrp)* item.variations.quantity)
    })
    return percentage(mrptotal,total);
}


export const orderSubTotal = (items)=>{
    let subTotal = 0;
    items.map(item=>{
        subTotal =subTotal + item.quantity * parseInt(item.details.options.mrp)
    })
    return formatMoney(subTotal)
}

export const orderTotal = (items)=>{
    let total = 0;
    items.map(item=>{
        total =total + item.quantity * parseInt(item.details.options.price)
    })
    return formatMoney(total)
}

export const orderDiscount = (items)=>{
    let total = 0;
    let subTotal = 0
    items.map(item=>{
        total =total + item.quantity * parseInt(item.details.options.price)
        subTotal =subTotal + item.quantity * parseInt(item.details.options.mrp)
    })
    return formatMoney(subTotal - total)
}

export const renderPriceWithoutGst = (price,gst)=>{
    let gstamount = (price/100)*gst
    return formatMoney(price-gstamount)
}

export const renderTenure = (sr,price)=>{
    if(sr===1 || sr==="1" || sr==="3by0"){
        return {name:"3by0",months:3,downPayment:0,monthsEmi:Math.ceil(price/3),string:`3 Months No Cost + ₹0 Down Payment EMI by CleverPe`}
    }else if(sr===2 || sr==="2" || sr==="6by0"){
        return {name:"6by0",months:6,downPayment:0,monthsEmi:Math.ceil(price/6),string:`6 Months No Cost + ₹0 Down Payment EMI by CleverPe`}
    }else if(sr===3 || sr==="3" || sr==="8by2"){
        return {name:"8by2",months:6,downPayment:Math.ceil((price/8)*2),monthsEmi:Math.ceil(price/8),string:`6 Months No Cost + ₹${Math.ceil((price/8)*2)} Down Payment EMI by CleverPe`}
    }
    else if(sr===4 || sr==="4" || sr==="9by3"){
        return {name:"9by3",months:6,downPayment:Math.ceil((price/9)*3),monthsEmi:Math.ceil(price/9),string:`6 Months No Cost + ₹${Math.ceil((price/9)*3)} Down Payment EMI by CleverPe`}
    }
    else if(sr===5 || sr==="5" || sr==="9by0"){
        return {name:"9by0",months:9,downPayment:0,monthsEmi:Math.ceil(price/9),string:`9 Months No Cost + ₹0 Down Payment EMI by CleverPe`}
    }else if(sr===6 || sr==="6" || sr==="12by0"){
        return {name:"12by0",months:12,downPayment:0,monthsEmi:Math.ceil(price/12),string:`12 Months No Cost + ₹0 Down Payment EMI by CleverPe`}
    }
}

export const renderTenureInterest = (sr,price, ir, pf)=>{
    if(sr===1 || sr==="1" || sr==="3by0"){
        return {name:"3by0",months:3,downPayment:0,monthsEmi:Math.ceil(price/3),interest:Math.round((((price*16)/100)/12)*3),string:`3 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*16)/100)/12)*3)}) + ₹0 Down Payment`}
    }else if(sr===2 || sr==="2" || sr==="6by0"){
        return {name:"6by0",months:6,downPayment:0,monthsEmi:Math.ceil(price/6),interest:Math.round((((price*15)/100)/12)*6),string:`6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`}
    }else if(sr===3 || sr==="3" || sr==="8by2"){
        return {name:"8by2",months:6,downPayment:Math.ceil((price/8)*2),monthsEmi:Math.ceil(price/8),interest:Math.round((((price*15)/100)/12)*6),string:`6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/8)*2))*15)/100)/12)*6)}) + ₹${Math.ceil((price/8)*2)} Down Payment`}
    }
    else if(sr===4 || sr==="4" || sr==="9by3"){
        return {name:"9by3",months:6,downPayment:Math.ceil((price/9)*3),monthsEmi:Math.ceil(price/9),interest:Math.round((((price*15)/100)/12)*6),string:`6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/9)*3))*15)/100)/12)*6)}) + ₹${Math.ceil((price/9)*3)} Down Payment`}
    }else if(sr===5 || sr==="5" || sr==="9by0"){
        return {name:"9by0",months:9,downPayment:0,monthsEmi:Math.ceil(price/9),interest:Math.round((((price*14.66667)/100)/12)*9),string:`9 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`}
    }else if(sr===6 || sr==="6" || sr==="12by0"){
        return {name:"12by0",months:12,downPayment:0,monthsEmi:Math.ceil(price/12),interest:Math.round((((price*14.5)/100)/12)*12),string:`12 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`}
    } else if(sr===7 || sr==="7" || sr==="11by1"){
        let interest = Math.round((((price*ir)/100)/12)*11)
        return {name:"11by1",months:10,downPayment:Math.ceil((price/11)*1),monthsEmi:Math.ceil(price/11),interest:Math.round((((price*ir)/100)/12)*11),string:`10 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/11)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*11))/11)*1)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }else if(sr===8 || sr==="8" || sr==="18by3"){
        let interest = Math.round((((price*ir)/100)/12)*18)
        return {name:"18by3",months:15,downPayment:Math.ceil((price/18)*3),monthsEmi:Math.ceil(price/18),interest:Math.round((((price*ir)/100)/12)*18),string:`15 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/18)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*18))/18)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }else if(sr===9 || sr==="9" || sr==="20by4"){
        let interest = Math.round((((price*ir)/100)/12)*20)
        return {name:"20by4",months:16,downPayment:Math.ceil((price/20)*4),monthsEmi:Math.ceil(price/20),interest:Math.round((((price*ir)/100)/12)*20),string:`16 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/20)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*20))/20)*4)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }else if(sr===10 || sr==="10" || sr==="24by4"){
        let interest = Math.round((((price*ir)/100)/12)*24)
        return {name:"24by4",months:20,downPayment:Math.ceil((price/24)*4),monthsEmi:Math.ceil(price/24),interest:Math.round((((price*ir)/100)/12)*24),string:`20 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/24)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*24))/24)*4)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }

    else if(sr===11 || sr==="11" || sr==="15by3"){
        let interest = Math.round((((price*ir)/100)/12)*15)
        return {name:"15by3",months:12,downPayment:Math.ceil((price/15)*3),monthsEmi:Math.ceil(price/15),interest:Math.round((((price*ir)/100)/12)*15),string:`12 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/15)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*15))/15)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }else if(sr===12 || sr==="12" || sr==="10by2"){
        let interest = Math.round((((price*ir)/100)/12)*10)
        return {name:"10by2",months:8,downPayment:Math.ceil((price/10)*2),monthsEmi:Math.ceil(price/10),interest:Math.round((((price*ir)/100)/12)*10),string:`8 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/10)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*10))/10)*2)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }else if(sr===13 || sr==="13" || sr==="12by3"){
        let interest = Math.round((((price*ir)/100)/12)*12)
        return {name:"12by3",months:9,downPayment:Math.ceil((price/12)*3),monthsEmi:Math.ceil(price/12),interest:Math.round((((price*ir)/100)/12)*12),string:`9 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/12)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*12))/12)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }

    else if(sr===14 || sr==="14" || sr==="36by6"){
        let interest = Math.round((((price*ir)/100)/12)*36)
        return {name:"36by6",months:30,downPayment:Math.ceil((price/36)*6),monthsEmi:Math.ceil(price/36),interest:Math.round((((price*ir)/100)/12)*36),string:`30 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/36)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*36))/36)*6)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }

    else if(sr===15 || sr==="15" || sr==="36by3"){
        let interest = Math.round((((price*ir)/100)/12)*36)
        return {name:"36by3",months:33,downPayment:Math.ceil((price/36)*3),monthsEmi:Math.ceil(price/36),interest:Math.round((((price*ir)/100)/12)*36),string:`33 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/36)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*36))/36)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`}
    }
}

export const renderTenureString = (sr,price)=>{
    if(sr===1 || sr==="1" || sr==="3by0"){
        return `3 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }else if(sr===2 || sr==="2" || sr==="6by0"){
        return `6 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }else if(sr===3 || sr==="3" || sr==="8by2"){
        return `6 Months No Cost + ₹${Math.ceil((price/8)*2)} Down Payment EMI by CleverPe`
    }
    else if(sr===4 || sr==="4" || sr==="9by3"){
        return `6 Months No Cost + ₹${Math.ceil((price/9)*3)} Down Payment EMI by CleverPe`
    }
    else if(sr===5 || sr==="5" || sr==="9by0"){
        return `9 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }else if(sr===6 || sr==="6" || sr==="12by0"){
        return `12 Months No Cost + ₹0 Down Payment EMI by CleverPe`
    }
}

export const renderTenureStringInterest = (sr,price, ir, pf)=>{
    if(sr===1 || sr==="1" || sr==="3by0"){
        return `3 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*16)/100)/12)*3)}) + ₹0 Down Payment`
    }else if(sr===2 || sr==="2" || sr==="6by0"){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`
    }else if(sr===3 || sr==="3" || sr==="8by2"){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/8)*2))*15)/100)/12)*6)}) + ₹${Math.ceil((price/8)*2)} Down Payment`
    }
    else if(sr===4 || sr==="4" || sr==="9by3"){
        return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round(((((price-Math.ceil((price/9)*3))*15)/100)/12)*6)}) + ₹${Math.ceil((price/9)*3)} Down Payment`
    }else if(sr===5 || sr==="5" || sr==="9by0"){
        return `9 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*14.66667)/100)/12)*9)}) + ₹0 Down Payment`
    }else if(sr===6 || sr==="6" || sr==="12by0"){
        return `12 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*14.5)/100)/12)*12)}) + ₹0 Down Payment`
    }else if(sr===7 || sr==="7" || sr==="11by1"){
        let interest = Math.round((((price*ir)/100)/12)*11)
        return `10 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/11)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*11))/11)*1)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }else if(sr===8 || sr==="8" || sr==="18by3"){
        let interest = Math.round((((price*ir)/100)/12)*18)
        return `15 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/18)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*18))/18)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }else if(sr===9 || sr==="9" || sr==="20by4"){
        let interest = Math.round((((price*ir)/100)/12)*20)
        return `16 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/20)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*20))/20)*4)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }else if(sr===10 || sr==="10" || sr==="24by4"){
        let interest = Math.round((((price*ir)/100)/12)*24)
        return `20 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/24)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*24))/24)*4)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }

    else if(sr===11 || sr==="11" || sr==="15by3"){
        let interest = Math.round((((price*ir)/100)/12)*15)
        console.log(interest, "aaaa", ir)
        return `12 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/15)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*15))/15)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }else if(sr===12 || sr==="12" || sr==="10by2"){
        let interest = Math.round((((price*ir)/100)/12)*10)
        return `8 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/10)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*10))/10)*2)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }else if(sr===13 || sr==="13" || sr==="12by3"){
        let interest = Math.round((((price*ir)/100)/12)*12)
        return `9 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/12)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*12))/12)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }

    else if(sr===14 || sr==="14" || sr==="36by6"){
        let interest = Math.round((((price*ir)/100)/12)*36)
        return `30 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/36)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*36))/36)*6)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }
    else if(sr===15 || sr==="15" || sr==="36by3"){
        let interest = Math.round((((price*ir)/100)/12)*36)
        return `33 Month Low Cost EMIs (EMI amt - ${Math.ceil((price+interest)/36)}), (Annual flat interest ${ir}%) + ₹${Math.round(((price+Math.round((((price*ir)/100)/12)*36))/36)*3)+ Math.round(((pf*1.18)/100)*price)} Down Payment`
    }
}


// export const renderTenureStringInterest = (sr,price)=>{
//     if(sr===1){
//         return `3 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*16)/100)/12)*3)}) + ₹0 Down Payment`
//     }else if(sr===2){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹0 Down Payment`
//     }else if(sr===3){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹${Math.ceil((price/9)*3)} Down Payment`
//     }
//     else if(sr===4){
//         return `6 Month Low Cost EMIs (Total Interest Payable ₹${Math.round((((price*15)/100)/12)*6)}) + ₹${Math.ceil((price/8)*2)} Down Payment`
//     }
// }

export const renderInterestPercentage = (sr,price)=>{
    if(sr===1){
        return 4
    }else if(sr===2){
        return 7.50
    }else if(sr===3){
        return 5
    }
    else if(sr===4){
        return 5.63
    }else if(sr===5){
        return 11
    }else if(sr===6){
        return 14.5
    }
}

export const renderInterestAmount = (sr,price)=>{
    if(sr===1){
        return Math.round((price/100)*4)
    }else if(sr===2){
        return Math.round((price/100)*7.50)
    }else if(sr===3){
        return Math.round((price/100)*5)
    }
    else if(sr===4){
        return Math.round((price/100)*5.63)
    }
    else if(sr===5){
        return Math.round((price/100)*11)
    }
    else if(sr===6){
        return Math.round((price/100)*14.5)
    }
} //test