import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material'
import axios from 'axios'
import {getUserInfo} from '../redux/user/userActions'
import {connect} from 'react-redux'

 function AlertDialog(props) {
    const [address,setAddress]=React.useState("")
    const [phoneNumber,setPhoneNumber]=React.useState("")
    const [error,setError]=React.useState(false)

    const handleSubmit = ()=>{
        if(address.length<=0 || phoneNumber.length<=0){
            setError(true)
        }else{
            let addressData = [...props.user.userInfo.addresses,`${address} ${phoneNumber}`]
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/user/edit`,{
                "data": [
                    {"propName": "addresses", "value": addressData}
                    ]
            },{headers:{token:props.user.user}})
            .then(res=>{
                props.getUserInfo(props.user.user)
                props.setOpen(false)
            })
            .catch(err=>{
            })
        }
        
    }

  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Address
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Note: Address with phone number helps us reach to you quick
          </DialogContentText>
          <TextField fullWidth className="mt-4 mb-3" onChange={(e)=>setAddress(e.target.value)} variant="outlined" id="outlined-basics" label="Address" />
          <TextField fullWidth className="my-3" onChange={(e)=>setPhoneNumber(e.target.value)} variant="outlined" id="outlined-basics" label="Phone Number" />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={()=>handleSubmit()} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({cleverpeUser})=>{
return {
    user:cleverpeUser
}
}
const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AlertDialog)

