import React from 'react'
import { Link } from 'react-router-dom'
import gold from './gold-ingots.png'
import "./TransactionStatus.scss"
import {Button} from '@mui/material'

function CasheGold(props) {
  return (
    <div className="gold-redirect">
        <div className="row m-auto align-items-center justify-content-center">
            <div className="col-2">
            <img className="logos" src="/cashehd.png" alt="cleverpeblack" />

            </div>
            <div className="col-2">
            <img className="logos" src="/cleverpehorizontal.png" alt="cleverpeblack" />

            </div>
        </div>
        <h1>Your Free Digital Gold is here !</h1>
        <img src={gold} className='g-img' alt="gold" />
        <h2>Many congratulations on winning <span style={{color:"#EE8F3B"}}>Free Digital Gold</span> worth <span style={{color:"#EE8F3B"}}>Rs. “{props.location.state?props.location.state:'500'}”</span> on your successful transaction on CleverPe powered by CASHe.</h2>
        <p>
        <b style={{fontSize:"1.2em"}}>Here are 3 easy steps to get your free digital gold:</b>
        <br />
<b>1.</b>   Click on Register Now
<br />
<b>2.</b>   Click on “Sign in”, enter your mobile number registered with CleverPe to generate OTP
<br />
<b>3.</b>   Enter OTP for Sign in
<br />
 
That’s it! We will send your free digital gold in your CASHe-Augmont account between <b>1st</b> to <b>7th Oct 2022.</b>
<br />
 
After the gold is deposited in your account, you can redeem it into Cash or you can request delivery of physical gold! T&C
        </p>
        <Button className="btn-text" onClick={()=>{
            window.location.href="https://cashe.augmont.com/buy"
        }} variant="contained">register now</Button>
        {/* <a href="https://google.com">Click to avail now !</a> */}

    </div>
  )
}

export default CasheGold