import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import {Link} from 'react-router-dom'
import SubHeader from '../components/Header/SubHeader'
import "./PageSection.scss"
import NewHeader from '../components/Header/NewHeader'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language';
function AboutUs() {
  return (
    <div className="home">
      <NewHeader id={2} />
        {/* <Header />
        <SubHeader /> */}
        <section className="desktop-container aboutus">
        
        <h1>About CleverPe</h1>
        <div className="row m-auto">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <img className="gif" src="/tvt.gif" alt="cleverpe" />
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p className="">
        <b>From Products to services & Bills to payments, we provide India a smart way to Pay !!</b>
        <br />
        CleverPe is a new age tech-buddy of every Indian simplifying their payment methods through an instant, card-less & hassle free affordability solutions like Buy Now Pay Later & No Cost EMIs. Through their omni channel presence, a customer can pay on EMIs/credits for any product or services either at offline partner merchants or on online platform through www.cleverpe.com
        </p>
        <p>
        CleverPe also offers an exclusive <b>"Buy Now Pay Later"</b> marketplace connecting customers with their local merchant partners where a customer can buy anything and everything on interest free credits instantly issued on platform.
        </p>
        <p>
        CleverPe brings revolution in providing on-the-fly card-less credits to customers with just their NAME & MOB NO in just 2 MINUTES. Not asking for any income proof or KYC documents or demographic details. 
        </p>
        </div>
        </div>

        <h1 className="leader">Our Lending Partners</h1>

        <div className="row m-auto justify-content-between team-card-parent">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 team-card">
            <img src="/bhanix.png" className="profile-pic" alt="profile-pic" />
            <h3>Bhanix Finance & Investment Ltd </h3>
            <p><b>CASHe</b></p>
            
            {/* <p>Our journey begins with a vision of empowering financial freedom contactless</p> */}
            <IconButton onClick={()=>window.open('https://bhanixfinance.com/index.html', "_blank")} aria-label="delete" color="secondary">
            <LanguageIcon sx={{fontSize:'1.4em'}} />
            </IconButton>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 team-card">
            <img src="/manveer.jpeg" className="profile-pic" alt="profile-pic" />
            <h3>Manveer Verma</h3>
            <p><b>Founder</b></p>
            
            <p>Our journey begins with a vision of empowering financial freedom contactless</p>
            <IconButton onClick={()=>window.open('https://www.linkedin.com/in/manveer-verma/', "_blank")} aria-label="delete" color="secondary">
            <LinkedInIcon sx={{fontSize:'1.4em'}} />
            </IconButton>
          </div> */}

          {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 team-card">
          <img src="/sharjeel.jpg" className="profile-pic" alt="profile-pic" />
            <h3>Sharjeel Shaikh</h3>
            <p><b>CTO</b></p>
            <p>Leading with pioneering tech solutions for secure and seamless finance experiences.</p>
            <IconButton onClick={()=>window.open('https://www.linkedin.com/in/sharjeelsktg/', "_blank")} aria-label="delete" color="secondary">
            <LinkedInIcon sx={{fontSize:'1.4em'}} />
            </IconButton>
          </div> */}

          {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 team-card">
          <img src="/fida.jpg" className="profile-pic" alt="profile-pic" />
            <h3>Fida Ur Rehman</h3>
            <p><b>Product Manager</b></p>
            <p>Passionate about driving product innovation to shape a brighter financial future.</p>
            <IconButton onClick={()=>window.open('https://www.linkedin.com/in/khan-fida-ur-rehman-834534190/', "_blank")} aria-label="delete" color="secondary">
            <LinkedInIcon sx={{fontSize:'1.4em'}} />
            </IconButton>
          </div> */}
        </div>

        </section>
        {/* <Footer /> */}
    </div>
  )
}

export default AboutUs