import React from 'react';

function LoanDetails(props) {
  const loanDetails = {
    emiScheme:props.emiScheme,
    grossLoanAmount:props.grossLoanAmount,
    downPayment:props.downPayment,
    netLoanAmount:props.netLoanAmount,
    loanTenure:props.loanTenure,
    // annualInterest:props.annualInterest,
    interestOnLoan:props.interestOnLoan,
    processingFee:props.processingFee,
    otherCharges:props.otherCharges,
    totalAmount:props.totalAmount,
    paymentFreq:props.paymentFreq,
    instalmentsCount:props.instalmentsCount,
    emiAmount:props.emiAmount,
    scheme:props.displayString,
  };

  return (
    <div className="loan-details-card">
      <h2>Loan Details</h2>
      <div className="details-list">
        {Object.entries(loanDetails).map(([key, value]) => (
          <div key={key} className="detail-item">
            <span className="detail-label">{key}: </span>
            <span className="detail-value">{value} {key==="emiAmount"&&"+ Broken interest as per 0.067% per day"}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  fontFamily: 'Arial, sans-serif',
};

export default LoanDetails;
