import React from 'react'
import "./Description.scss"
function Specification({spec}) {
    
  return (
    <div>
        <h1>Product Specification</h1>


        {
            spec.map((item,index)=>(
                <div key={index}>
                            <div className="row my-4 mx-auto spec-container justify-content-between align-items-center">
            <div className="col-5 key">
                <h3>{Object.entries(item)[0][0]}</h3>
            </div>
            <div className="col-6 value">
                <h3>
                {Object.entries(item)[0][1]}
                </h3>
            </div>
        </div>
                </div>
            ))
        }




    </div>
  )
}

export default Specification