import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import {connect} from 'react-redux'
import MerchantDashhead from '../MerchantDashhead';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import "./GenerateMerchantQR.scss"
import {getUserInfo,storeUserInfo} from '../../redux/user/userActions'
import {useForm} from 'react-hook-form'
import {renderName,renderTenureString,renderTenure,renderTenureStringInterest, renderTenureInterest} from '../../Utils/Functions'
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import {Link} from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import _ from 'lodash'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FormLabel from '@mui/material/FormLabel';
function GenerateMerchantQR(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [value, setValue] = React.useState({});
    const [inputValue, setInputValue] = React.useState('');
    const [value2, setValue2] = React.useState("");
    const [inputValue2, setInputValue2] = React.useState('');
    const [options,setOptions]=React.useState([])
    const [checked,setChecked]=React.useState(true)
    const [dataV,setData]=React.useState([])
    const [quantity,setQuantity]=React.useState(1)
    const [orderId,setOrderId]=React.useState("")
    const [productData,setProductData]=React.useState([])
    const [tenure,setTenure]=React.useState(1)
    const [error,setError] = React.useState("")
    const [prodsrl,setProdsrl] = React.useState("")
    const [selectedItem,setSelectedItem] = React.useState(null)
    const [verifiedConekt,setVerifiedConekt] = React.useState(false)
    const [success,setSuccess] = React.useState("")
    const [disabledButton,setDisabledButton]=React.useState(false)
    const [conektobject,setConektObject] = React.useState(null)
    //const [error,setError]=React.useState("")
    React.useEffect(() =>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/product/allWithVariation`,{headers:{token:props.user.user}})
        .then(res=>{
          if(res.data.result.length>0){
            let nameArray = res.data.result.map((item,index)=>({label:renderName(item),variationId:item.variations._id}))
            setOptions(nameArray)
            setData(res.data.result)
          }else{
            setOptions([])
          }
        })
        .catch(err=>{
        })
      },[])
      const Total = ()=>{
        let total = 0
        let gstTotal = 0
        productData.map(item=>{
          total = (parseInt(item.productprice)*parseInt(item.quantity))+total;
          // gstTotal = (total*parseInt(item.gst))/100
          // total = gstTotal+total;
        })
        
        return total;
      }

      const onSubmit = (data)=>{
      if(_.isEmpty(value)){
        //error
      }else{
        let arr = productData.filter(item=>item.variation.variationId===value.variationId)
        if(arr.length>0){
          //error
        }else{
          setProductData([...productData,{variation:value,...data}])
        }
      }

      }

      
      const handleProductAdd = ()=>{
        let mobile;
        let name;
        let variations = productData.map(item=>{
          mobile = item.mobileNo
          name = item?.name;
          return {
            productPrice:item.productprice,quantity:item.quantity,variationId:item.variation.variationId,imeiserial:item.imeiserial
          }
        })
        let downPayment = renderTenure(tenure,Total())
        let interest = props.user.userInfo.merchantData.schemeStatus?true:false
        let keyFactStatement = {
          grossLoanAmount: Total(),
          downPayment: interest?renderTenureInterest(parseInt(tenure),Total()).downPayment:renderTenure(parseInt(tenure),Total()).downPayment,
          netLoanAmount: interest?(Total()-renderTenureInterest(parseInt(tenure),Total()).downPayment):(Total()-renderTenure(parseInt(tenure),Total()).downPayment),
          loanTenure: interest?renderTenureInterest(parseInt(tenure),Total()).months:renderTenure(parseInt(tenure),Total()).months,
          annualInterest: interest?(renderTenureInterest(parseInt(tenure),Total()).months===3?16:15):0,
          interestOnLoan:interest?renderTenureInterest(parseInt(tenure),Total()-renderTenureInterest(parseInt(tenure),Total()).downPayment).interest:0,
          otherCharges: 0,
          paymentFreq : "Monthly",
          instalmentsCount: interest?renderTenureInterest(tenure,Total()).months:renderTenure(tenure,Total()).months,
       }
       keyFactStatement.processingFee = (keyFactStatement.netLoanAmount/100)*1.5
       keyFactStatement.totalAmount = keyFactStatement.grossLoanAmount + keyFactStatement.interestOnLoan + keyFactStatement.processingFee
       keyFactStatement.emiAmount = (keyFactStatement.netLoanAmount + keyFactStatement.interestOnLoan)/keyFactStatement.loanTenure
       keyFactStatement.systemTenure = parseInt(tenure)
       console.log(keyFactStatement)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/createOffline`,{
          mobileNo:mobile,
          name,
          merchantId:props.user.userInfo.merchantId,
          variations,
          total:Total(),
          conekt:verifiedConekt,
          // scheme:"",
          // validation:response of your response
          //oemId
          //keyFactStatement:{}
          // downPayment:{price:downPayment.downPayment,status:0,tenure:parseInt(tenure),
          // interest
          // },
          keyFactStatement,
          serialNo:conektobject?conektobject:{}
        },{headers:{token:props.user.user}})
        .then(res=>{
          console.log(res)
          if(res.data.msg==="Success"){
            setOrderId(res.data.result._id)
          }
        })
        .catch(err=>{
        })
      }

const renderDisabled = ()=>{
  if(productData.length<=0){  
    return true
  }else if(selectedItem){
    if(verifiedConekt){
      console.log("verified conekt")
      return false
    }else{
      return true
    }
  }
  else{
    return false
  }
  
}
console.log(renderDisabled().toString(),selectedItem,verifiedConekt)
  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto' >
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <MerchantDashhead id={5} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container" >


              {props.user.userInfo.kyc?<section>

                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>

                <div className="offline-pay" style={{backgroundColor:"white"}}>
                  <img src="/digitalgold.jpeg" alt="digigold" className="digigold" />
                  <div style={{textAlign:"center"}} className="p-3">
                  <Link className="link" to="/offersto">Terms and conditions</Link>
                  </div>
      <h1>Generate QR For Customer</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
      <TextField error={errors.name?true:false} {...register('name',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Customer Name" />
      <TextField error={errors.mobileNo?true:false} type='number' {...register('mobileNo',{required:true,maxLength:10})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Customer Mobile Number" />

            <div className="my-3">
            {options.length>0&&<Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  
                  if(newValue){
                    let arr = dataV.filter(item=>item.variations._id===newValue.variationId)[0]
                    console.log(arr)
                    if(arr){
                      if(arr.brand.name==="Conekt"){
                        setDisabledButton(true)
                        setSelectedItem(arr)
                      }else{
                        setSelectedItem({})
                        setVerifiedConekt(false)
                      }
                    }
                    
                  }
                  
                }}
                filterSelectedOptions
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Product"
                    placeholder="Product"
                />
                )}
            />}
            </div>


        <TextField error={errors.productprice?true:false} type='number' {...register('productprice',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Price" />
        <TextField error={errors.quantity?true:false} type='number' defaultValue={1} {...register('quantity',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Quantity" />
        {/* <TextField defaultValue={18} {...register('gst',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product GST" /> */}
        <TextField error={errors.imeiserial?true:false} type='number' {...register('imeiserial',{required:true})} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="IMEI Number or Serial Number" />

        {!_.isEmpty(selectedItem)&&
(        selectedItem.brand.name==="Conekt"&&<>
        <TextField onChange={(e)=>setProdsrl(e.target.value)} className='my-3' fullWidth id="outlined-basics" variant="outlined" label="Product Serial Number" />
        {!verifiedConekt&&<Button variant="outlined" onClick={()=>{
          if(prodsrl.length<=0){
            setError("Enter Valid Serial")
          }else{
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/serial/check`,{serialNo:prodsrl},{headers:{token:props.user.user}})
            .then(res=>{
              console.log(res)
              if(res.data.result==="Not Found"){
                setSuccess("")
                setVerifiedConekt(false)
                setError(res.data.result)
                setDisabledButton(true)
              }
              else{
                setError("")
                setSuccess("Verification Successfull")
                setConektObject(res.data.result)
                setDisabledButton(false)
                setVerifiedConekt(true)
              }
            })
          }
          
        }}>Verify Serial</Button>}
        {error.length>0&&<p style={{textAlign:"center",fontWeight:"bold"}}>{error}</p>}
        {success.length>0&&<p style={{textAlign:"center",fontWeight:"bold"}}>{success}</p>}
        </>)
        }
        <div className="my-4 btnc" style={{textAlign:"center"}}>
            <Button disabled={productData.length>0?true:false} endIcon={<AddIcon />} type="submit" variant="outlined">Add Product</Button>
            </div>
        <p><b>Note:</b> Generate QR once you have selected products for your customer</p>

        <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Select CleverPe Plan For Customer</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        onChange={(e)=>setTenure(e.target.value)}
      >
        {(props.user.userInfo.groupId==="SANGEETHA" || props.user.userInfo.groupId==="WHAM" || props.user.userInfo.groupId==="ANURADHA")?<>
        {
          Total()>5999?[1,2].map((item,index)=>(
            !_.isEmpty(selectedItem)?<FormControlLabel key={index} value={item} control={<Radio />} label={selectedItem.brand.name==="Conekt"?renderTenureString(item,Total()):props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):
              renderTenureString(item,Total())} />:<FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          ))
          :[1].map((item,index)=>(
            <FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          ))
        }
        </>:
        <>
        
        {
          <>
          {
          !_.isEmpty(selectedItem)?selectedItem.brand.name==="Conekt"&&Total()>5999?[1,2].map((item,index)=>(
            !_.isEmpty(selectedItem)?<FormControlLabel key={index} value={item} control={<Radio />} label={selectedItem.brand.name==="Conekt"?renderTenureString(item,Total()):props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):
              renderTenureString(item,Total())} />:<FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          ))
          :[1].map((item,index)=>(
            <FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          )):
          Total()>5999?[1,2,3,4].map((item,index)=>(
            !_.isEmpty(selectedItem)?<FormControlLabel key={index} value={item} control={<Radio />} label={selectedItem.brand.name==="Conekt"?renderTenureString(item,Total()):props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):
              renderTenureString(item,Total())} />:<FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          ))
          :[1].map((item,index)=>(
            <FormControlLabel key={index} value={item} control={<Radio />} label={props.user.userInfo.merchantData.schemeStatus?renderTenureStringInterest(item,Total()):renderTenureString(item,Total())} />
          ))

          }
          </>
        }
        </>
        }
      </RadioGroup>
    </FormControl>
            <div className="my-4 btnc" style={{textAlign:"center"}}>
            <Button disabled={productData.length<=0?true:disabledButton} endIcon={<QrCodeScannerIcon />} onClick={()=>handleProductAdd()} variant="contained">Generate QR</Button>
            </div>
      </form>
    </div>

    <div style={{textAlign:"center"}}>
    {
      orderId.length>0&&<QRCode value={`${process.env.REACT_APP_PRODUCTION}/offlinepay?${orderId}`} logoImage='/cleverpec.jpg' removeQrCodeBehindLogo logoHeight={25} logoWidth={25} />
    }
    </div>



{ productData.length>0&&<div className="table-responsive">
    <table className="table">
  <thead>
    <tr>
      <th scope="col">Sr</th>
      <th scope="col">Product Name</th>
      <th scope="col">IMEI or Serial</th>
      <th scope="col">Quantity</th>
      <th scope="col">Price</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>

   {
     productData.length>0?productData.map((item,index)=>(
      <tr key={index} style={{alignItems:"center"}}>
      <th scope="row">{index+1}</th>
      <td>{item.variation.label}</td>
      <td>{item.imeiserial}</td>
      <td>{item.quantity}</td>
      <td>{parseInt(item.productprice)}</td>
      <td>
        <DeleteOutlineIcon className="cursor-pointer" onClick={()=>{
          setProductData(productData.filter(i=>item.variation.variationId!==i.variation.variationId))
          }} sx={{fontSize:"1.3em"}} />
      </td>
    </tr>
     )):null
   }
  </tbody>
</table>



    </div>
    }



</section>:
                <section style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100%"}}>
                <div>
                <FindInPageIcon sx={{fontSize:"3em"}} />
                </div>
                <div>
                <h3>Your account is under review</h3>
                </div>
                </section>
}

            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GenerateMerchantQR)

