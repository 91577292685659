import React, { useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import GroupIcon from '@mui/icons-material/Group';
import SecurityIcon from '@mui/icons-material/Security';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { IconButton } from '@mui/material';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import "./UserHome.scss"
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import axios from 'axios';
import { connect } from 'react-redux';
import { setLoading } from '../redux/loading/loadingActions';
import { formatIndianCurrency } from '../Utils/Functions';

const UserHome = (props) => {
  console.log("userhomeprops",props)
  const [value, setValue] = useState(4);
  const [data,setData] = React.useState(null)
  const [personalInfo,setPersonalInfo] = React.useState(null)
  const [error,setError] = React.useState(null)
  React.useEffect(()=>{
    props.setLoading(true)
    axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/customer/basic`,{headers:{token:props.user.user}})
    .then(res=>{
      props.setLoading(false)
      console.log(res)
      if(res.data.result.creditLine){
        setError(null)
        setData(res.data.result)
        if(res.data.result.orders.length>0){
          setPersonalInfo({mobileNo:res.data.result.orders[0].customer.mobileNo,name:res.data.result.orders[0].customer.name})
        }
      }else{
        setError("Unable to fetch details")
      }
      
      
      
    })
    .catch(err=>{
      console.log(err)
      props.setLoading(false)
    })
  },[])
  const styles = {
    bottomNavigationAction: {
      // Override the default styles for BottomNavigationAction
      "&.Mui-selected": {
        // Remove the yellow border when the button is selected
        border: 'none',
      },
    },
  };

  const renderData = ()=>{
    if(value===4){
      return error?<h1>{error}</h1>:<section className="userhome-body">
      <div className="icon-button-first">
        <ClearAllOutlinedIcon sx={{fontSize:34,color:"white"}} />
      </div>
      <section className='upper-body'>
      <div className="my-4">
      <h1>Hi, {personalInfo&&personalInfo.name}!</h1>
      <p className="wallet-text">Here's Your Spends</p>
      </div>
  
      <div className="row m-auto spend-div">
        <div className="col-6">
          <p>Spend</p>
          <h2>{data?formatIndianCurrency(data.creditLine.sanctionedAmount-data.creditLine.availableAmount):"₹0"}</h2>
          <LinearProgress className="my-3" color="quaternary"  variant="determinate" value={70} />
        </div>
        <div className="col-6">
          <p>Approved Limit</p>
          <h2>{data?formatIndianCurrency(data.creditLine.sanctionedAmount):"₹0"}</h2>
          <LinearProgress className="my-3" color="quaternary" variant="determinate" value={30} />
        </div>
      </div>
      </section>
  
  
      <div className="activities-head">
      {/* <h3 className="">Activities</h3> */}
      <div className="row m-auto justify-content-around align-items-center">
        <div className="col-3 activities-child shadow">
          <GroupIcon sx={{fontSize:35,color:"#ccc"}} />
          <p className="my-2" style={{color:"#cccc"}}>Account</p>
        </div>
        <div onClick={()=>{
          props.setLoading(true)
          if(personalInfo.mobileNo){
            axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/b2b/cashe/loaninstsummary/${personalInfo.mobileNo}`,{headers:{token:props.user.user}})
            .then(res=>{
              console.log(res)
              props.setLoading(false)
              if(res.data.result.razorPayPaymentLink){
                window.location.href=res.data.result.razorPayPaymentLink
              }
            })
            .catch(err=>{
              props.setLoading(false)
              console.log(err)
            })
          }
          
        }} className="col-3 activities-child shadow">
          <SecurityIcon sx={{fontSize:35}} />
          <p className="my-2">Pay EMI</p>
        </div>
        <div className="col-3 activities-child shadow">
          <ContactSupportIcon sx={{fontSize:35,color:"#ccc"}} />
          <p className="my-2" style={{color:"#cccc"}}>Help</p>
        </div>
      </div>
      </div>
  
  
      <h3>My Orders</h3>
      {data&&<>
      {
        data.orders.length>0&&data.orders.map((item,index)=><>
        <div key={index} className="row m-auto align-items-center transaction-single">
        <div className="col-2">
          <img src="/upArrow.png" alt="uparrrow" />
        </div>
        <div className="col-7">
          <h4>{item.productName}</h4>
          <p>More Details</p>
        </div>
        <div className="col-3">
          <h4>{formatIndianCurrency(item.total)}</h4>
          <p>{item.createdAt}</p>
        </div>
      </div>
        </>)
      }
      </>}
  

      </section>
    }else if(value===1){
       return <section className="transaction-userhome">
       {/* <div className="icon-button-first">
         <ClearAllOutlinedIcon sx={{fontSize:34,color:"black"}} />
       </div> */}
  
   
       <h1>Transactions</h1>
       {data&&<>
       {
         data.orders.length>0&&data.orders.map((item,index)=>item.status==="Completed"?<>
         <div key={index} className="row m-auto align-items-center transaction-single">
         <div className="col-2">
           <img src="/upArrow.png" alt="uparrrow" />
         </div>
         <div className="col-7">
           <h4>{item.productName}</h4>
           <p>More Details</p>
         </div>
         <div className="col-3">
           <h4>{formatIndianCurrency(item.total)}</h4>
           <p>{item.createdAt}</p>
         </div>
       </div>
       <hr />
         </>:
         <div className="row m-auto transaction-single">
         <div className="col-2">
           <ArrowDropDownCircleIcon sx={{fontSize:30}} />
         </div>
         <div className="col-7">
         <h4>{item.productName}</h4>
           <p>More Details</p>
         </div>
         <div className="col-3">
         <h4>{formatIndianCurrency(item.total)}</h4>
           <p>Mar 23</p>
         </div>
       </div> 
         )
       }
       </>}
   

       </section>
    }
    else{
      return <section>
        <h1>Coming soon</h1>
      </section>
    }

  }

  return (
    <>
    <section className="mobile-visible userhome" style={{ height: '100vh' }}>


    {
      renderData()
    }



<Box position="fixed" bottom={0} width="100%">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label=""
          icon={<HomeOutlinedIcon sx={{ fontSize: 30 }} />}
          classes={{ root: styles.bottomNavigationAction }}
        />
        <BottomNavigationAction
          label=""
          icon={<ReceiptLongOutlinedIcon sx={{ fontSize: 30 }} />}
          classes={{ root: styles.bottomNavigationAction }}
        />
        <BottomNavigationAction
          label=""
          icon={<WidgetsOutlinedIcon sx={{ fontSize: 30 }} />}
          classes={{ root: styles.bottomNavigationAction }}
        />
        <BottomNavigationAction
          label=""
          icon={<NotificationsNoneOutlinedIcon sx={{ fontSize: 30 }} />}
          classes={{ root: styles.bottomNavigationAction }}
        />
        <BottomNavigationAction
          label=""
          icon={<AccountCircleOutlinedIcon sx={{ fontSize: 30 }} />}
          classes={{ root: styles.bottomNavigationAction }}
        />
      </BottomNavigation>
    </Box>
    </section>
    <section className="desktop-visible" style={{display:"flex",alignItems:"center",justifyContent:"center",height:'100vh'}}>
      <h1>LogIn with Mobile For Better Experience</h1>
    </section>
    </>
  );
};
            // backgroundColor: 'black',
            // '& .MuiBottomNavigationAction-root': {
            //   color: '#f7f7f7',
            // },
            // '& .Mui-selected': {
            //   color: '#D298FF',
            // },
            // '& .Mui-selected': {
            //   color: '#8BFF9D',
            // },
const mapStateToProps = ({cleverpeUser,loading})=>{
  return {
      user:cleverpeUser,
      loading
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      setLoading:(loading)=>dispatch(setLoading(loading)),
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(UserHome)
