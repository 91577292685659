import React from 'react'
import Skeleton from '@mui/material/Skeleton';
function CategoryItemLoading() {
  return (
    <div className="my-4 mx-2 p-5 row" style={{backgroundColor:"white",borderRadius:"10px"}}>
        <div className='col-3'>
        <Skeleton variant="rectangular" width={200} height={200} />
        </div>
        <div className='col-7'>
        <Skeleton className="mb-2" variant="text" />
        <Skeleton className="mb-2" variant="text" />
        <Skeleton className="mb-2" variant="text" />
        <Skeleton className="mb-2" variant="text" />
        <Skeleton className="mb-2" variant="text" />
        <Skeleton className="mb-2" variant="text" />
        </div>
        <div className='col-2'>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        </div>
    </div>
  )
}

export default CategoryItemLoading