import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {connect} from 'react-redux'
import "../Merchant/MerchantInventory/MerchantInventory.scss"
import {renderName} from  '../Utils/Functions'
import { storeMerchant } from '../redux/user/userActions'
import axios from 'axios'
//const options = ['Option 1', 'Option 2'];
function SelectMerchant(props) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState('');
  const [options,setOptions]=React.useState(props.options)
  const [checked,setChecked]=React.useState(true)
  const [quantity,setQuantity]=React.useState(1)
  const [data,setData]=React.useState([])


//   React.useEffect(()=>{
//       setOptions(props.options.map(item=>item.merchantId))
//   },[])

  const handleSubmit = ()=>{
    let arr = props.options.filter(i=>i.merchantId===value)
    props.storeMerchant(arr[0])
    props.setOpen(false)
  }

  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Choose Desired Merchant
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select the merchant from whom you want to buy the product
          </DialogContentText>


              <section className="my-4 inventory-form-section">
                <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}

              id="controllable-states-demo"
              className='my-2'
              options={props.options.map(item=>item.merchantId)}
              fullWidth
              renderInput={(params) => <TextField  {...params} label="Select Merchant" />}
            />


            </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>handleSubmit()} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({cleverpeUser})=>{
  return {
    user:cleverpeUser
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      storeMerchant:(merchant)=>dispatch(storeMerchant(merchant))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(SelectMerchant)
{/* <TwoBDialog title="Delete Event" description="Are you sure you want to delete this event"
rightButton="Delete"
leftButton="Cancel"
open={open}
setOpen={setOpen}
handleSubmit={handleSubmit}
/> */}
