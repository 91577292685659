import React from 'react'
import "./CategoryItem.scss"
import {Button} from '@mui/material'
import {IconButton} from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import {Link, withRouter} from 'react-router-dom'
import { formatMoney,percentage } from '../Utils/Functions';
import _ from 'lodash'
import {compose} from 'redux'
import { addItems } from '../redux/checkout/checkoutActions';
import {connect} from 'react-redux'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {addToCart,removeFromCart,increaseItemCount,decreaseItemCount,addItemToUserCart,removeItemFromUserCart,handleItemCartCount} from '../redux/cart/cartActions'
import {wishListAction,addToWishListReq,removeFromWishListReq} from '../redux/wishlist/wishlistActions'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from '@mui/material/colors';
import axios from 'axios'
function CategoryItem(props) {
    let details  = props.item;
    const [cart,setCart]=React.useState(false)
    const [cartItem,setCartItem]=React.useState({})
    const [wishlist,setWishList]=React.useState(false)
    React.useEffect(() =>{
        if(props.user.user){
            props.wishList.map(item=>{
                if(item.variations.item._id===details.variations._id){
                    setWishList(true)
                }
            })
            props.cart.map(item=>{
                if(item.variations.item._id===details.variations._id){
                    setCart(true)
                    setCartItem(item)
                }
            })
            

        }
    },[props.wishList,props.cart]) 
    const renderSpecs = ()=>{
        if(details.specification.length>0){
            return details.specification.map((item,index)=>(
                
                <div >
                {index<=5&&_.map(item,(value,key)=>(
                <p className="attribute"><span className="key">{key}:</span> {value}</p>
                ))    }            
                </div>
            ))
        }
    }

    const renderName = ()=>{
        let name = details.name
        _.map(details.variations.options,(value,key)=>{
            if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
                name = name + " " + `(${value})`
            }
        })
        return name
    }
    const handleCartAdd = ()=>{
        if(!props.user.user){
            props.history.push("/signin",{route:"/"})
        }else{
            setCart(!cart)
            if(!cart){
                props.addItemToUserCart(details,props.user.user)
                setCartItem(details)
            }else{
                props.removeItemFromUserCart(details,props.user.user)
                setCartItem({})
            }
            //props.wishListAction(details)
        }
    }
    const handleWishListAdd = ()=>{
        if(!props.user.user){
            props.history.push("/signin",{route:"/"})
        }else{
            setWishList(!wishlist)
            if(!wishlist){
                props.addToWishListReq(props.user.user,details)
            }else{
                props.removeFromWishListReq(props.user.user,details)
            }
            //props.wishListAction(details)
        }
    }
  return (
    <div className="row m-auto category-item">
        <div className="col-3 imgdiv">
            {details.variations.images.length>0?
            <img src={details.variations.images[0]} alt={details.variations.images[0]} />
            :<img src="/cleverpeproduct.png" alt="oppo" />}
        </div>
        <div className="col-6 infodiv">
        <Link to={`/productinfo?${details.variations._id}`} style={{color:"inherit"}}>
            <h3>{renderName()}</h3>
            {
                renderSpecs()
            }
                </Link>
            
            {/* <p className="attribute"><span className="key">Rom:</span> 128 GB</p>
            <p className="attribute"><span className="key">Display:</span> 16.51 cm (6.5 inch) Full HD+ Amoled Display</p>
            <p className="attribute"><span className="key">Battery:</span> 4500 mAh Lithium-ion Polymer Battery</p>
            <p className="attribute"><span className="key">Processor:</span> MediaTek Dimensity 1200-Max Processor</p>
            <p className="attribute"><span className="key">Charger:</span> 65W Super VOOC | OPPO Sony IMAX766</p> */}
            <div className="row m-auto button-container">
                    {
                    !cart?<Button onClick={()=>handleCartAdd()} startIcon={<AddShoppingCartOutlinedIcon />} variant="outlined">Add To Cart</Button>:
                    <div className="col-6 row m-auto align-items-center justify-content-between">
                        <div className="col-3">
                        <IconButton onClick={()=>{
                        if(cartItem.variations.quantity===1){
                            setCart(false)
                            props.removeItemFromUserCart(cartItem,props.user.user)
                        }else{
                            props.handleItemCartCount(cartItem,false,props.user.user)    
                        }
                        }}>
                            <RemoveIcon />
                        </IconButton>
                        </div>

                        <div className="col-2">
                        <p>{cartItem.variations.quantity}</p>
                        </div>

                        <div className="col-3">
                        <IconButton onClick={()=>{
                        props.handleItemCartCount(cartItem,true,props.user.user)
                            }}>
                            <AddIcon />
                        </IconButton>
                        </div>
                    </div>
                    }
                
                <div className="col-6">
                <Button onClick={()=>{
                    //props.addItems([details])
                    if(props.user.user){
                        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/variation/single`,{variationId:details.variations._id},{headers:{token:props.user.user}})
                        .then(res=>{
                            if(res.data.msg==="Success"){
                                props.addItems([{_id:"",variations:{item:res.data.result[0],quantity:1}}])
                                props.history.push("/checkout",1)
                            }
                            
                        })
                        .catch(err=>{
                        })
                        
                    }else{
                        props.history.push("/signin",{route:"checkout"})
                    }
                    }} className="ml-3" variant="contained">Buy Now</Button>
                    </div>
            </div>
        </div>
        <div className="col-3 pricing-div">
        <div className="icon-button">
                <IconButton onClick={()=>handleWishListAdd()}>
                    {wishlist?<FavoriteIcon sx={{color:red[500]}} className="icon" />:<FavoriteBorderIcon className="icon"/>}
                </IconButton>
            </div>
            <h2 className="price">{formatMoney(details.variations.options.price)}</h2>
            <span className="regularprice">{formatMoney(details.variations.options.mrp)}</span><span className="percentage">{percentage(details.variations.options.mrp,details.variations.options.price)}% off</span>
            <p className="downpayment">Downpayment</p>
            <p className="m-0"><span className="emitext">EMI From</span><span  className="emi">{formatMoney(Math.ceil(details.variations.options.price/12))}/mon*</span></p>
            <p className="homedelivery">Free Home Delivery</p>
        </div>
    </div>
  )
}


const mapStateToProps = ({cleverpeUser,cart,wishList})=>{
    return {
        user:cleverpeUser,
		cart,
        wishList
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        addItems:(items)=>dispatch(addItems(items)),
        wishListAction:(item)=>dispatch(wishListAction(item)),
        addToCart:(item)=>dispatch(addToCart(item)),
        increaseItemCount:(id)=>dispatch(increaseItemCount(id)),
        decreaseItemCount:(id)=>dispatch(decreaseItemCount(id)),
        removeFromCart:(item)=>dispatch(removeFromCart(item)),
        addItemToUserCart:(item,token)=>dispatch(addItemToUserCart(item,token)),
        removeItemFromUserCart:(item,token)=>dispatch(removeItemFromUserCart(item,token)),
        removeFromWishListReq:(token,item)=>dispatch(removeFromWishListReq(token,item)),
        addToWishListReq:(token,item)=>dispatch(addToWishListReq(token,item)),
        handleItemCartCount:(item,check,token)=>dispatch(handleItemCartCount(item,check,token))
    }
}

export default compose(withRouter,connect(mapStateToProps,mapDispatchToProps))(CategoryItem)