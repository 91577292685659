import React from 'react'
import "./CategoryDetail.scss"
import CategoryItemMobile from './CategoryItemMobile'
import { IconButton } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import "./CategoryDetail.scss"
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import CategoryItem from './CategoryItem'
import Pagination from '@mui/material/Pagination';
import SubHeader from '../Header/SubHeader'
import axios from 'axios'
import {connect} from 'react-redux'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import comingsoon from '../Images/text.png'
function CategoryDetailMobile(props) {
    const [data,setData]=React.useState([])
    const [pageNumber,setPageNumber]=React.useState(1)
    const [totalPage,setTotalPage]=React.useState(1)
    const [brands,setBrands]=React.useState([])
    const [brandFilter,setBrandFilter]=React.useState([])
    const [priceFilter,setPriceFilter]=React.useState(null)
    const [ratingFilter,setRatingFilter]=React.useState(null)
    const [name,setName]=React.useState("")
    const [loading,setLoading]=React.useState(false)
    const rating = [5,4,3,2,1]
  const [open,setOpen] = React.useState(false)
  React.useEffect(()=>{
    setLoading(true)
    setName(props.location.state.name)
    if(props.location.state.categoryId && !props.location.state.brand){
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/byCategory`,
        {
            categoryId:props.location.state.categoryId,
            byBrand:brandFilter.length<=0?null:brandFilter,
            byRating:ratingFilter,
            byPrice:priceFilter===null?null:priceFilter,
            page:pageNumber
        
        },{headers:{token:props.user.user}})
        .then(res=>{


            //axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/brandsByCategory`,{categoryId:"62571e99703b0600041b1db7",brand:"Mi"})
            setLoading(false)
            setData(res.data.result[0].products)
            setBrands(res.data.result[0].brands)
            setTotalPage(res.data.result[0].pages)
        })
        .catch(err=>{
            setLoading(false)
        })
    }else{
        
        if(props.location.state.data!==undefined){
            //go for mapping search items
            setLoading(false)
            setData(props.location.state.data)
        }else{
            //go for brand or category serach
            if(props.location.state.brand && props.location.state.categoryId){
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/byCategory`,
                {
                    categoryId:props.location.state.categoryId,
                    byBrand:brandFilter.length<=0?[props.location.state.brand]:[...brandFilter,props.location.state.brand],
                    byRating:ratingFilter,
                    byPrice:priceFilter===null?null:priceFilter,
                    page:pageNumber
                
                },{headers:{token:props.user.user}})
                .then(res=>{
    
    
                    //axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/brandsByCategory`,{categoryId:"62571e99703b0600041b1db7",brand:"Mi"})
                    setLoading(false)
                    setData(res.data.result[0].products)
                    setBrands(res.data.result[0].brands)
                    setTotalPage(res.data.result[0].pages)
                })
                .catch(err=>{
                    setLoading(false)
                })
            }else{
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/product/byBrand`,{
                    byBrand:[props.location.state.name],
                    byRating:ratingFilter,
                    byPrice:priceFilter===null?null:priceFilter,
                    page:pageNumber
                })
                .then(res=>{
                    setLoading(false)
                    setData(res.data.result[0].products)
                    setBrands(res.data.result[0].brands)
                    setTotalPage(res.data.result[0].pages)
                })
                .catch(err=>{
                    setLoading(false)
                })
            }

        }
        


    }
    
},[props.location.state,brandFilter,priceFilter,ratingFilter,pageNumber])
  return (
    <div className="category-detail-mobile">
          {!open?<IconButton onClick={()=>setOpen(true)}>
                  <MenuOutlinedIcon />
          </IconButton>
          :
          <IconButton onClick={()=>setOpen(false)}>
            <CloseOutlinedIcon />
          </IconButton>}

         { open&&<section className="filter-container-mobile">
         <h4>Filters</h4>
                <hr />
                
                <div>
                <h2>Brand</h2>
                <FormGroup>
                    {
                        brands.length>0?brands.map((item,index)=>(
                            <FormControlLabel key={index}   control={<Checkbox checked={brandFilter.includes(item._id)?true:false} onChange={(e)=>{
                                if(e.target.checked===true){
                                   setBrandFilter([...brandFilter,item._id])
                                }else if(e.target.checked===false){
                                    setBrandFilter(brandFilter.filter(it=>it!==item._id))
                                }
                            }} />} label={item._id} />
                        )):null
                    }
                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Oppo" /> */}
                
                {/* <FormControlLabel control={<Checkbox />} label="Xiaomi" />
                <FormControlLabel control={<Checkbox />} label="Samsung" /> */}
                </FormGroup>
                <hr />
                </div>

                <div>
                <h2>Price</h2>
                <p className="subheading">Min & Max Price both are required*</p>
                <TextField onChange={(e)=>{
                    if(priceFilter===null){
                        setPriceFilter({lowest:e.target.value,highest:0})
                    }else{
                        setPriceFilter({lowest:e.target.value,highest:priceFilter.highest})
                    }
                }} className="my-2" fullWidth id="outlined-basic" label="Minimum Price" variant="outlined" />
                <TextField 
                onChange={(e)=>{
                    if(priceFilter===null){
                        setPriceFilter({lowest:0,highest:e.target.value})
                    }else{
                        setPriceFilter({lowest:priceFilter.lowest,highest:e.target.value})
                    }
                }}
                className="my-2" fullWidth id="outlined-basic" label="Maximum Price" variant="outlined" />
                <hr />
                </div>

                <div>
                <h2>Rating</h2>


                    <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={ratingFilter}
                        onChange={(e)=>setRatingFilter(e.target.value)}
                    >
                        
                        {
                            rating.map((item,index)=><FormControlLabel key={index} value={item} control={<Radio />}label={<Rating name="read-only" value={item} readOnly />} />)
                        }
                    </RadioGroup>
                    </FormControl>
                <hr />
                </div>
            </section>}


        <section className="product-container">
        <h4 className="subheading">{props.location.state.name}</h4>
                <div className="row m-auto sortingdiv align-items-center justify-content-between">
                    <div>
                    <h1>Showing {data.length} results for "{props.location.state.name}"</h1>
                    </div>
                    <div>
                        {/* <h3><span className="subheading">Sort By</span> - Popularity</h3> */}
                    </div>
                </div>

                <section>
                    {
                        data.length>0?
                        <div>

                        {data.map((item,index)=>(
                            <div key={index} className='my-4 mx-2'>
                            <CategoryItemMobile item={item}  />
                            </div>
                        ))}
                                                <Pagination page={pageNumber} onChange={(e,pagenumber)=>{
                             window.scrollTo(0, 0)
                            setPageNumber(pagenumber)}} count={totalPage} />
                        </div>
                        :
                        <div className="no-products">
                            <img src={comingsoon} alt={comingsoon} />
                        </div>
                    }
                </section>

                </section>
    </div>
  )
}
const mapStateToProps = ({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

export default connect(mapStateToProps)(CategoryDetailMobile)