import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {connect} from 'react-redux'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Rating from '@mui/material/Rating';
const Input = styled('input')({
  display: 'none',
});
function ReviewModal(props) {
    const {register,handleSubmit,formState:{errors}}=useForm()
    const [selectedImage,setSelectedImage]=React.useState([])
    const [value, setValue] = React.useState(5);

    const onSubmit = ({description,title})=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/review/create`,{title,description,rating:value,productId:props.productId,orderId:props.orderId},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            props.setOpen(false)
        })
        .catch(err=>{
            props.setOpen(false)
        })
        
      
    }

  return (
    <div>
      <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
        <DialogTitle>Rate Product</DialogTitle>
        <form onSubmit = {handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Note : Ratings and Reviews helps other customer buy a good and relaible product
          </DialogContentText>

          <div style={{textAlign:"center"}} className="mt-3">
          <Rating
          sx={{fontSize:"3em"}}
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
      </div>
          <TextField
          inputProps={{ maxLength: 200 }}
          {...register('title',{required:true})}
          error={errors.title?true:false}
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            fullWidth
            variant="standard"
          />
                    <TextField
          inputProps={{ maxLength: 200 }}
          
          {...register('description',{required:true})}
          error={errors.description?true:false}
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            fullWidth
            variant="standard"
          />





        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

export default connect(mapStateToProps)(ReviewModal)