import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import {formatMoney,renderName2,orderSubTotal,orderTotal,orderDiscount,renderPriceWithoutGst} from '../../Utils/Functions'
import html2canvas from 'html2canvas';
import moment from 'moment'
function Invoice(props) {
    let order = props.location.state;
    const [totalObject,setTotalObject]=React.useState({ratePerItem:0,taxableItem:0,gst:18,igst:0,total:0})
    React.useEffect(()=>{
        //window.print()
        let ratePerItem=0;
        let taxableItem=0;
        let gst=0;
        let igst=0;
        let total=0;
        order.items.map((item,index)=>{
            total = total + (parseInt(item.details.options.price)*item.quantity);
            gst = (item.details.gst+gst)/(index+1);
            let gstamount = (item.details.options.price/100)*item.details.gst;
            ratePerItem = ratePerItem + (parseInt(item.details.options.price)-gstamount)
            taxableItem = ratePerItem;
            igst = igst + (parseInt(item.details.options.price)/100)*item.details.gst
        })
        setTotalObject({ratePerItem,taxableItem,gst,igst,total})
    },[])

    var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

function inWords (num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}


  return (
    <div>
        
    <div className="invoice" id="divToPrint">
        <div className="row mt-2 mx-auto">
            <div className="col-4">
                <p><b>Tax Invoice</b></p>
            </div>
            <div className="col-4" style={{textAlign:"center"}}>
                <img src="/cleverpeblack.png" alt="cleverpeblack" />
            </div>
            <div className="col-4" style={{textAlign:"right"}}>
                <p><b>ORIGINAL</b></p>
            </div>
        </div>
        <p className="ml-3"><b>GSTIN:29AAKCC2473L1Z6</b></p>

        <div style={{textAlign:"center"}}>
        <h1>Cleverpe Technologies Private Limited</h1>
        <p>97, HOSUR, MAINMADIWALA,</p>
        <p>Bengaluru Urban, Karnataka,560034</p>
        </div>

        <div className="row mx-3 justify-content-between">
            <div className="">
            https://cleverpe.com/
            </div>

            <div className="">
            support@cleverpe.com
            </div>
        </div>

        <div className="row m-auto">
            <div className="border-col col-4">
                <p>Invoice No: <b>3578</b></p>
            </div>
            <div className="border-col col-4">
                <p>Order No: <b>3578</b></p>
            </div>
            <div className="border-col col-4">
                <p>Place of Supply: <b>Lucknow</b></p>
            </div>
        </div>

        <div className="row m-auto">
            <div className="border-col col-4">
                <p>Invoice Date: <b>{moment.parseZone(order.createdAt).local().format("MMMM Do YYYY")}</b></p>
            </div>
            <div className="border-col col-4">
                <p>Order Date: <b>{moment.parseZone(order.createdAt).local().format("MMMM Do YYYY")}</b></p>
            </div>
            <div className="border-col col-4">
                <p>Date of Supply: <b>{moment.parseZone(order.createdAt).local().format("MMMM Do YYYY")}</b></p>
            </div>
        </div>
        <div>
        <p>Payment Method: <b>RAZORPAY / CASHE</b></p>
        </div>

        <div className="row my-3 mx-auto">
            <div className="border-col p-0 col-6">
                <div><p className="greycol center">BILL TO PARTY</p>
                </div>
                <div className="border-col">
                    <p>{order.shipping.first_name} {order.shipping.last_name}</p>
                </div>
                <div className="border-col">
                    <p>{order.shipping.address_1}</p>
                </div>
                <div className="border-col">
                    <p>Postcode: {order.shipping.postcode} </p>
                </div>
                <div className="border-col">
                    <p>Phone No: {order.shipping.phone}</p>
                </div>
                <div className="border-col">
                    <p>GSTIN: </p>
                </div>
                <div className="border-col">
                    <p>State: {order.shipping.state}</p>
                </div>
                <div className="border-col">
                    <p>Country: {order.shipping.country}</p>
                </div>
            </div>
            <div className="border-col p-0 col-6">
            <div><p className="greycol center"><b>SHIP TO PARTY / DELIVERY ADDRESS</b></p>
            </div>
            <div className="border-col">
                    <p>{order.shipping.first_name} {order.shipping.last_name}</p>
                </div>
                <div className="border-col">
                    <p>{order.shipping.address_1}</p>
                </div>
                <div className="border-col">
                    <p>Postcode: {order.shipping.postcode} </p>
                </div>
                <div className="border-col">
                    <p>Phone No: {order.shipping.phone}</p>
                </div>
                <div className="border-col">
                    <p>GSTIN: </p>
                </div>
                <div className="border-col">
                    <p>State: {order.shipping.state}</p>
                </div>
                <div className="border-col">
                    <p>Country: {order.shipping.country}</p>
                </div>
            </div>
        </div>
        <table className="ui celled table">
            <thead>
                <tr><th>#</th>
                <th>Item</th>
                <th>QTY</th>
                <th>Rate Per Item (Rs.)</th>
                <th>Discount Item</th>
                <th>Taxable Item (Rs.)</th>
                <th>HSN</th>
                <th>GST %</th>
                <th>IGST Rs.</th>
                <th>Total Rs</th>
            </tr></thead>
  <tbody>
      {
          order.items.length>0&&order.items.map((item,index)=>(
            <tr key={index}>
                <td data-label="Name">{index+1}</td>
                <td data-label="Name">{renderName2(item.details.product[0],item.details.options)}</td>
                    <td data-label="Name">{item.quantity}</td>
                <td data-label="Age">{renderPriceWithoutGst((parseInt(item.details.options.price)*parseInt(item.quantity)),item.details.gst)}</td>
                <td data-label="Job">-</td>
                <td data-label="Job">{formatMoney(item.details.options.price)}</td>
                <td data-label="Job">8528</td>
                <td data-label="Job">{item.details.gst}</td>
                <td data-label="Job">{formatMoney(parseInt(item.details.options.price*item.quantity)/100*18)}</td>
                <td data-label="Job">{formatMoney(item.details.options.price)}</td>
                </tr>
          ))
      }
    

  </tbody>
</table>


<p><b>Total</b></p>

<table className="ui celled table">
  <thead>
    <tr>
    <th>Rate Per
            Item (Rs.)</th>
    <th>Discount
                Item</th>
    <th>Taxable
                Item (Rs.)</th>
    <th>HSN</th>
    <th>GST
                %</th>
    <th>IGST Rs.</th>
    <th>Total Rs</th>
  </tr></thead>
  <tbody>
  <tr>
      <td data-label="Age">{formatMoney(totalObject.ratePerItem)}</td>
      <td data-label="Job">0</td>
      <td data-label="Job">{formatMoney(totalObject.taxableItem)}</td>
      <td data-label="Job">8528</td>
      <td data-label="Job">{totalObject.gst}</td>
      <td data-label="Job">{formatMoney(totalObject.igst)}</td>
      <td data-label="Job">{formatMoney(totalObject.total)}</td>
    </tr>
  </tbody>
</table>


        {/* <div className="row m-auto">
            <div className="col-6">
            Terms and Conditions
            https://cleverpe.com/termsandconditions
            </div>
            <div className="col-3">
            Total Amount before Tax
            </div>
            <div className="col-3">
            ₹17968
            </div>
        </div> */}

        <div className="row mb-4 mx-auto">
            <div className="col-6">
            <div>
            Total Invoice Amount in Words:
            </div>
            <div>
            <b>{inWords(totalObject.total)}</b>
            </div>
            </div>
            <div className="col-3">
            <p>Total Amount before Tax</p>
            <p>Total Tax Amount</p>
            <p>Discount</p>
            <p>Shipping Amount</p>
            <p>Shipping GST Amount</p>
            <p>Total Amount After Tax</p>
            <p>Total</p>
            </div>
            <div className="col-3">
            <p>{formatMoney(totalObject.taxableItem)}</p>
            <p>{formatMoney(totalObject.igst)}</p>
            <p>₹0</p>
            <p>₹0</p>
            <p>₹0</p>
            <p>{formatMoney(totalObject.total)}</p>
            <p>{orderTotal(order.items)}</p>
            </div>
        </div>


        <div className="row m-auto">
            <div className="col-8">
            E&O.E
            </div>
            <div className="col-4">
                <p><b>Cleverpe Technologies Private Limited</b></p>
                <p>Authorised Signature</p>
            </div>
        </div>

    </div>
    <div style={{textAlign:"center"}}>
    <Button onClick={()=>window.print()}>Print Bill</Button>
    </div>
    </div>
  )
}

export default Invoice