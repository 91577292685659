import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardMenu from '../DashboardMenu';
import { IconButton,TextField,Button } from '@mui/material';
import {formatMoney,renderName2,orderSubTotal,orderTotal,orderDiscount} from '../../Utils/Functions'
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios'
import "./MyOrders.scss"
import moment from 'moment';
import {connect} from 'react-redux'
import _ from 'lodash'
import ReviewModal from './ReviewModal'

function SingleOrder(props) {
    const [open,setOpen]=React.useState(false)
    const [open2,setOpen2]=React.useState(false)
    const [order,setOrder]=React.useState({})
    const [loading,setLoading]=React.useState(false)
    const [productId,setProductId]=React.useState("")
    const [orderId,setOrderId]=React.useState("")

    React.useEffect(()=>{
        setLoading(true)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/single`,{orderId:props.location.search.slice(1)},{headers:{token:props.user.user}})
        .then(res=>{
            setOrder(res.data.result)
            console.log(res)
            //axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/review/product`,{},{headers:{token:props.user.user}})
            setLoading(false)
        })
        .catch(err=>{
            setLoading(false)
        })
    },[])

    const renderStatus = (status)=>{

        if(status){
            if(status.toLowerCase()==="processing"){
                return "status"
            }else if(status.toLowerCase()==="delivered"){
                return "green-status"
            }else{
                return "red-status"
            }
        }
        
    }
  return (
    <div>
        <ReviewModal orderId={orderId} productId={productId} open={open2} setOpen={setOpen2} />
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <DashboardMenu id={2} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>
                


                {!_.isEmpty(order)?<section className="single-order">

                <div className="row m-auto justify-content-between">
                    <p>Order: <span className="boldspan">{order._id}</span></p>
                    <p>Created At: <span className="boldspan">{moment.parseZone(order.createdAt).local().format("MMMM Do YYYY, h:mm:ss a")}</span></p>
                    <p>Delivered On: -</p>
                </div>

                <h4 className={renderStatus(order.status)}>{order.status}</h4>

                {
                    order.items.length>0&&order.items.map((item,index)=>(
                        <div key={index} className='row m-auto item-order-container'>
                            <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                            {item.details.images.length>0?
                            <img src={item.details.images[0]} alt="oppo" />
                            :<img src="/cleverpeproduct.png" alt="oppo" />
                            }
                            </div>
                            <div className="col-8 col-sm-8 col-md-7 col-lg-7 col-xl-7">
                                <h5>{renderName2(item.details.product[0],item.details.options)}</h5>
                                <p>{formatMoney(item.details.options.price)} x {item.quantity}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 order-button">
                                <Button onClick={()=>{
                                    setProductId(item.details.product[0]._id)
                                    setOrderId(order._id)
                                    setOpen2(!open2)
                                    }} variant='outlined'>Write Review</Button>
                                    <div>
                                </div>
                            </div>
                        </div>
                    ))
                }

                <div className='row m-auto shipping-container justify-content-between'>
                    <div className="col-5 shipping">
                        <h4>Shipping Address</h4>
                        <p>Address: {order.shipping.address_1}</p>
                        <p>City: {order.shipping.city}</p>
                        <p>Pincode: {order.shipping.postcode}</p>
                    </div>
                    <div className="col-6 summary">
                        <h4>Total Summary</h4>
                        <div className="flex-row-item">
                            <div>
                            <p className="key">Subtotal</p>
                            </div>
                            <div>
                            <p className="value">{orderSubTotal(order.items)}</p>
                            </div>
                        </div>

                        <div className="flex-row-item">
                            <div>
                            <p className="key">Delivery</p>
                            </div>
                            <div>
                            <p className="value">0</p>
                            </div>
                        </div>

                        <div className="flex-row-item">
                            <div>
                            <p className="key">Discount</p>
                            </div>
                            <div>
                            <p className="value" style={{color:"green"}}>{orderDiscount(order.items)}</p>
                            </div>
                        </div>

                        <div className="flex-row-item total">
                            <div>
                            <p className="key">Total</p>
                            </div>
                            <div>
                            <p className="value">{orderTotal(order.items)}</p>
                            </div>
                        </div>
                        <Button fullWidth onClick={()=>props.history.push("/invoice",order)} className="mt-3" variant="contained">View Invoice</Button>

                    </div>  
                </div>
                
                </section>:null}



            </section>
        </div>
    </div>
    <Footer />
    </div>
  )
}

const mapStateToProps =({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

export default connect(mapStateToProps)(SingleOrder)