import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SubHeader from '../../Header/SubHeader'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardMenu from '../DashboardMenu';
import { IconButton,TextField,Button } from '@mui/material';
import {formatMoney} from '../../Utils/Functions'
import MenuIcon from '@mui/icons-material/Menu';
import "./MyPayments.scss"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import ReportModal from './ReportModal'
import axios from 'axios'
function MyPayments(props) {
    const [open,setOpen]=React.useState(false)
    const [edit,setEdit]=React.useState(false)
    const [orders,setOrders]=React.useState([])
    const [open2,setOpen2] = React.useState(false)
    const [orderId,setOrderId] = React.useState("")

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/order/mine`,{headers:{token:props.user.user}})
        .then(res=>{
            setOrders(res.data.result)
        })
        .catch(err=>{
        })
    },[])


  return (
    <div>
        <ReportModal  open={open2} setOpen={setOpen2} orderId={orderId} />
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    <div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <DashboardMenu id={4} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>

                {
                    orders.length>0?(
                        <section>
                            <div className="row m-auto payments-table align-items-center">
                                <div className="col-2">
                                    <h3>Items</h3>
                                </div>
                                <div className="col-4">
                                    <h3>Down Payment</h3>
                                </div>
                                <div className="col-3">
                                    <h3>EMI Payment</h3>
                                </div>
                                <div className="col-3">
                                    <h3>Raise Query</h3>
                                </div>
                            </div>

                            {
                                orders.map((item,index)=>(
                                <div key={index} className="row m-auto payments-table-content align-items-center">
                                    <div className="col-2">
                                        <p className="ml-1">{item.items.length}</p>
                                    </div>
                                    <div className="col-4">
                                        <p className={`ml-1 ${item.downPayment.status?'paid':'notpaid'}`}>{item.downPayment.price}/{item.downPayment.status?"Paid":"Not Paid"}</p>
                                    </div>
                                    <div className="col-3">
                                    <p className={`ml-1 ${item.transaction&&(item.transaction.transactionId===""?"notpaid":"paid")}}`}><b>{(item.total-parseInt(item.downPayment.price))}/{item.transaction&&(item.transaction.transactionId===""?"Not Processed":"Processed")}</b></p>
                                    </div>
                                    <div className="col-3">
                                        {item.reported?
                                        <p className="ml-1">Wait for mail</p>
                                        :<Button onClick={()=>{
                                            setOrderId(item._id)
                                            setOpen2(true)
                                        }} className="ml-1">Report</Button>}
                                    </div>
                                </div>
                                ))
                            }
                            
                        </section>
                    ):<p>No Payments so far</p>
                }




            </section>
        </div>
    </div>
    <Footer />
    </div>
  )
}
const mapStateToProps =({cleverpeUser})=>{
    return {
        user:cleverpeUser
    }
}

export default connect(mapStateToProps)(MyPayments)