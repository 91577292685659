import React from 'react'
import "./Description.scss"
import LinearProgress from '@mui/material/LinearProgress';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Rating from '@mui/material/Rating';
function Ratings({ratings,reviews}) {

    const calculateRating = (val)=>{
        if(ratings.total===0){
            return 0
        }else{
            let per = (val/ratings.total)*100
            return per
        }
        
    }

  return (
    <div>
        <h1>Ratings and Reviews</h1>
        <div className="row m-auto">
            <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 ratings-reviews-div">
                <h2>{ratings.avg} <StarRoundedIcon /></h2>
                <p>
                {ratings.total} Ratings <br />& <br />{reviews.length} Reviews
                </p>
            </div>



            <div className='col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3'>


                <div className="row m-auto align-items-center rating-count-div">
                    <div className="col-4 row align-items-center">
                        <h3>5 <StarRoundedIcon sx={{fontSize:".8em"}}/></h3>
                    </div>
                    <div className="col-8">
                    <LinearProgress variant="determinate" value={calculateRating(ratings.graph.five)} />
                    </div>
                </div>

                <div className="row m-auto align-items-center rating-count-div"> 
                    <div className="col-4 row align-items-center">
                    <h3>4 <StarRoundedIcon sx={{fontSize:".8em"}}/></h3>
                    </div>
                    <div className="col-8">
                    <LinearProgress variant="determinate" value={calculateRating(ratings.graph.four)} />
                    </div>
                </div>

                <div className="row m-auto align-items-center rating-count-div"> 
                    <div className="col-4 row align-items-center">
                    <h3>3 <StarRoundedIcon sx={{fontSize:".8em"}}/></h3>
                    </div>
                    <div className="col-8">
                    <LinearProgress variant="determinate" value={calculateRating(ratings.graph.three)} />
                    </div>
                </div>

                <div className="row m-auto align-items-center rating-count-div"> 
                    <div className="col-4 row align-items-center">
                    <h3>2 <StarRoundedIcon sx={{fontSize:".8em"}}/></h3>
                    </div>
                    <div className="col-8">
                    <LinearProgress variant="determinate" value={calculateRating(ratings.graph.two)} />
                    </div>
                </div>

                <div className="row m-auto align-items-center rating-count-div"> 
                    <div className="col-4 row align-items-center">
                    <h3>1 <StarRoundedIcon className="ml-1" sx={{fontSize:".8em"}}/></h3>
                    </div>
                    <div className="col-8">
                    <LinearProgress variant="determinate" value={calculateRating(ratings.graph.one)} />
                    </div>
                </div>
            </div>

        </div>

        {
                reviews.length>0?reviews.map((item,index)=>(
                    <section key={index} className="customer-review-container">
                    <h2>{item.title}</h2>
                    <h3><Rating sx={{color:"black"}} name="read-only" value={item.rating} readOnly /></h3>
                    <h4>{item.userName}</h4>
                    <p>
                    {item.description}
                    </p>
                </section>
                )):
                <div className="m-3">
                    <h3>No reviews Yet</h3>
                </div>
            }



        {/* <section className="customer-review-container">
            <h2>Magna voluptate ad sit qui tempor ipsum tempor</h2>
            <h3><Rating sx={{color:"black"}} name="read-only" value={5} readOnly /></h3>
            <h4>Aute minim</h4>
            <p>
            Esse cupidatat ea enim do. Tempor sit laboris eiusmod id et excepteur exercitation nostrud ullamco. Laboris aliquip voluptate eiusmod nostrud Lorem nulla fugiat adipisicing do cupidatat.
            </p>
        </section>

        <section className="customer-review-container">
            <h2>Reprehenderit enim quis</h2>
            <h3><Rating sx={{color:"black"}} name="read-only" value={5} readOnly /></h3>
            <h4>Cupdatakto</h4>
            <p>
            Mollit sint nostrud ad labore dolor. Ut amet culpa ullamco ad esse velit dolor aliquip amet est aliqua. Tempor reprehenderit sunt magna laboris dolore quis. Aliqua ipsum aliqua anim adipisicing sint labore minim laboris mollit aute. Velit tempor cupidatat non non eu eiusmod nulla sit magna. Aliqua occaecat nostrud velit est esse aute elit excepteur duis est duis commodo. Anim anim consequat minim tempor est do minim qui.

Laborum minim id enim nostrud amet labore in. Amet amet excepteur occaecat anim esse nostrud do qui nisi sint id elit. Enim eu ea nisi irure commodo elit. Irure veniam ex sint id. Velit eiusmod ut nisi duis voluptate mollit ea dolore ex in. Anim dolore commodo fugiat Lorem occaecat culpa ullamco aliqua occaecat ea esse. Exercitation nisi adipisicing non non reprehenderit.
            </p>
        </section> */}



    </div>
  )
}

export default Ratings