import axios from 'axios';
import React, { useRef, useState, useEffect, useDebugValue } from 'react';
import { useParams } from 'react-router-dom';
import './CameraComponent.css'; // Import your custom CSS for styling
import { Button } from '@mui/material';
import { connect } from 'react-redux'
import CameraIcon from '@mui/icons-material/Camera';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function CameraComponent(props) {
  const videoRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [uploaded,setUploaded] = useState(false)
  console.log(capturedImage)
  const params = useParams();
  console.log(params)
  useEffect(() => {
    initCamera();
  }, []);

  const initCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };
  const captureImage = () => {
    const canvas = document.createElement('canvas');
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const capturedImageDataUrl = canvas.toDataURL('image/png'); // Convert to base64
    setCapturedImage(capturedImageDataUrl);
  };
  console.log(capturedImage)
  return (
    uploaded?<div className='upload-picture'>
      <img className='success' src="/success.png" alt="success" />
      <h1>Successfully Uploaded Picture</h1>
      <p>Redirecting...</p>
    </div>:<div className="nj-container">
      {/* <h1 className="heading">Click Picture</h1> */}
    {!capturedImage?<>
    <div className="camera-container">
      <video className='camera-video' ref={videoRef} autoPlay playsInline muted />      
    </div>
    <Button startIcon={<CameraIcon />} onClick={captureImage} className="cpabutton" variant="contained" fullWidth>click to Capture</Button> 
    </>:
    <>
    <div className="camera-container">
      <img className='captured-img' src={capturedImage} alt="captured-img" />
    </div>
    
      <div className='row m-auto comb-button'>
      <div className='col-6 p-0'>
    <Button  className='comb-button-inside' startIcon={<CameraIcon />} onClick={()=>{
      window.location.href=""
      }} variant="outlined" fullWidth>Retake</Button> 
      </div>

      <div className='col-6 p-0'>
      <Button className='comb-button-inside' endIcon={<ArrowForwardIosIcon />} onClick={()=>{

        if(props.location.state){
          axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/cusProdImg/${props.location.state.orderId}`,{
            "image" : capturedImage,
          })
          .then(res=>{
            setUploaded(true)   
            console.log(res)
            setTimeout(() => {
              if(props.location.state.appliedOffer){
                //props.history.push("/cashback",props.location.state.appliedOffer)
                props.history.push("/feedback",props.location.state.orderId)
              }else{
                props.history.push("/feedback",props.location.state.orderId)
              }
            }, 2000);
          })
        }else{
          //error not uploaded
        }
           
        //            props.history.push("/feedback",orderId)
      }} variant="contained" fullWidth>Next</Button> 
      </div>
      </div>
    </>
    } 

    </div>
  );
}
const mapStateToProps = ({nativeUser})=>{
  return {
      user:nativeUser
  }
}

export default connect(mapStateToProps)(CameraComponent)
