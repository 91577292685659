import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store,Persister} from './components/redux/Store'
import { createTheme,ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react'
import ScrollToTop from './components/Utils/ScrollToTop';

const theme = createTheme({
  typography: {
    "fontFamily": `'Barlow', sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 400,
    "fontWeightRegular": 500,
    "fontWeightMedium": 600
   },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
    tertiary:{
      main:"#f51000"
    },
    quaternary:{
      main:"#C273FF"
    }
  },
  components:{
    MuiButton:{
      styleOverrides:{
        root:{
         '&:focus':{
           outline:'none'
         }
        }
      }
    },
    MuiIconButton:{
      styleOverrides:{
        root:{
         '&:focus':{
           outline:'none'
         }
        }
      }
    },
    MuiTab:{
      styleOverrides:{
        root:{
          '&:focus':{
            outline:'none'
          }
        }
      }
    },
    MuiPagination:{
      styleOverrides:{
        root:{
          '&:focus':{
            outline:'none'
          }
        }
      }
    },
    MuiPaginationItem:{
      styleOverrides:{
        root:{
          '&:focus':{
            outline:'none'
          }
        }
      }
    }
  }
});
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(    <Provider store={store}>
    <PersistGate loading={null} persistor={Persister}>
      <BrowserRouter>
      <ThemeProvider theme={theme}>
      <ScrollToTop />
      <App />
      </ThemeProvider>
      </BrowserRouter>
      </PersistGate>
      </Provider>, rootElement);
} else {
  ReactDOM.render(
    <Provider store={store}>
    <PersistGate loading={null} persistor={Persister}>
      <BrowserRouter>
      <ThemeProvider theme={theme}>
      <ScrollToTop />
      <App />
      </ThemeProvider>
      </BrowserRouter>
      </PersistGate>
      </Provider>
  ,
   rootElement
  );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
