import React from 'react'
import {IconButton} from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import "./Item.scss"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import _ from 'lodash'
import Button from '@mui/material/Button'
import {formatMoney,percentage} from '../Utils/Functions'
import {withRouter} from 'react-router-dom'
import {wishListAction,addToWishListReq,removeFromWishListReq} from '../redux/wishlist/wishlistActions'
import {connect} from 'react-redux'
import {compose} from "redux";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from '@mui/material/colors';
import {addToCart,removeFromCart,increaseItemCount,decreaseItemCount,addItemToUserCart,removeItemFromUserCart,handleItemCartCount} from '../redux/cart/cartActions'
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios'
import Rating from '@mui/material/Rating';
import AddIcon from '@mui/icons-material/Add';
function ItemVar(props) {
     let details = props.item;
     const [wishlist,setWishList]=React.useState(false)
     const [cart,setCart]=React.useState(false)
     const [cartItem,setCartItem]=React.useState({})
    React.useEffect(() =>{
        if(props.user.user){
            props.wishList.map(item=>{
                if(item.variations.item._id===details.variations._id){
                    setWishList(true)
                }
            })
            props.cart.map(item=>{
                if(item.variations.item._id===details.variations._id){
                    setCart(true)
                    setCartItem(item)
                }
            })
            

        }
    },[props.wishList,props.cart]) 


    const renderName = ()=>{
        let name = details.name
        _.map(details.variations.options,(value,key)=>{
            if(key!=="_id" && key!=="mrp" && key!=="price" && key!=="imgs"){
                name = name + " " + `(${value})`
            }
        })
        return name
    }

    const handleWishListAdd = ()=>{
        if(!props.user.user){
            props.history.push("/signin",{route:"/"})
        }else{
            setWishList(!wishlist)
            if(!wishlist){
                props.addToWishListReq(props.user.user,details)
            }else{
                props.removeFromWishListReq(props.user.user,details)
            }
            //props.wishListAction(details)
        }
        
    }


    const handleCartAdd = ()=>{
        if(!props.user.user){
            props.history.push("/signin",{route:"/"})
        }else{
            setCart(!cart)
            if(!cart){
                props.addItemToUserCart(details,props.user.user)
                setCartItem(details)
            }else{
                props.removeItemFromUserCart(details,props.user.user)
                setCartItem({})
            }
            //props.wishListAction(details)
        }
    }

    const renderEmi =()=>{
        return formatMoney(parseInt(details.variations.options.price)/9)
    }


  return (
    <div data-aos="flip-left" className="shadow-sm item-container">
        <div className="row mt-2 mx-auto justify-content-between align-items-center">
            <div className="emi-offer">
                <p>{renderEmi()}/mon</p>
            </div>
            <div className="icon-button">
                <IconButton onClick={()=>handleWishListAdd()}>
                    {wishlist?<FavoriteIcon sx={{color:red[500]}} className="icon" />:<FavoriteBorderIcon className="icon"/>}
                </IconButton>
            </div>
        </div>


        <div onClick={()=>props.history.push(`/productinfo?${details.variations._id}`)}>
        {details.variations.images.length>0?
            <img src={details.variations.images[0]} alt="oppo" />
            :<img src="/cleverpeproduct.png" alt="oppo" />}
        <h3>{renderName()}</h3>
        <Rating name="read-only" value={props.item.rating.avg} readOnly />
        <p className="offerpara">Get it on No Cost EMI & Free Home Delivery</p>
        <div className="row align-items-center m-auto pricing-container">
            <div>
                <h2 className="price">{formatMoney(details.variations.options.price)}</h2>
            </div>
            <div>
                <p className="discountprice">{formatMoney(details.variations.options.mrp)}</p>
            </div>
            <div>
                <p className='percentage'>{percentage(details.variations.options.mrp,details.variations.options.price)}% off</p>
            </div>
        </div>
        </div>

        {!props.trend&&<>
        {!cart?<div className="btn-container">
            <Button onClick={()=>handleCartAdd()} className="btn-button" startIcon={<ShoppingCartOutlinedIcon />} fullWidth variant="contained">Add to cart</Button>
        </div>:
        <div className="row m-auto inccont align-items-center justify-content-center">
            <div className="col-5" style={{textAlign:"center"}}>
            <IconButton onClick={()=>{
                if(cartItem.variations.quantity===1){
                    setCart(false)
                    props.removeItemFromUserCart(cartItem,props.user.user)
                }else{
                    props.handleItemCartCount(cartItem,false,props.user.user)    
                }
                }}>
                    <RemoveIcon />
                </IconButton>
            </div>
            <div className="col-2">
                <p>{cartItem.variations.quantity}</p>
            </div>
            <div className="col-5" style={{textAlign:"center"}}>
                <IconButton onClick={()=>{
                   props.handleItemCartCount(cartItem,true,props.user.user)
                    }}>
                    <AddIcon />
                </IconButton>
            </div>
        </div>
        }
        </>}
    </div>
  )
}

const mapDispatchToProps = (dispatch)=>{
    return {
        wishListAction:(item)=>dispatch(wishListAction(item)),
        addToCart:(item)=>dispatch(addToCart(item)),
        increaseItemCount:(id)=>dispatch(increaseItemCount(id)),
        decreaseItemCount:(id)=>dispatch(decreaseItemCount(id)),
        removeFromCart:(item)=>dispatch(removeFromCart(item)),
        addItemToUserCart:(item,token)=>dispatch(addItemToUserCart(item,token)),
        removeItemFromUserCart:(item,token)=>dispatch(removeItemFromUserCart(item,token)),
        removeFromWishListReq:(token,item)=>dispatch(removeFromWishListReq(token,item)),
        addToWishListReq:(token,item)=>dispatch(addToWishListReq(token,item)),
        handleItemCartCount:(item,check,token)=>dispatch(handleItemCartCount(item,check,token))
        
    }
}

const mapStateToProps =({cleverpeUser,cart,wishList})=>{
	return {
		user:cleverpeUser,
		cart,
        wishList
	}
	}
	export default compose(
		withRouter,
		connect(mapStateToProps,mapDispatchToProps)
	  )(ItemVar);