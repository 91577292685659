import axios from 'axios'

export const setCart =(items)=>{
    return {
        type:"SET_CART",
        payload:items
    }
}

export const addToCart = (item)=>{
return {
    type:'ADD_ITEM',
    payload:item
}
}

export const removeFromCart = (item)=>{
    //send complete item to remove from cart
return {
    type:'REMOVE_ITEM',
    payload:item
}
}

export const emptyCart = ()=>{
    return {
        type:"EMPTY_CART"
    }
}

export const increaseItemCount = (id)=>{
    //send id to increase count
    return {
        type:'INCREASE_ITEM_COUNT',
        payload:id
    }
}

export const decreaseItemCount = (id)=>{
    //send id to deccrease count
    return{
        type:'DECREASE_ITEM_COUNT',
        payload:id
    }
}

export const getCart = (token)=>{
    return async (dispatch)=>{
        return await axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/cart/single`,{headers:{token}})
        .then(res=>{
            dispatch(setCart(res.data.result))
        })
        .catch(err=>{
        })
    }
}

export const addItemToUserCart = (item,token)=>{
    return  (dispatch)=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/cart/add`,{variationId:item.variations._id,quantity:1},{headers:{token}})
        .then(res=>{
            dispatch(getCart(token))
        })
    }
}

export const removeItemFromUserCart = (item,token)=>{
    return  (dispatch)=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/cart/remove`,{variationId:item.variations.item._id},{headers:{token}})
        .then(res=>{
            dispatch(getCart(token))
        })
    }
}

export const handleItemCartCount = (item,check,token)=>{
    return  (dispatch)=>{
            if(check){
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/cart/count`,{variationId:item.variations.item._id,quantity:item.variations.quantity+1},{headers:{token}})
                .then(res=>{
                    dispatch(getCart(token))
                })
            }else{
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/cart/count`,{variationId:item.variations.item._id,quantity:item.variations.quantity-1},{headers:{token}})
                .then(res=>{
                    dispatch(getCart(token))
                })
            }

    }
}