import React from 'react'
import "./OfflinePay.scss"
import {addItems} from '../redux/checkout/checkoutActions'
import {TextField,Button} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import {Link} from 'react-router-dom'
import PaymentIcon from '@mui/icons-material/Payment';
import {connect} from 'react-redux'
import {renderName2,formatMoney,orderSubTotal,orderDiscount,orderTotal, renderTenureString,renderTenure,renderTenureStringInterest} from '../Utils/Functions'
import axios from 'axios'
import _ from 'lodash'
import {storeOrderId} from '../redux/user/userActions'
import { nanoid } from 'nanoid'
import moment from 'moment';
import { Calculate, SettingsRemoteSharp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { setLoading } from '../redux/loading/loadingActions';
function OfflinePay(props) {
  const [data,setData]=React.useState({})
  const [display,setDisplay] = React.useState(true)
  const [btnDisabled, setBtnDisabled] = React.useState(false)
  const [payLink,setPayLink] = React.useState("");
  const [eV,setEv]= React.useState(false);
  const [native,setNative]=React.useState(false);
  const { id } = useParams();
  console.log(useParams())
  console.log("props",props.user);

  console.log("data",data);
  React.useEffect(() =>{
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/order/single/${id}`)
    .then(res=>{
      console.log(res)
      console.log("Ev:-"+res?.data?.result?.oem[0].eV+"Paylink :-"+res?.data?.result?.oem[0].payLink);
      setEv(res?.data?.result?.oem[0].eV);
      console.log("Ev is;- ",eV);
      setPayLink(res?.data?.result?.oem[0].payLink);
    
      if(res.data.result==="Completed"){
        setDisplay("Order Completed")
      }else if(res.data.result==="Order Expired"){
        setDisplay("Order Expired ! create a new order")
      }else{
        props.storeOrderId(id)
        setData(res.data.result)
        console.log("result",res.data.result);
        console.log("result native",res.data.result.native);
        setNative(res.data.result.native);
        setDisplay(null)

      }
    })
    .catch(err=>{
      console.log(err.response)
      if(err.response.data){
        //
        setDisplay(err.response.data)
      }
    })
  },[])

  const handlePayment = async ()=>{
    props.setLoading(true)
    setBtnDisabled(true)
    let variations = data.items.map(i=>({
      variations:{item:i.details,quantity:1},
      _id:""
    })
    )
    

    props.addItems(variations)

    let tenureObj = renderTenure(data.downPayment.tenure,data.total)
    console.log({
      "total":data.keyFactStatement.totalAmount,
      "tenure":parseInt(data.keyFactStatement.loanTenure),
      orderId:data._id
    })

    
    if(parseInt(data.downPayment.price)===0){
      axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/makePayment`,{
        "total":data.keyFactStatement.netLoanAmount,
        "tenure":parseInt(data.keyFactStatement.loanTenure),
        orderId:data._id
        //"mobilenumber":data.customer.mobileNo,
        //"authKey":data.interestScheme?`${process.env.REACT_APP_CASHEINTERESTKEY}`:`${process.env.REACT_APP_CASHELIVEKEY}`,
        //"leafRefNo":`${data._id}_${data.userDetails.mobileNo}`,//nanoid(),
        //"merchantname":"cleverpe",
        //"returnPageURL":`${process.env.REACT_APP_PRODUCTION}/transactionstatusoffline?orderId=${data._id}`
      }).then(res=>{
        console.log("response",res)
        console.log("native",native);
        if(eV===true){
          window.location.href=payLink
        }
        else
      {if(native==true){
          window.location.href=`${process.env.REACT_APP_NATIVE}/home/${data.nativeToken}`
        }else{
            window.location.href=`${process.env.REACT_APP_CASHELIVEREDIRECT}/Login?transaction=${res.data.result}`
        } 
      }
      })
      .catch(err=>{
        console.log(err.response)
        if(err.response.data){
          //
          setDisplay(err.response.data)
        }
      })
    }else{
      // const options = {
      //   key: `${process.env.REACT_APP_RAZORPAYLIVE}`,
      //   amount: `${parseInt(data.downPayment.price)*100}`, //  = INR 1
      //   name: 'Cleverpe',
      //   description: 'some description',
      //   image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
      //   handler:async function(response) {
            const dp = await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/downPayment`,{orderId:data._id,status:1},{headers:{token:props.user.user}})
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/order/makePayment`,{
              "total":data.keyFactStatement.netLoanAmount,
              "tenure":parseInt(data.keyFactStatement.loanTenure),
              orderId:data._id
              //"mobilenumber":data.customer.mobileNo,
              //"authKey":data.interestScheme?`${process.env.REACT_APP_CASHEINTERESTKEY}`:`${process.env.REACT_APP_CASHELIVEKEY}`,
              //"leafRefNo":`${data._id}_${data.userDetails.mobileNo}`,//nanoid(),
              //"merchantname":"cleverpe",
              //"returnPageURL":`${process.env.REACT_APP_PRODUCTION}/transactionstatusoffline?orderId=${data._id}`
            }).then(res=>{
              console.log(data.native)
              if(eV===true){
                window.location.href=payLink
              }
              else{
              if(native===true){
                window.location.href=`${process.env.REACT_APP_NATIVE}/home/${data.nativeToken}`
              }else{
                window.location.href=`${process.env.REACT_APP_CASHELIVEREDIRECT}/Login?transaction=${res.data.result}`
              }
            }
              
            })
            .catch(err=>{
              console.log(err.response)
              if(err.response.data){
                //
                setDisplay(err.response.data)
              }
            })
    //     },
    //     prefill: {
    //         name: 'Gaurav',
    //         contact: '9999999999',
    //         email: 'demo@demo.com'
    //     },
    //     notes: {
    //         address: 'some address'
    //     },
    //     theme: {
    //         color: 'black',
    //         hide_topbar: false
    //     }
    // };
    //     var rzp1 = new window.Razorpay(options);
    //     rzp1.open();
     }


     props.setLoading(false)
    //  setBtnDisabled(false)

  }


  

  const calculateSubTotal = (items)=>{
    let subTotal = 0
    items.map(i=>{
    subTotal = parseInt(i.details.options.price) + subTotal
    })
    return subTotal;
  }
   console.log(data.native);
  
  
  return (
    <>
    {display?
      <div className="m-3">
        <h1>{display}</h1>
      </div>
      :<div className="offline-pay">
      <div className="shadow-sm offline-pay-2">
      <img src="/cleverpewhitewithtext.png" alt="cleverpe" />
      <h1>Proforma Invoice</h1>

      <p><b>Order Id:</b> {!_.isEmpty(data)&&data.orderId}</p>

      <section className="info">
      <h3>Billing Info</h3>

      <div className="row mx-auto justify-content-between">
      <div>
      {/* <p>Name: <b>{!_.isEmpty(data)&&data.customer.name}</b></p> */}
      <p>Customer Mobile: <b>{!_.isEmpty(data)&&data.customer.mobileNo}</b></p>
      {/* <p>Email: <b>{!_.isEmpty(data)&&data.customer.email}</b></p> */}
      </div>
      
      <div className="merchant-info">
      <p>Merchant Name: <b>{!_.isEmpty(data)&&data.userDetails.name}</b></p>
      <p>Mobile: <b>{!_.isEmpty(data)&&data.userDetails.mobileNo}</b></p>
      <p>Email: <b>{!_.isEmpty(data)&&data.userDetails.email}</b></p>
      </div>
      </div>

      {/* <div className="mt-5">
      <hr />
      </div> */}


      <h3>Products</h3>

      <table className="ui celled table">
      <thead>
        <tr><th>Name</th>
        {/* <th>Sub Total</th>
        <th>Gst</th> */}
        <th>Total</th>
      </tr></thead>
      <tbody>
      {
      !_.isEmpty(data)&&
      data.items.map((item,index)=>(
        <tr key={index}>
          <td data-label="Name">{renderName2(item.details.product[0],item.details.options)} x {item.quantity}</td>
          {/* <td data-label="Sub Total">{formatMoney(item.details.options.price*parseInt(item.quantity))}</td>
          <td className="discounttable" data-label="Discount">{item.gst}%</td> */}
          <td className="totaltable" data-label="Total">{!_.isEmpty(data)&&formatMoney(item.productPrice*item.quantity)}</td>
        </tr>
      ))
      }
        

      </tbody>
    </table>

    <h3>Selected EMI Plan</h3>
    <p><b>{!_.isEmpty(data)&&data.keyFactStatement.displayString}</b></p>
    {/* <p><b>{!_.isEmpty(data)&&(
      data.conekt?renderTenureString(data.keyFactStatement.systemTenure,data.keyFactStatement.grossLoanAmount):
      (data.keyFactStatement.interestOnLoan!==0?renderTenureStringInterest(data.keyFactStatement.systemTenure,data.keyFactStatement.grossLoanAmount)+``:renderTenureString(data.keyFactStatement.systemTenure,data.keyFactStatement.grossLoanAmount)+``)
      )}
      </b></p> */}
  { (eV==true)?(<>
  <section className='payment-summary'>
  <p className="total">Total: {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.totalAmount)}</p>
  </section>
  </>):(
   <>
    <h3>Key Fact Statement</h3>
      <section className="payment-summary">
      Lender: Bhanix Finance & Investment Ltd <br />
      Borrower Mob no: {!_.isEmpty(data)&&data.customer.mobileNo} <br />
      Date of Loan: {!_.isEmpty(data)&&moment.parseZone(data.createdAt).local().format("MMMM Do YYYY")} <br />

        <p className="mt-4 subtotal">Gross Loan Amount (Rs): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.grossLoanAmount)} </p>
        <p className="subtotal">Down Payment (Rs): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.downPayment)}</p>
        <p className="subtotal">Net Loan Amount (Rs): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.netLoanAmount)}</p>
        <p className="subtotal">Loan Tenure: {!_.isEmpty(data)&&data.keyFactStatement.loanTenure} Months</p>
        <p className="subtotal">Effective Annualized Interest Rate: {!_.isEmpty(data)&&data.keyFactStatement.annualInterest+'%'}</p>
        <p className="subtotal">Interest Payable on Loan Amount: {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.interestOnLoan)}</p>
        <p className="subtotal">Broken Interest: As Applicable</p>
        <p className="subtotal">Processing Fee (if any): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.processingFee)}</p>
        <p className="subtotal">Other up-front charges (if any): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.otherCharges)}</p>
        <p className="subtotal">Total Repayable Amount (sum of all of the above): {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.totalAmount)}</p>
        <p className="subtotal">Repayment Frequency: {!_.isEmpty(data)&&data.keyFactStatement.paymentFreq}</p>
        <p className="subtotal">Number of Instalments: {!_.isEmpty(data)&&data.keyFactStatement.instalmentsCount}</p>
        <p className="subtotal">EMI Amount: {!_.isEmpty(data)&&formatMoney(data.keyFactStatement.emiAmount)} + Broken interest as per 0.067% per day</p>

        <p className="subtotal"><b>Details about Contingent Charges:</b></p>
        <p>Rate of annualized penal charges in case of delayed payments (if any): 36.5%</p>
        <p>Rate of annualized other penal charges (if any): 0</p>

        <p><b>Other Disclosures:</b></p>
        <p>Cooling off/look-up period: 3 Days</p>
        <p>Authorized Recovery Agent: List mentioned as <a href="https://www.cashe.co.in/our-collection-agencies/" alt="link">https://www.cashe.co.in/our-collection-agencies/</a></p>
        <p>Nodal Grievance Officer: Name: Ms. Pushpinder Kaur Phone: 022 46047350 Address: grievance@bhanix.in</p>
        {/* <p className="discount">Discount: {!_.isEmpty(data)&&orderDiscount(data.items)}</p> */}
        <p className="total">Total: {!_.isEmpty(data)&&formatMoney(data.total)}</p>
      </section>
    </>
  )
  }
      </section>
  

      {/* <section className="row m-auto justify-content-between align-items-center">
        <div className="col-3 signdiv">
          <p>Customer Signature</p>
        </div>
        <div className="col-3 signdiv">
        <p>Merchant Signature</p>
        </div>
      </section> */}
      
      <div className="pbtn">
      <Button onClick={()=>handlePayment()} startIcon={<PaymentIcon color='primary' />} variant="contained" color='secondary' disabled={btnDisabled}>CleverPe now</Button>
      </div>
      <div className="pbtn">
      <Button onClick={()=>window.print()} color='secondary'>Print Bill</Button>
      </div>
      </div>
    </div>}
    </>
  )
}
const mapStateToProps = ({cleverpeUser})=>{
  return {
    user:cleverpeUser,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      addItems:(item)=>dispatch(addItems(item)),
      storeOrderId:(id)=>dispatch(storeOrderId(id)),
      setLoading:(loading)=>dispatch(setLoading(loading))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(OfflinePay)

