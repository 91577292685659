import React from 'react'
import axios from 'axios'

function ComingSoon() {

  return (
    <div>
        <div>
        <img className="comingsoonlogo" src="/cleverpeblack.png" alt="cleverpeblack" />
        </div>
        <div>
        <img src="/coming-soon.png" className="comingsoon" alt="comingsoon" />
        </div>
    </div>
  )
}

export default ComingSoon