import React from 'react';

function OffersTo() {
  const containerStyle = {
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '5px',
    margin: '20px',
  };

  const headingStyle = {
    color: 'black',
    fontSize: '24px',
  };

  const instructionStyle = {
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Terms and Condidtions:</h2>
      <ol>
        <li style={instructionStyle}>
          Transact successfully with CleverPe instant cardless EMIs powered by CASHe.
        </li>
        <li style={instructionStyle}>
          After successful transactions, the system will flash the Cashback amount you have won.
        </li>
        <li style={instructionStyle}>
          The Cashback will be deposited in your bank account (through shared UPI Id) within 90 days from date of transaction.
        </li>
        <li style={instructionStyle}>
          Customer should repay all EMIs on or before due date to be eligible for Cashback. EMI Defaulters or customers with late payments will not be eligible for the payout.
        </li>
      </ol>
      <p>Offer validity : 12th Sep – 30th Sep 2023.</p>
      
    </div>
  );
}

export default OffersTo;
