import React from 'react'
import "./Auth.scss"
import { TextField,Button,Alert } from '@mui/material'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {connect} from 'react-redux'
import {setLoading} from '../redux/loading/loadingActions'
import {Link} from 'react-router-dom'
function MS3(props) {
    console.log(props)
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [error,setError]=React.useState("")
    console.log(props)
    const onSubmit = (data)=>{
        //props.history.push("/ms4")
        console.log(data)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/b2b/scoreme/gstinbasic`,{gstin:data.gstin},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            if(res.status===200){
                props.history.push("/ms4",{gstin:res.data.data,email:data.email})
            }
            
        })
        .catch(err=>{
            console.log(err)
        })
        //props.setLoading(true)
        // if(parseInt(data.mobile) || parseInt(data.mobile).length===10){
        //   setError("")
        //   props.history.push("/ms2",{data:{mobileNo:data.mobile}})  
        // //   axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/auth/signin`,{mobileNo:data.mobile})
        // //   .then(res=>{
        // //     props.setLoading(false)
        // //     if(res.data.msg==="Error"){
        // //       setError(res.data.result)
        // //     }else{
        // //       if(props.location.state){
        // //         props.history.push("/verifyotp",{auth:props.location.state.route,data:res.data})  
        // //       }else{
        // //         props.history.push("/verifyotp",{auth:"myprofile",data:res.data})
        // //       }
              
        // //     }
            
        // //   })
        // //   .catch(err=>{
        // //     setError("Check your internet connection")
        // //     props.setLoading(false)
        // //   })
        // }else{
        //   setError("Enter valid mobile number")
        //   props.setLoading(false)
        // }
      }
  return (
    <div className='body'>
    <div className="shadow-sm auth-container">
        <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
        <h3>Be Clever Pe Later</h3>
        <section>
                <p className="mototext">You're just one step away in becoming a CleverPe Merchant !!</p>
            </section>
        <form onSubmit={handleSubmit(onSubmit)}>
        <TextField error={errors.email?true:false} {...register("email",{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Enter Email" />
        <TextField error={errors.gstin?true:false} {...register("gstin",{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Enter GSTIN" />


        {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
        <Button type="submit" className="mt-4" fullWidth variant="contained">Next</Button>
        <p className="grey subtitle my-3">By continuing, you agree to CleverPe <Link to="/termsandconditions">Terms and Conditions</Link> of Use and <Link to="/privacypolicy">Privacy Policy</Link>.</p>

        <hr />
        {/* <div className="mt-3">
          <p className="newtext">New to CleverPe? Dive in by creating an account</p>
          <Button fullWidth variant="outlined" onClick={()=>props.history.push("/signup",props.location.state)}>create an account</Button>
        </div> */}
        </form>

    <p className='grey subtitle mt-3'>
    ©2022 Cleverpe Technologies Private Limited
    </p>
    </div>

    </div>
  )
}
const mapStateToProps = ({loading,cleverpeUser})=>{
    return {
        loading,
        user:cleverpeUser
    }
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return {
        setLoading:value=>dispatch(setLoading(value))
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(MS3)