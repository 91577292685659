import React from 'react'
//transactionId=null&transactionStatus=0&transactionTime=1653887336811&leafRefNo=undefined
import "./TransactionStatus.scss"
var url_string = window.location.href
var url = new URL(url_string);
var transactionStatus = url.searchParams.get("transactionStatus");
var transactionTime = url.searchParams.get("transactionTime");
var leafRefNo = url.searchParams.get("leafRefNo");
function CreditLimitStatus(props) {
  const [success,setSuccess]=React.useState(false)

  React.useEffect(()=>{
    if(transactionStatus.toString()==="1"){
      setSuccess(true)
    }
    setTimeout(() => {
      props.history.push("/myprofile")
    }, 3000);
  },[])

  return (
    <div>
      {
        success?
        <div className="transaction-status-container">
        <img className="img" src="/success.png" alt="failed" />
        <h1 >Transaction <span style={{color:"#05d237"}}>Success</span></h1>
        <p>Credit Limit Generated and has been credited to your account</p>
        <p>Redirecting ...</p>
      </div>
          :
          <div className="transaction-status-container">
            <img className="img" src="/failed.png" alt="failed" />
            <h1 >Transaction Failed</h1>
            <p>Failed To Genereate Credit Limit</p>
            <p>Redirecting ...</p>
          </div>
      }
    </div>
  )
}

export default CreditLimitStatus