import React from 'react'
import "../Home"
import {Link} from 'react-router-dom'
import { IoClose, IoMenu } from "react-icons/io5";

function NewHeader(props) {

  const [navOpen, setNavOpen] = React.useState(false)
  return (
    <div>
      <div className="Navbar">
      <div className='clever-logo'>
        <img className="logo" src="/cleverpewhitehori.png" alt="logo" />
      </div>
      <div className='main-links'>
        <Link to="/"><span className={`mx-3 home-link ${props.id===1&&'home-link-active'}`}>Home</span></Link>
        <Link to="/aboutus"><span className={`mx-3 home-link ${props.id===2&&'home-link-active'}`}>About</span></Link>
        <Link to="/cleverpeeco"><span className={`mx-3 home-link ${props.id===3&&'home-link-active'}`}>CleverPe Eco</span></Link>
      </div>
      <div>
      <div className="button desktop-visible">
        <button onClick={()=>window.location.href="https://business.cleverpe.com/"} className="white-button">Merchants</button>
      </div>
      <div className='menu-icon' onClick={()=>setNavOpen(!navOpen)}>
      { !navOpen ? <IoMenu fontSize={"7vw"}/> : <IoClose fontSize={"7vw"}/> }
      </div>
      </div>
   </div>
   { navOpen &&
   <div className={`${navOpen} ? mobile-nav : mobile-nav-display`}>
        <Link to="/"><span className={`mx-3 mob-link ${props.id===1&&'home-link-active'}`}>Home</span></Link>
        <Link to="/aboutus"><span className={`mx-3 mob-link ${props.id===2&&'home-link-active'}`}>About</span></Link>
        <Link to="/cleverpeeco"><span className={`mx-3 mob-link ${props.id===3&&'home-link-active'}`}>CleverPe Eco</span></Link>
   </div>
    }
    </div>
  )
}

export default NewHeader