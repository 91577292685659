import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import animationData from './animation_lmdicw0o.json';
import "./TransactionStatus.scss"
export default function CashbackAni(props) {
  console.log(props)
  //const [cashback,setCashback]=React.useState(true)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div className="cashback-ani">
        {props.location.state?<>
        <h1>Cashback Bonanza</h1>
        <p>You have successfully won the cashback on CleverPe.</p>
        <h3 className="cashback-t">₹ {props.location.state.order.value}</h3>
        <Link to="/offersto">Terms and Conditions</Link>
        <Lottie 
          options={defaultOptions}
          height={1000}
        />
        </>:
        <>
        <h1>Oops ! Sorry</h1>
        <p>You haven't got any cashback, hardluck today :(, <br />Although, congratulations on your successful transaction and we would love to see you soon. <br />
        <b>Thank You</b>
        </p>
        </>
        }
      </div>
    );
  }