import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SubHeader from '../Header/SubHeader'
import {connect} from 'react-redux'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardMenu from './DashboardMenu';
import { IconButton,TextField,Button,Tooltip,Fab } from '@mui/material';
import {formatMoney} from '../Utils/Functions'
import MenuIcon from '@mui/icons-material/Menu';
import "./MyProfile.scss"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {getUserInfo,storeUserInfo} from '../redux/user/userActions'
import { setLoading } from '../redux/loading/loadingActions'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SimpleBackdrop from '../Utils/SimpleBackdrop'
import {useForm} from 'react-hook-form'
import axios from 'axios'
function MyProfile(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [open,setOpen]=React.useState(false)
    const [edit,setEdit]=React.useState(false)
    const [gender, setGender] = React.useState(10);
    const [address,setAddress]=React.useState("")
    const [addressData,setAddressData]=React.useState(props.user.userInfo?props.user.userInfo.addresses:[])
    const [userData,setUserData]=React.useState({})
    const [addressMobile,setAddressMobile]=React.useState("")
    const [loading,setLoading]=React.useState(false)
    const [availableCredit,setAvailableCredit]=React.useState(0)
    const handleChange = (event) => {
        setGender(event.target.value);
    };

    const onSubmit  = (data)=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/user/edit`,
        {
            "data": [
            {"propName": "mobileNo", "value": data.mobileNo},
            {"propName": "email", "value": data.email},
            {"propName": "name", "value": data.name},
            {"propName": "addresses", "value": addressData}
            ]
        }
        ,{headers:{token:props.user.user}})
        .then(res=>{
            if(res.data.result==="Updated"){
                setEdit(false)
                props.getUserInfo(props.user.user)
            }
        })
        .catch(err=>{
        })
    }
  
    const getUserInfo = async ()=>{
        await props.getUserInfo(props.user.user)
        if(props.user.userInfo){
            setAddressData(props.user.userInfo.addresses)

        }
    }

    React.useEffect(()=>{ 
        if(props.user.userInfo){
            props.setLoading(true)
            setAddressData(props.user.userInfo.addresses)
            if(props.user.userInfo.role!=="customer"){
                props.setLoading(false)
                props.history.push("/merchantprofile")
            }else{
                axios.get(`${process.env.REACT_APP_CASHELIVE}/api/cashe/paymentgateway/customer/getCreditInfo/${props.user.userInfo.mobileNo}`,{headers:{Authorization:`${process.env.REACT_APP_CASHELIVEKEY}`}})
                .then(res=>{
                        //setAvailableCredit(res.data.entity[0].leftOverCreditLine)
                        props.setLoading(false)
                        setAvailableCredit(res.data.entity)
                })
                .catch(err=>{
                    props.setLoading(false)
                })
            }
        }
        //getUserInfo()
        // axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/user/single`,{headers:{token:props.user.user}})
        // .then(res=>{
        //     //setUserData()
        //     props.storeUserInfo(res.data.result)
        // })
        // .catch(err=>{
        // })
       
    },[props.user.userInfo])

  return (
    <div>
        <Header />
        <div className="desktop-visible">
        <SubHeader />
        </div>
    {!loading&&<div className="my-profile">

        <div className='desktop-container row m-auto'>
            <section className=" col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 menu-container p-0">
             <DashboardMenu id={1} open={open} setOpen={setOpen}/>
                  

            </section>
            <section className="shadow col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 info-container">
                <div className='mobile-visible'>
                <IconButton onClick={()=>setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                </div>


          { !edit? <section className="profile-section row m-auto">

                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 profile-parent-container">
                <div className="row m-auto profile-cont align-items-center">
                    <div className="">
                    {
                  props.user.userInfo&&(
                  props.user.userInfo.img?<img src={`${process.env.REACT_APP_DEVELOPMENT}/api/user/image/${props.user.userInfo.img}`} className="avatar" alt="man" />
                  :
                  <img src="/menprofile.png" alt="man" />
                  )
                }
                    </div>
                    <div className="">
                        <h2>{props.user.userInfo&&props.user.userInfo.name}</h2>
                    </div>
                    <div className="">
                        <IconButton onClick={()=>setEdit(!edit)}>
                            <EditIcon />
                        </IconButton>
                    </div>
                </div>


                <div className='row m-auto info-children p-0'>

                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <h3>Mobile No</h3>
                        <p>{props.user.userInfo&&props.user.userInfo.mobileNo}</p>
                    </div>

                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <h3>Email</h3>
                        <p>{props.user.userInfo&&props.user.userInfo.email}</p>
                    </div>
                </div>

                {
                    props.user.userInfo&&props.user.userInfo.addresses.length>0?(
                        props.user.userInfo&&props.user.userInfo.addresses.map((item,index)=>(
                            <div key={index} className="address">
                            <h3>Address {index+1}:</h3>
                            <p>{item}</p>
                        </div>
                        ))
                    ):<div style={{color:"#ccc"}}><ContactMailIcon /> <span className="ml-2">You haven't added any addresses yet</span></div>
                }
                </div>


                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 credit-limit-container">
                    <h4>CleverPe Credit Limit</h4>
                    <h1>{formatMoney(availableCredit)}</h1>
                    <div style={{textAlign:"center"}}>
                    {availableCredit<=0&&<Button disabled={true} onClick={()=>{
                        axios.post(`${process.env.REACT_APP_CASHELIVE}/api/cashe/paymentgateway/customer/generateUtmLink`,{
                            "mobilenumber":props.user.userInfo?props.user.userInfo.mobileNo:"",
                            "authKey":`${process.env.REACT_APP_CASHELIVEKEY}`,
                            "merchantname":"cleverpe",
                            "returnPageURL":`${process.env.REACT_APP_PRODUCTION}/creditlimitstatus`
                        })
                        .then(res=>{
                            window.location.href = `${process.env.REACT_APP_CASHELIVEREDIRECT}/Login?transaction=${res.data.entity}`;
                        })
                    }}>Generate Credit Limit</Button>}
                    </div>
                </div>

                </section>
:
















<form onSubmit = {handleSubmit(onSubmit)} className="profile-section row m-auto">
<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 profile-parent-container">
<h3>Edit Personal Info</h3>
<div className="row m-auto profile-cont align-items-center">
    <div className="">
        <img src="/man.png" alt="man" />
    </div>
    <div className="mx-2">
        <TextField fullWidth error={errors.name?true:false} {...register('name',{required:true})} defaultValue={props.user.userInfo&&props.user.userInfo.name} id="outlined-basic" variant="outlined" label="Name" />
    </div>
    <div className="">
        <IconButton onClick={()=>setEdit(!edit)}>
            <CloseIcon />
        </IconButton>
    </div>
</div>


<div className='row m-auto info-children p-0'>

    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p><TextField fullWidth error={errors.mobile?true:false} {...register('mobileNo',{required:true})} defaultValue={props.user.userInfo&&props.user.userInfo.mobileNo} id="outlined-basic" variant="outlined" label="Mobile Number" /></p>
    </div>
    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gender}
          label="Gender"
          onChange={handleChange}
          defaultValue={10}
        >
          <MenuItem value={10}>Male</MenuItem>
          <MenuItem value={20}>Female</MenuItem>
          <MenuItem value={30}>Other</MenuItem>
        </Select>
      </FormControl>
        </p>
    </div> */}
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <p><TextField fullWidth error={errors.email?true:false} {...register('email',{required:true})} defaultValue={props.user.userInfo&&props.user.userInfo.email} id="outlined-basic" variant="outlined" label="Email" /></p>
    </div>
</div>

<section className="add-address">
    <h3>Add Address</h3>
                    <TextField onChange={(e)=>setAddress(e.target.value)} fullWidth multiline maxRows={5} id="outlined-basic" variant="outlined" label="Enter New Address" />
                    <TextField className="mt-3" onChange={(e)=>setAddressMobile(e.target.value)} fullWidth  id="outlined-basic" variant="outlined" label="Enter Mobile Number" />

                        <Button className="addressbutton" onClick={()=>{
                            let addr = `${address} ${addressMobile}`
                            setAddressData([...addressData,addr])}} variant="contained" fullWidth>Add Address</Button>

                </section>


{
    addressData.length>0?(
        addressData.map((item,index)=>(
            <div key={index} className="row m-auto address align-items-center">
            <div className="col-10 p-0">
            <h3>Address {index+1}:</h3>
            <p>{item}</p>
            </div>
            <div className="col-2 p-0">
                <IconButton>
                    <DeleteOutlineRoundedIcon />
                </IconButton>
            </div>
        </div>
        ))
    ):<div className="my-5" style={{color:"#ccc"}}><ContactMailIcon /> <span className="ml-2">You haven't added any addresses yet</span></div>
}




</div>

<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 credit-limit-container">
    <h4>CleverPe Credit Limit</h4>
    <h1>{formatMoney(availableCredit)}</h1>
    <div style={{textAlign:"center"}}>
{availableCredit<=0&&<Button onClick={()=>{
        axios.post(`${process.env.REACT_APP_CASHELIVE}/api/cashe/paymentgateway/customer/generateUtmLink`,{
            "mobilenumber":props.user.userInfo?props.user.userInfo.mobileNo:"",
             "authKey":"",
             "merchantname":"cleverpe",
             "returnPageURL":`${process.env.REACT_APP_PRODUCTION}/creditlimitstatus`
        })
    }}>Generate Credit Limit</Button>}
    </div>
</div>
{edit&&<div style={{position:"fixed",bottom:"5%",right:"15%"}}>
              <Tooltip title="Create Product">
              <Fab type="submit" variant="extended">
                <CheckIcon sx={{ mr: 1 }} />
                Done
                </Fab>
              </Tooltip>
              </div>
}
</form>}



            </section>
        </div>
    </div>}
    <Footer />
    </div>
  )
}

const mapStateToProps = ({cleverpeUser,loading})=>{
    return {
        user:cleverpeUser,
        loading
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getUserInfo:(token)=>dispatch(getUserInfo(token)),
        setLoading:value=>dispatch(setLoading(value)),
        storeUserInfo:(user)=>dispatch(storeUserInfo(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MyProfile)