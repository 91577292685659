import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import axios from 'axios'
function Feedback(props) {
  const [feedback,setFeedback] = React.useState([])
  const options = [
    "Recharge & Bill Payments",
    "Insurance premium & Health packages",
    "Smart digital accessories - Earphones, wireless, Neckbands, etc",
    "Consumer Durables - TV, Fridge, AC, Washing Machine, etc",
    "Home improvement - Furniture, Home Decor, Matteress, Home furnishings",
    "Healthcare & Fitness packages",
    "Educational and skill development courses"
  ]

  const handleSubmit = ()=>{
    console.log(feedback)
    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/survey/create`,{orderId:props.location.state?props.location.state:"63ecb5858b185f61f296b67b",options:feedback})
    .then(res=>{
      console.log(res)
      props.history.push("/")
    })
    .catch(err=>{
      props.history.push("/")
      console.log(err)
    })
  }

  return (
    <div className='upload-picture'>
        <h1>Yayyy!</h1>
        <h4>Your Transaction is Successful</h4>
        <p>Thank you for choosing CleverPe as your finance buddy. What would be the next product/service you wish to transact on CleverPe?</p>
        <section className='poly-container'>
        <FormGroup >
      {
        options.map((item,index)=><FormControlLabel control={<Checkbox onChange={(e)=>{
          if(feedback.includes(item)){
            setFeedback(feedback.filter(i=>i!==item))
          }else{
            setFeedback([...feedback,item])
          }
        }} />} label={item} key={index} />)
      }
      
    </FormGroup>
    
    <Button onClick={()=>handleSubmit()} className="my-3" fullWidth variant="contained" >Submit</Button>
    </section>
    </div>
    // <div className="feedback-section-head">
    // <section className="feedback-section">
    //     <h1>Thank you for choosing CleverPe as your finance buddy !!!</h1>
    //     <p>we would love to have you back on our platform</p>
    //     <p>What would be the next product/service you wish to transact on CleverPe?</p>
    // <FormGroup >
    //   {
    //     options.map((item,index)=><FormControlLabel control={<Checkbox onChange={(e)=>{
    //       if(feedback.includes(item)){
    //         setFeedback(feedback.filter(i=>i!==item))
    //       }else{
    //         setFeedback([...feedback,item])
    //       }
    //     }} />} label={item} key={index} />)
    //   }
      
    // </FormGroup>
    
    // <Button onClick={()=>handleSubmit()} className="my-3" fullWidth variant="contained" >Submit</Button>
    // </section>
    // </div>
  )
}

export default Feedback